import React from "react";

const CardBar = ({ stats }) => {
  // const stats = [
  //   {
  //     value: 150,
  //     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias.",
  //   },
  //   {
  //     value: 70,
  //     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias.",
  //   },
  //   {
  //     value: 150,
  //     text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias.",
  //   },
  // ];
  console.log(stats);
  return (
    <div className="grid grid-cols-3 px-4 text-center bg-opacity-25 bg-gray-01">
      {stats.map((stat, index) => {
        return (
          <div
            key={index + "stat"}
            className={`${index !== stats.length - 1 ? "border-r-2 border-r-gray-400" : ""
              } my-4`}
          >
            <div className="p-1">
              <h1 className="text-2xl font-bold 2xl:text-3xl font-poppins">
                {index !== (stats.length - 1) / 2
                  ? stat.value + "+"
                  : stat.value + "%"}
              </h1>
              <p className="justify-center hidden text-sm sm:flex lg:text-base xl:text-lg break-02:text-xl xl:p-1 font-lato">
                {stat.text}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardBar;
