import { FaBookmark, FaCalendarMinus, FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaCheckSquare } from "react-icons/fa";
import translate from "../../internationalisation/translate";
import { apiUrlVitrine, defaultHeaders } from "../../api";

const BodyViewPublication = ({ article }) => {
  const [lastPost, setLastPost] = useState([]);
  const locale = useSelector((state) => state.languageLocale);
  useEffect(() => {
    axios.get(apiUrlVitrine + "/publication/").then((res) => {
      setLastPost(res.data.reverse());
    });
  }, []);
  return (
    <div className=" xl:mr-32 xl:ml-20 md:mx-20 mx-10 flex">
      <div className=" order-last border-l xl:basis-1/3 hidden xl:block xl:mt-40 ">
        {lastPost.slice(0, 3).map((post) => {
          return (
            <div
              key={post.id}
              className="flex-row text-center my-4 gap-3 px-10"
            >
              <img src={post.image} className="w-full h-42" alt="..." />

              <div className="text-md xl:text-lg my-2 font-semibold font-lato text-[#444] cursor-pointer hover:text-blue-01">
                <a href={`/publication/${post.id}`}>
                  {locale === "en-us"
                    ? post.translations.en.title
                    : post.translations.fr.title}
                </a>
              </div>
            </div>
          );
        })}
      </div>
      <div className="m-6 lg:mr-32 break-02:m-20  xl:basis-2/3 ">
        <div className="flex flex-col md:flex-row justify-between lg:items-center gap-2 my-4 2xl:mx-4">
          <div className="xl:-translate-y-10">
            <div className="font-lato font-bold text-blue-01 text-xl lg:text-3xl break-02:text-4xl py-5">
              {locale === "en-us"
                ? article[0].translations.en.title
                : article[0].translations.fr.title}
            </div>

            <div className="grid gap-4 font-lato text-sm md:text-base 2xl:text-lg break-02:text-xl my-4 ml-1">
              <div className="flex">
                <span className="text-blue-01">
                  <FaCalendarMinus />
                </span>
                <span className="mx-2">{article[0].date}</span>
              </div>

              <div className="flex">
                <span className="text-blue-01">
                  <FaUser />
                </span>
                <span className="mx-2">{article[0].auteur}</span>
              </div>

              <div className="flex">
                <span className="text-blue-01">{/* <FaBookmark /> */}</span>
                <span className="mx-2">
                  {/* {article.domaines.map((element, index) => (
                  <span key={index + "element"} className="">
                    {index !== article.domaines.length - 1
                      ? element + " - "
                      : element}
                  </span>
                ))} */}
                </span>
              </div>
            </div>
          </div>
        </div>

        <p
          className="py-4 2xl:mx-4 font-lato indent-8 text-gray-700 lg:text-xl break-02::text-2xl text-justify"
          dangerouslySetInnerHTML={{
            __html:
              locale === "en-us"
                ? article[0].translations.en.body
                : article[0].translations.fr.body,
          }}
        ></p>
      </div>
    </div>
  );
};

export default BodyViewPublication;
