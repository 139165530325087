import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaBookmark, FaCalendarMinus, FaUser } from "react-icons/fa";

import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../../api";
import { useSelector } from "react-redux";

const NewAticle = ({ article }) => {
  const [domaine, setDomaine] = useState(null);
  const locale =useSelector((state)=>state.languageLocale);

  useEffect(() => {
    axios.get(apiUrlVitrine + "/domaine/?id=" + article.domaine).then((res) => {
      setDomaine(res.data[0]);
    });
  }, [locale]);

  return (
    <div>
      <div className="max-w-md px-3 pb-5 mx-2 mb-10 transition duration-200 ease-in-out border border-gray-100 hover:border-gray-200 hover:shadow-xl shadow-gray-300">
        <a href={`/publication/${article.id}`}>
          <div className="flex justify-center -translate-y-10">
            <img
              src={article.image}
              alt="..."
              className="w-full transition duration-200 ease-in-out cursor-pointer hover:scale-105"
            />
          </div>
        </a>

        <div className="grid grid-cols-2 gap-2 text-xs font-poppins lg:text-sm">
          <div className="flex ml-2">
            <span className="text-blue-01">
              <FaCalendarMinus />
            </span>
            <span className="mx-2">{article.date}</span>
          </div>

          <div className="flex justify-end ml-2">
            <span className="text-blue-01">
              <FaUser />
            </span>
            <span className="mx-2">{article.auteur}</span>
          </div>

          <div className="flex col-span-2 ml-2">
            <span className="text-blue-01">
              <FaBookmark />
            </span>
            <span className="mx-2">
              <span className="mr-2">{ domaine===null?"":locale ==="en-us"? domaine.translations.en.name:domaine.translations.fr.name}</span>
              {/* {article.domaines.map((element, index) => (
                <span key={index + "element"} className="mr-2">
                  {index !== article.domaines.length - 1
                    ? element + ","
                    : element}
                </span>
              ))} */}
            </span>
          </div>
        </div>

        <div className="mx-2">
          <div className="py-4 text-xl font-semibold cursor-pointer text-blue-01 font-lato 2xl:my-2">
            <a href="/publication/view">{locale ==="en-us"? article.translations.en.title:article.translations.fr.title}</a>
          </div>
          <div className="py-2 font-light font-poppins">{locale ==="en-us"? article.translations.en.abstract:article.translations.fr.abstract}</div>
        </div>
      </div>
    </div>
  );
};

export default NewAticle;
