import React from "react";
import { FaBookmark, FaCalendarMinus, FaUser } from "react-icons/fa";

import { useEffect } from "react";
import { useState } from "react";

import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../../api";
import { useSelector } from "react-redux";

const Article = ({ article }) => {
  const locale = useSelector((state) => state.languageLocale);
  const [domaine, setDomaine] = useState(null);

  useEffect(() => {
    axios.get(apiUrlVitrine + "/domaine/?id=" + article.domaine).then((res) => {
      setDomaine(res.data[0]);
    });
  }, []);

  // const separation = (date) => {

  //   return [yy, mm, dd];
  // };

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const newDate = () => {
    let [yy, mm, dd] = article.date.split("-");
    return month[mm - 1] + " " + dd + ", " + yy;
  };

  return (
    <div className="px-3 pb-5 mx-2 mb-10 border-t-2 border-gray-200 shadow-xl shadow-gray-300 max-w-md">
      <a href={`/publication/${article.id}`}>
        <div className="-translate-y-10 flex justify-center">
          <img
            src={article.image}
            alt="..."
            className="w-full shadow-xl shadow-slate-300 cursor-pointer"
          />
        </div>
      </a>

      <div className="grid gap-2 grid-cols-2 font-lato text-xs 2xl:text-sm">
        <div className="flex ml-2">
          <span className="text-blue-01">
            <FaCalendarMinus />
          </span>
          <span className="mx-2">{newDate()}</span>
        </div>

        <div className="flex ml-2">
          <span className="text-blue-01">
            <FaUser />
          </span>
          <span className="mx-2">{article.auteur}</span>
        </div>

        <div className="col-span-2 flex ml-2">
          <span className="text-blue-01">
            <FaBookmark />
          </span>
          <span className="mx-2">
            <span className="mr-2">
              {domaine === null
                ? ""
                : locale === "en-us"
                ? domaine.translations.en.name
                : domaine.translations.fr.name}
            </span>
            {/* {article.domaines.map((element, index) => (
                <span key={index + "element"} className="mr-2">
                  {index !== article.domaines.length - 1
                    ? element + ","
                    : element}
                </span>
              ))} */}
          </span>
        </div>
      </div>

      <div className="mx-2">
        <div className="text-blue-01 font-lato font-semibold text-xl py-4 2xl:my-2 cursor-pointer">
          <a href={`/publication/${article.id}`}>
            {locale === "en-us"
              ? article.translations.en.title
              : article.translations.fr.title}
          </a>
        </div>
        <div className="font-poppins font-light py-2">
          {locale === "en-us"
            ? article.translations.en.abstract
            : article.translations.fr.abstract}
        </div>
      </div>
    </div>
  );
};

export default Article;
