import React, { useState, useEffect } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { CgMenu, CgClose } from "react-icons/cg";
import { MdTranslate } from "react-icons/md";
import { BsChevronDown } from "react-icons/bs";
import { GB } from "country-flag-icons/react/3x2";
import { FR } from "country-flag-icons/react/3x2";
import { changeLanguage } from "../store";
import logo from "../img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import translate from "../internationalisation/translate";
import { Link } from "react-router-dom";
import { apiUrlVitrine } from "../api";

const Navbar = () => {
  const [languageState, setLanguageState] = useState(false);
  const [language, setLanguage] = useState("en");
  const [nav, setNav] = useState(false);

  const dispatch = useDispatch();

  const handleLanguage = (lang) => {
    dispatch(changeLanguage(lang));
    localStorage.setItem("LANG", lang);
    return setLanguageState(!languageState);
  };
  console.log(useSelector((state) => state.languageLocale));



  

  useEffect(() => {

    fetch(`${apiUrlVitrine}/navbar/`)
      .then(response => response.json())
      .then(data => setNav(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const ChangeLanguage = () => {
    return (
      <div className="relative inline-block">
        <div className="px-2 mx-1 flex">
          <MdTranslate
            size={23}
            onClick={() => setLanguageState(!languageState)}
            className=" text-gray-900 hover:text-[#444] "
          />
          <BsChevronDown className="mt-2 mx-1" />
        </div>

        <div
          className={
            !languageState
              ? "hidden"
              : "absolute transition-all ease-in-out duration-300 mx-3 my-1 border-t border-gray-100 bg-[#FEFEFE] text-black min-w-max shadow-xl z-10"
          }
        >
          <div
            onClick={() => handleLanguage("en-us")}
            className="block p-1 pb-3 mx-1 sm:m-2 border-b border-b-gray-300"
          >
            <GB
              title="United States"
              className="h-6 md:h-8 w-auto cursor-pointer rounded hover:scale-125 transition ease-in-out duration-200"
            />
          </div>

          <div
            onClick={() => handleLanguage("fr-fr")}
            className="block p-1 mx-1 sm:m-2"
          >
            <FR
              title="France"
              className="h-6 md:h-8 w-auto cursor-pointer rounded hover:scale-125 ease-in-out duration-200"
            />
          </div>
        </div>
      </div>
    );
  };

  const MoblieNavBar = () => {
    const [nav, setNav] = useState(false);

    const menu = () => {
      if (languageState === true) {
        setLanguageState(false);
      }

      let tampon = nav;
      return setNav(!tampon);
    };

    return (
      <div
        className={`"border-b border-gray-200 bg-[#FFF] fixed z-0 w-full shadow-md md:px-12" ${
          nav ? "" : ""
        }`}
      >
        <div
          className={`"z-10 fixed top-0 left-0 h-full flex overflow-hidden origin-left" ${
            nav
              ? "w-full transition-all ease-in-out duration-500"
              : "w-0 transition-all ease-in-out duration-500"
          }`}
        >
          <div
            id="mySidenav"
            className="sidenav w-4/5 bg-[#FFF] bg-opacity-[0.97]"
          >
            <div className="flex flex-col justify-center items-center p-4">
              <Link to="/" className="">
                <img src={logo} alt="OFTY logo" className="" />
              </Link>
              <span className="text-green-01 p-2 font-lato text-center text-xs">
                {translate("slogan")}
              </span>
            </div>

            <div className="mt-8 flex flex-col justify-center items-center text-center text-blue-01 font-lato uppercase text-sm sm:text-base">
              <Link
                to="/expertise"
                className="m-4 p-1 border-b border-gray-400 w-4/6 block"
              >
                {translate("Expertises")}
              </Link>

              <Link
                to="/services"
                className="m-4 p-1 border-b border-gray-400 w-4/6 block"
              >
                {translate("Services")}
              </Link>

              <Link
                to="/products"
                className="m-4 p-1 border-b border-gray-400 w-4/6 block"
              >
                {translate("Produits")}
              </Link>

              <Link
                to="/publication"
                className="m-4 p-1 border-b border-gray-400 w-4/6 block"
              >
                {translate("Blog")}
              </Link>

              <Link
                to="/career"
                className="m-4 p-1 border-b border-gray-400 w-4/6 block"
              >
                {translate("Career")}
              </Link>

              <Link
                to="/about"
                className="m-4 p-1 border-b border-gray-400 w-4/6 block"
              >
                {translate("About")}
              </Link>

              <Link
                to="/contact"
                className="m-4 p-1 border-b border-gray-400 w-4/6 block"
              >
                {translate("contact")}
              </Link>
            </div>
          </div>

          <div
            onClick={() => menu()}
            className="w-1/5 bg-black bg-opacity-10 grid justify-center"
          >
            <div className="bg-white my-4 h-max w-max p-2 cursor-pointer text-blue-01">
              {nav ? <CgClose size={30} /> : null}
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center p-2 sm:px-4 mx-4 sm:mx-6">
          <div className="flex flex-col">
            <Link to="/" className="block max-w-[5.5rem]">
              <img src={logo} alt="" />
            </Link>

            <div className="hidden sm:block text-xs  font-lato text-left text-green-01">
              {translate("slogan")}
            </div>
          </div>

          <div className="flex">
            <div className="py-2 mt-2">{!nav ? <ChangeLanguage /> : null}</div>

            <div
              onClick={() => menu()}
              className="m-2 cursor-pointer text-blue-01"
            >
              {!nav ? <CgMenu size={35} /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PCNavBar = () => {
    return (
      <div className="border-b border-gray-200 bg-white w-full font-lato fixed shadow-lg">
        <div className="flex justify-between items-center px-3 sm:mx-2.5 xl:mx-4 2xl:ml-10 py-2 md:px-12 break-02:pl-20 break-02:pr-16">
          <div className="flex flex-col">
            <Link to="/" className="block">
              <img src={logo} alt="" />
            </Link>

            <div className="hidden sm:block text-xs text-left text-green-01">
              {translate("slogan")}
            </div>
          </div>

          <div className="flex gap-x-2 items-center">
            <ul className="flex justify-around items-center cursor-pointer xl:gap-1 text-blue-01 text-sm 2xl:text-base uppercase">
            {nav&&nav.map((data) => {
              if (!data.children.length > 0){
            return <Link to={data.url} className="appearance-none p-2 m-1">
            <li className={`${data.translate === 'contact' ? ('appearance-none py-1 mx-1 px-7 rounded-full transition-all ease-in-out duration-300 text-white bg-blue-01 hover:bg-white hover:text-blue-01 hover:scale-110 border-2 border-solid border-blue-01'):'hover-underline-animation'}`}>
              {translate(data.translate)}
            </li>
          </Link>
              }else{

                return<li className="relative inline-block dropdown">
                <Link
                  to={data.url}
                  className="flex items-center gap-x-1 appearance-none p-2 m-1"
                >
                  <span className="hover-underline-animation">
                  {translate(data.translate)}
                  </span>
                  <span className="text-2xl">
                    <IoMdArrowDropdown />
                  </span>
                </Link>

                <div className="absolute hidden text-left flex-col -translate-y-1.5 dropdown-content mx-3 my-1 bg-[#FEFEFE] text-blue-01 min-w-max shadow-lg z-10">
                  {data.children.map((child) =>{

                  return <Link
                    to={child.url}
                    className="appearance-none hover:bg-slate-100 p-4 transition ease-in-out duration-300"
                  >
                    {translate(child.translate)}
                  </Link>
                  })}

                </div>
              </li>


              }

          })}
              

            </ul>

            <div className="py-2 mt-2"><ChangeLanguage /></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="h-full">
      <div className="xl:hidden h-full">
        <MoblieNavBar />
      </div>

      <div className="hidden xl:block">
        <PCNavBar />
      </div>
    </div>
  );
};

export default Navbar;

// import React, { useState } from "react";
// import { CgMenu, CgClose } from "react-icons/cg";
// import { MdTranslate } from "react-icons/md";
// import { GB } from "country-flag-icons/react/3x2";
// import { FR } from "country-flag-icons/react/3x2";
// import { IoMdArrowDropdown } from "react-icons/io";

// import logo from "../img/logo.png";

// const Navbar = () => {
//   const [nav, setNav] = useState(false);
//   const [language, setLanguage] = useState(false);
//   const [langues, setLangues] = useState("en");
//   const en = "en";
//   const fr = "fr";

//   const navBar = {
//     logo: logo,
//     slogan: "Nous nous occupons de votre croissance",
//   };

//   const handleNav = () => {
//     if (language) {
//       setLanguage(!language);
//     }
//     return setNav(!nav);
//   };

//   const handleLanguage = (lang) => {
//     if (langues !== lang) {
//       setLangues(lang);
//     }
//     return setLanguage(!language);
//   };

//   // Fonction en charge de gestion des langues sur la pages
//   const ChangeLanguage = () => {
//     return (
//       <div className="relative inline-block dropdown">
//         <div className="appearance-none px-2 mx-1">
//           <MdTranslate
//             size={23}
//             onClick={() => setLanguage(!language)}
//             className=" text-gray-900 hover:text-[#444] order-2"
//           />
//         </div>

//         <div
//           className={
//             !language
//               ? "hidden"
//               : "absolute transition-all ease-in-out duration-300 mx-3 my-1 border-t border-gray-100 bg-[#FEFEFE] text-black min-w-max shadow-xl z-[1]"
//           }
//         >
//           <div
//             onClick={() => handleLanguage({ en })}
//             className="block pb-3 px-1 mx-1 sm:m-2 sm:pb-4 border-b border-b-gray-300"
//           >
//             <GB
//               title="United States"
//               className="h-6 sm:h-7 2xl:h-8 w-auto rounded hover:scale-110 transition ease-in-out duration-200"
//             />
//           </div>

//           <div
//             onClick={() => handleLanguage({ fr })}
//             className="block pt-2 px-1 mx-1 sm:m-2"
//           >
//             <FR
//               title="France"
//               className="h-6 sm:h-7 2xl:h-8 w-auto rounded hover:scale-110 ease-in-out duration-200"
//             />
//           </div>
//         </div>
//       </div>
//     );
//   };

//   // Fonction en charge de l'affache de la navbar PC
//   const NavbarPC = () => {
//     return (
//       <ul className="uppercase font-lato text-sm 2xl:text-base break-02:text-lg flex items-center">
//         <li className="p-3 break-04:p-4 cursor-pointer">
//           <Link to="/expertise" className="hover-underline-animation">
//             Our Expertises
//           </Link>
//         </li>

//         <li className="relative inline-block dropdown p-3 break-04:p-4">
//           <Link
//             to="/services"
//             className="cursor-pointer hover-underline-animation"
//           >
//             <div className="flex items-center gap-1">
//               <span>Our Services</span>
//               <span className="text-2xl">
//                 <IoMdArrowDropdown />
//               </span>
//             </div>
//           </Link>

//           <div className="absolute hidden hover:grid translate-y-2 min-w-max dropdown-content bg-[#FEFEFE] shadow-lg z-[1]">
//             <Link
//               to="/services/consulting"
//               className="appearance-none hover:bg-slate-100 p-4 transition ease-in-out duration-300"
//             >
//               Consulting
//             </Link>
//             <Link
//               to="/services/research-and-developpement"
//               className="appearance-none hover:bg-slate-100 p-4 transition ease-in-out duration-300"
//             >
//               Research & Development
//             </Link>
//           </div>
//         </li>

//         <li className="p-3 break-04:p-4 cursor-pointer">
//           <Link to="/products" className="hover-underline-animation">
//             Our Product
//           </Link>
//         </li>
//         <li className="p-3 break-04:p-4 cursor-pointer">
//           <Link to="/publication" className="hover-underline-animation">
//             Blog
//           </Link>
//         </li>
//         <li className="p-3 break-04:p-4 cursor-pointer">
//           <Link to="/career" className="hover-underline-animation">
//             Career
//           </Link>
//         </li>
//         <li className="p-3 break-04:p-4 cursor-pointer">
//           <Link to="/about" className="hover-underline-animation">
//             About us
//           </Link>
//         </li>
//         <li className="p-2 ml-4 2xl:ml-10 cursor-pointer">
//           <Link to="/contact">
//             <button
//               type="button"
//               className="uppercase items-center transition ease-in-out duration-500 px-7 py-1 bg-blue-01 text-[#FFF] border-2 hover:translate-y-1 hover:scale-110 border-blue-01 rounded-full hover:text-blue-01 hover:bg-[#FFF]"
//             >
//               Contact us
//             </button>
//           </Link>
//         </li>
//         <li className="flex items-center">
//           <ChangeLanguage />
//         </li>
//       </ul>
//     );
//   };

//   // Fonction en charge de l'affache de la navbar mobile
//   const BtnMenu = () => {
//     return (
//       <div className="">
//         <div
//           className={`"z-10 fixed top-0 left-0 flex overflow-hidden origin-left"
//             ${
//               nav
//                 ? "w-[100%] h-full flex transition-all ease-in-out duration-500"
//                 : "w-0 transition-all ease-in-out duration-500"
//             } `}
//         >
//           <div className="w-4/5 h-full bg-white-01 font-lato">
//             <div className="flex flex-col w-full p-4 items-center justify-center">
//               <Link to="/" className="">
//                 <img src={navBar.logo} alt="OFTY logo" className="" />
//               </Link>
//               <span className="text-green-01 p-2 text-center text-xs">
//                 {navBar.slogan}
//               </span>
//             </div>

//             <ul className="py-5 uppercase text-center text-xs sm:text-sm">
//               <li className="m-4 px-10">
//                 <Link
//                   to="/expertise"
//                   className="block py-2 border-b-[0.1rem] border-b-gray-400 "
//                 >
//                   Our Expertises
//                 </Link>
//               </li>
//               <li className="m-4 px-10">
//                 <Link
//                   to="/services"
//                   className="block py-2 border-b-[0.1rem] border-b-gray-400"
//                 >
//                   Our Services
//                 </Link>
//               </li>
//               <li className="m-4 px-10">
//                 <Link
//                   to="/products"
//                   className="block py-2 border-b-[0.1rem] border-b-gray-400"
//                 >
//                   Our Products
//                 </Link>
//               </li>
//               <li className="m-4 px-10">
//                 <Link
//                   to="/publication"
//                   className="block py-2 border-b-[0.1rem] border-b-gray-400"
//                 >
//                   Blog
//                 </Link>
//               </li>

//               <li className="m-4 px-10">
//                 <Link
//                   to="/career"
//                   className="block py-2 border-b-[0.1rem] border-b-gray-400"
//                 >
//                   Career
//                 </Link>
//               </li>
//               <li className="m-4 px-10">
//                 <Link
//                   to="/about"
//                   className="block py-2 border-b-[0.1rem] border-b-gray-400"
//                 >
//                   About us
//                 </Link>
//               </li>
//               <li className="m-4 px-10">
//                 <Link
//                   to="/contact"
//                   className="block py-2 border-b-[0.1rem] border-b-gray-400"
//                 >
//                   Contact us
//                 </Link>
//               </li>
//             </ul>
//           </div>
//           <div
//             className="w-1/5 h-full border-r border-r-gray-500 bg-black bg-opacity-50 flex justify-center cursor-pointer"
//             onClick={() => handleNav()}
//           >
//             <div
//               onClick={() => handleNav()}
//               className="m-3 h-10 w-10 flex justify-center items-center bg-white-01 bg-opacity-90 text-blue-01"
//             >
//               {!nav ? null : <CgClose size={30} />}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div className="bg-white-01 font-lato py-2 select-none flex sm:flex-row justify-between items-center px-3 md:px-12 2xl:pl-20 2xl:pr-16 text-blue-01 fixed-top border-b border-b-gray-100 shadow-xl">
//       {/* Gestion du logo de l'entrerise */}

//       <div className="cursor-pointer flex flex-col">
//         <Link to="/">
//           <img
//             src={navBar.logo}
//             alt="OFTY logo"
//             className="max-h-9 break-02:max-h-14"
//           />
//         </Link>
//         <span className="hidden pt-1 font-lato sm:flex break-03:flex text-xs justify-start text-green-01">
//           {navBar.slogan}
//         </span>
//       </div>

//       {/* Appel de la navbar pour PC et Desktop */}
//       <div className="hidden break-01:block">
//         <NavbarPC />
//       </div>

//       {/* Gestion de la navbar pour mobile et tablette */}

//       <div className="flex justify-around items-center select-none  break-01:hidden ">
//         <div className="m-3">{!nav ? <ChangeLanguage /> : null}</div>

//         <div onClick={() => handleNav()} className="block m-2 cursor-pointer">
//           {!nav ? <CgMenu size={35} /> : null}
//         </div>

//         <div className="">
//           <BtnMenu />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;
