import { LOCALES } from "../locales";
export default {
    [LOCALES.ENGLISH]: {
        'hello': 'Bonjour',
        'Expertise': 'Expertise',
        'Services': 'Services',
        'Produits': 'Products',
        'Blog': 'Blog',
        'Carrière': 'Career',
        'About': 'About Us',
        'Consulting': 'CONSULTING',
        'RECHERCHE & DÉVELOPPEMENT': 'Research & Development',
        'AboutHeader': 'Who are we?',
        'slogan': ' We take care of your growth',
        'contact': 'Contact Us',
        'ourServices': 'Our Services',
        'ourExpertises': 'Our Expertises',
        'ourProducts': 'Our Products',
        'insideTitle': 'We are a team of dynamic and proactive experts.',
        'insideDescribe': '',
        'insideTexte': 'At OFTY, we believe that time is a crucial value for any company ("time is money!"), and a mark of efficiency in the realization of projects. That\'s why we place great importance on meeting deadlines in the creation of our products and services.',
        'inside': 'Inside',
        'aboutTitle': 'TRUSTED SOFTWARE DEVELOPMENT COMPANY SINCE',
        'aboutDescribe': 'With over 02 years of experience and more than 850 employees on board, Iflexion serves medium to large businesses worldwide. Our deep technical knowledge, combined with our understanding of the business world, allows us to create unique solutions that power companies, their employees and their customers every day. We adhere to the latest industry trends and quality requirements to deliver powerful, secure software that fits perfectly into corporate environments and provides a positive user experience.',
        'careerTitle': 'Welcome to the team',
        'careerText': 'Solving the world\'s biggest problems requires instinct and imagination.',
        'careerApply': 'Apply now',
        'careerExplore': 'Learn about our culture',
        'learnMore': 'Learn More',
        'submit': 'Submit',
        'submitMessage': ' your message has been sent successfully',
        'contactTitle': ' LET\'S WORK TOGETHER',
        'our': 'Our',
        'services': 'Services',
        'expertises': 'Expertises',
        'lastAchievement': 'Latest achievements',
        'lastForm': 'latest from',
        'ourBlog': 'our Blog',
        'aExpertise': "Innovative solutions",
        'aService': "A Service",
        'adapterService': 'adapted to the customer\'s needs',
        'forNeed': "For A Need",
        'contactFomHeader': ' For more information, please fill out the form below.',
        'fullName': 'Full Name *',
        'companyName': 'Company Name',
        'phone': 'Phone',
        'headerDescribeExpertise': 'Ofty is a socially responsible company, researching and developing (R&D) innovative solutions for people.',
        'headerDescribeBlog': 'The latest information on Ofty\'s activities and its partners, with press articles, editorials, reports, photos articles, editorials, reviews, photos, videos, interviews with recognized R&D R&D players around the world',
        'workCarred': 'Work carried out',
        'accordingOfty': 'according to OFTY values',
        'contactLink': 'Are you looking for a digital agency to digitalize your business?',
        'contactLinkText': 'OFTY puts at your disposal its expertise in the field of digitalization of companies and a group of experts to help you develop your business.',
        'lastArticle': 'Lastest Articles',
        'aboutTitle': 'OFTY in Brief',
        'aboutContent': 'Ofty is an international Research and Development (R&D) company. The company operates mainly in the fields of information technology (web development cybersecurity, network administration, software development, information system consulting database administration, network architecture, IT development, etc.), marketing development, etc.), marketing (digital marketing), culture (sale of cultural products) and and communication (community management). Its mission: to develop innovative solutions, respectful of the environment and accessible to the greatest number. With offices in France and Cameroon, Ofty employs hundreds of people. The startup works in partnership with multiple research and technological innovation laboratories laboratories around the world.',
        'aboutMission': 'Developing innovative solutions for people. Thanks to the commitment of its employees, Ofty creates high-tech solutions that are accessible to all. As a company company, Ofty is also committed to helping build a safer world, through scientific research that scientific research activity, which is particularly committed to respecting environmental laws and environmental laws and standards.',
        'aboutVision': 'To become a major player in the global project to improve human living conditions. This ambition is fueled by the operational excellence and motivation of Ofty teams. Democratization of technological tools, promotion of cultural products development of IT solutions for disadvantaged countries, production of ecological tools. These are the types of projects under development at Ofty that allow the company to follow its the company to follow its approach of creating innovative products at the service of the human being.',
        'aboutValeurTitle1': 'Ethics',
        'aboutValeurContent1': 'Respect for intellectual property standards. Like any innovation company, Ofty conducts its activities in compliance with laws and regulations regarding intellectual intellectual property. The values of transparency and integrity are at the heart of this ethical commitment. It is a symbol of Ofty\'s desire to embody the image of a responsible responsible company.',
        'aboutValeurTitle2': 'Customer Satisfaction',
        'aboutValeurContent2': 'Prioritizing the customer\'s interest and optimizing product quality. Ofty is aware that the customer is its most valuable asset and is committed to customer satisfaction. The company works in strict compliance with high quality standards in technological innovation and industrial production.',
        'aboutValeurTitle3': 'Innovation',
        'aboutValeurContent3': 'Creation of innovative and, above all, eco-responsible products. The ecological issue is at the heart of Ofty\'s research activities. The company only invests in initiatives and and actions that take into account the environmental imperative and guarantee a better quality of life for quality of life for people. Several such projects are being conducted at Ofty, reflecting the company\'s commitment to sustainable development and CSR.',
        'aboutValeurTitle4': 'Teamwork',
        'aboutValeurContent4': 'The best solutions come from good collaborations. That\'s why Ofty is constantly working Ofty is constantly working to ensure that the working environment is conducive to the exchange and sharing of ideas. One of the company\'s major objectives is to be an exemplary employer for all its employees. Ofty has a proactive policy to promote equal treatment, inclusion and inclusion and safety in the workplace.',
        'values': 'Values',
        'team': 'Teams',
        'likeThem': 'Like them',
        'trustUs': ' trust us ',
        'contactMotive': 'Our (well-informed) experts are at your disposal to discuss your project and answer all your questions.',
        'collaborate': 'Collaborate',
        'withUs': 'with us',
        'subscribe': 'Subscribe',
        'subscribeTitle': 'Stay up to date with our latest information.',
        'accesRapide': 'Quick Access',
        'notre': 'Our',
        'of': 'of',
        'valeurs': 'Values',
        'vision': 'Vision',
        'cible': 'Target',
        'project': 'project',
        'about': 'About',
        "whatis": 'What is ',
        'consulting': ' Consulting',
        'bodyConsulting': 'Our role is to support companies in their growth by providing them with the expertise of our consultants in the digital and business strategy fields. For several years, OFTY has been recruiting experienced IT consultants (full stack developers, java, Big Data, React, Python; cyber security experts, DevOps, Node JS, React JS, Angular, Data analyst, business analyst, NEt core, C Sharp, etc.) and then offering them to partner organisations, most of them European, according to their needs. Once hired, these people have the opportunity to travel to the headquarters of the client company, as well as to work from OFTY\'s offices in Cameroon.',
        'bodyConsulting2': 'OFTY has a team of IT recruiters whose main role is that of intermediation. The mission of these talent scouts is to match the supply with the demand in terms of IT services, to put companies in touch with candidates with profiles adapted to their needs.',
        'bodyRd': 'This is OFTY\'s second operational department. This is where all the company\'s projects are designed and developed. Whether it is the creation of cultural promotion applications (Cheel, Andaal), the production of electricity from green energy (GROX, SNETBEE, ETUL) or the migration of Virtual Machines (VMs) as experienced in the TGVM project. Our research teams are made up of high quality engineers (computer scientists, mechanics, electricians, etc.) responsible for producing theoretical knowledge (scientific articles), but also for innovating by creating high added value and, above all, eco-responsible products and services. To carry out its projects, OFTY benefits from the technical assistance of well-known players in the field of science and technological innovation, including Pr Alain Tchana (lecturer at the University of Grenoble INP) and Dr Djob Mvondo (lecturer at the University of Rennes). Thanks to the quality of its R&D activity, OFTY was the only African representative at the international forum Unikraft Hackathon 2022, organised in Lyon, France.',
        'bodyRd2': ' Our research teams are made up of high quality engineers (computer scientists, mechanics, electricians, etc.) in charge of producing theoretical knowledge (scientific articles), but also of innovating by creating products and services with high added value and, above all, eco-responsible. To carry out its projects, OFTY benefits from the technical assistance of well-known actors in the field of science and technological innovation, including Pr Alain Tchana (lecturer at the University of Grenoble INP) and Dr Djob Mvondo (lecturer at the University of Rennes). Thanks to the quality of its R&D activity, OFTY was the only African representative at the international forum Unikraft Hackathon 2022, organized in Lyon, France.',
        'research': ' Research & Development ',
        'researchOfty': 'OFTY\'s other priority',
        'ResponseNewletter': 'We have taken into account your request.',
        'respSuccess': 'Sucess',
        'respError': 'Error',
        'respErrorLong': 'An error occurred during this operation',
        'consutlting': "Consulting",
        'mentionL': 'Legal Notice',
        'dataPerso': ' Privacy Policy',







    }
};