import React, { useState, useEffect } from "react";
import {
  FaTelegramPlane,
  FaFacebookSquare,
  FaTwitterSquare,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import image from "../img/logo192.png";
import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../api";
import { HashLink as Link } from "react-router-hash-link";
import { NotificationManager } from "react-notifications";
import translate from "../internationalisation/translate";

const Footer = () => {
  const [footer, setFooter] = useState({});
  const [newletter, setNewletter] = useState({});
  const [testEmail, setTestEmail] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState(false);
  const [processState, setProcessState] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
  ]);
  const dateFunction = () => {
    var b = new Date();
    return b.getUTCFullYear();
  };

  let changeProcess = (id) => {
    let tampon = [...processState];
    if (tampon[id] === false) {
      for (let index = 0; index < tampon.length; index++) {
        tampon[index] = false;
      }
      tampon[id] = true;
      setProcessState(tampon);
    }
  };
  let changeNewletter = (event) => {
    const regex =
      /^[^\W][a-zA-Z0-9\-\._]+[^\W]@[^\W][a-zA-Z0-9\-\._]+[^\W]\.[a-zA-Z]{2,6}$/;
    if (event.target.name === "email") {
      if (setVerificationEmail(regex.test(event.target.value))) {
        setTestEmail(true);
      } else {
        setTestEmail(false);
      }
    }
    setNewletter({
      ...newletter,
      [event.target.name]: event.target.value,
    });
  };
  let sendNewletter = () => {
    axios
      .post(apiUrlVitrine + "/newletter/", newletter)
      .then((res) => {
        NotificationManager.success(
          translate("ResponseNewletter"),
          translate("respSuccess"),
          5000
        );
      })
      .catch((e) => {
        NotificationManager.error(
          translate("respErrorLong"),
          translate("respError"),
          5000
        );
      });
  };
  const processData = [
    { name: translate("ourExpertises"), link: "/expertise" },
    { name: translate("ourServices"), link: "/services" },
    { name: translate("ourProducts"), link: "/products" },
    { name: translate("Blog"), link: "/publication" },
    { name: translate("Carrière"), link: "/career" },
    { name: translate("About"), link: "/about" },
    { name: translate("mentionL"), link: "/mentionlegaleofty" },
    { name: translate("dataPerso"), link: "/donneespersonnellesofty" },
  ];

  useEffect(() => {
    axios.get(apiUrlVitrine + "/footer/").then((res) => {
      setFooter(res.data[0]);
    });
  }, []);

  return (
    <div className="w-full text-white xl:text-lg break-03:text-xl">
      <div className="w-full px-4 py-6 bg-dark-01">
        <div className="grid items-center justify-center grid-cols-1 md:grid-cols-5 lg:grid-cols-7 md:gap-3">
          <div className="col-span-2 px-4 xl:px-10 lg:col-span-2">
            <img src={image} alt="OFTY logo" />
            <div className="px-2 py-4 text-gray-300 font-lato">
              {translate("slogan")}
            </div>
          </div>

          <div className="col-span-3 lg:col-span-3">
            <div className="flex flex-col my-4 text-lg font-lato lg:text-xl 2xl:text-2xl mx-7 lg:mx-14 xl:mx-16">
              <span>{translate("accesRapide")}</span>
              <span className="w-16 border-2 rounded-full bg-green-01 border-green-01"></span>
            </div>

            <div className="px-2 border-gray-400 break-03:px-8 lg:border-x">
              <div className="flex items-center gap-9 2xl:gap-20 font-lato">
                <ul className="mx-3 lg:mx-5 md:grid-cols-2 grid">
                  {processData.map((process, index) => (
                    <div
                      onClick={() => changeProcess(index)}
                      className={`${
                        processState[index] ? "text-gray-300" : "text-gray-300"
                      } mx-10 `}
                      key={index + "process"}
                    >
                      <a href={process.link}>
                        <li className="p-1 cursor-pointer">{process.name}</li>
                      </a>
                    </div>
                  ))}
                </ul>

                <div className="px-3 border-l border-green-01 break-04::mx-12 text-green-01">
                  <ul className="2xl:mx-5"></ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-3 lg:col-span-2 md:order-last lg:order-none">
            <div className="flex flex-col my-4 text-lg font-lato lg:text-xl 2xl:text-2xl mx-7 xl:mx-14">
              <span>{translate("subscribe")}</span>
              <span className="border-2 rounded-full w-14 bg-green-01 border-green-01"></span>
            </div>
            <div className="text-base font-light mx-7 font-poppins xl:mx-14">
              {translate("subscribeTitle")}
            </div>

            <div className="flex flex-col justify-center mx-6 mt-6 mb-3 text-xl sm:justify-start lg:flex-row xl:mx-14">
              <input
                type="text"
                name="email"
                onChange={changeNewletter}
                className={`block px-2 py-1.5 font-norma text-gray-700 bg-white bg-clip-padding max-w-sm lg:w-44 xl:w-auto  ${
                  !testEmail && newletter.email
                    ? "focus:border-red-700  border border-solid border-red-300  focus:outline-none  "
                    : "  border border-solid border-green-01   focus:text-gray-700 focus:outline-none "
                }
            transition ease-in-out  focus:bg-white focus:outline-none`}
                placeholder="Enter your Email.."
              />
              <button
                onClick={sendNewletter}
                disabled={!verificationEmail}
                className={`px-2 text-center text-white -translate-x-2 rounded lg:px-3 ${
                  verificationEmail ? "lg:bg-green-01" : "lg:bg-gray-500"
                } `}
              >
                <div href="#!" className="flex justify-start">
                  <span className="py-1.5 px-3 bg-green-01 rounded-sm my-2 lg:hidden">
                    S'abonner
                  </span>
                  <FaTelegramPlane className="hidden lg:flex" />
                </div>
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center col-span-2 text-2xl text-white lg:col-span-7 md:items-start lg:items-center mx-7">
            <div className="flex flex-col my-3">
              <span>Follow Us</span>
              <span className="border-2 rounded-full w-14 bg-green-01 border-green-01"></span>
            </div>

            <div className="flex items-center text-white cursor-pointer lg:justify-center">
              {/* <div className="pr-2">
                <a href={footer.facebook}>
                  <FaFacebookSquare size={40} />
                </a>
              </div>
              <div className="px-2">
                <a href={footer.twitter}>
                  {" "}
                  <FaTwitterSquare size={40} />
                </a>
              </div> */}
              <div className="px-2">
                <a href={footer.linkedin}>
                  <FaLinkedin size={40} />
                </a>
              </div>
              {/* <div className="px-2">
                <a href={footer.youtube}>
                  <FaYoutube size={50} />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center py-2 text-sm text-white bg-black sm:text-lg 2xl:text-xl">
        Copyright 2020 — {dateFunction()} © OFTY. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
