import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const DonneesPersoAndaal = () => {
  return (
    <div className="w-full bg-[#ffffff]">
      <Navbar />

      <div className="pt-40 mx-10 flex flex-col justify-start md:px-20 lg:px-60  ">
        <h1 className="text-xl md:text-2xl font-lato font-semibold text-blueT tracking-normal ">
          DONNÉES PERSONNELLES COLLECTÉES PAR L’APPLICATION ANDAAL (OFTY SAS
          France)
        </h1>
        {/* <h4 className="text-md md:text-xl font-semibold font-lato mt-2">
          Introduction
        </h4> */}
        <div className="my-2 text-sm md:text-lg font-lato">
          Cette page vise à vous informer de l'utilisation de vos données
          personnelles sur cette application . <br />
          <br />
          Andaal, Plateforme appartenant à l’entreprise OFTY France, s'engage à
          ce que les traitements de données personnelles effectués sur ce site
          soient conformes au règlement général sur la protection des données
          (RGPD) et à la loi Informatique et Libertés. <br />
          <br />
          Andaal est une application appartenant à l’entreprise OFTY SAS France
          <a className="text-blue-500 font-semibold">https://ofty.fr</a> C'est
          une application (mobile, web) destinée à démocratiser l'accès au
          savoir culturel africain. Son développement vise premièrement à offrir
          à ses utilisateurs la possibilité de consulter, commander et recevoir
          à domicile des ouvrages sur l'ensemble du territoire camerounais
          <br />
          .Deuxièmement , à offrir au public une plateforme d'informations
          officielles sur le livre au public une plateforme d' informations
          officielles sur le livre au programme , dans les deux systèmes
          éducatifs (anglophones et francophones), du primaire au secondaire et
          sur les examens officiels . <br />
          Andaal se propose d'aider par ailleurs les citoyens à acquérir la
          sagesse par le biais de la lecture, ainsi participer à la promotion de
          l'éducation et du développement des peuples et États africains tout en
          valorisant les auteurs Afro descendants. <br />
        </div>

        <div className="my-2 text-sm md:text-lg font-lato">
          Les services de Andaal sont :<br /> - Système de livraison rapide -
          Vente des fournitures scolaires ;<br /> - Ventes des ouvrages des
          auteurs Afro-descendants ;<br />+ - Consultation sur les activités
          culturelles au Cameroun et de la diaspora , les informations sur les
          concours, examens, bourses, actualités éducations ;<br /> - Calendrier
          académique des établissements publics et privés ;<br /> - Actualité
          sur les examens officiels ;<br />
          <br />
        </div>

        <div className="font-lato font md:text-lg text-sm">
          La consultation du site est libre et ne nécessite pas de compte
          utilisateur. Pour vous fournir des services personnalisés, nous
          pouvons être obligés de vous demander de vous identifier ou de
          recueillir certaines données personnelles. <br /> Dans ce cadre, OFTY
          ou Andaal est amené à traiter des informations personnelles vous
          concernant. Il s'agit par exemple d'informations identifiant vos
          consultations, de l'inscription à un service d'alertes, ou encore
          d'informations collectées lors de la création d'un compte vous
          permettant d’effectuer des achats et d’être livré .<br /> Le
          responsable de traitement est OFTY SAS 6 Rue des Tilleuls, 95870
          Bezons, France Mail :{" "}
          <span className="text-blue-500 font-semibold">contact@ofty.fr</span>
        </div>
        <h4 className="text-md  md:text-xl font-semibold font-lato mt-2">
          <br />
          Finalité du traitement <br />
          <br />
        </h4>
        <div className="font-lato font md:text-lg text-sm">
          Les données renseignées dans les formulaires servent à vous
          identifier, à savoir quel contenu vous appartient, à vous contacter si
          vous le souhaitez, par exemple à vous envoyer des alertes par courriel
          si vous le demandez.
          <br />
          <br />
        </div>
        <h4 className="text-md font-semibold font-lato md:text-xl mt-2">
          Base légale du traitement <br />
        </h4>
        <div className="font-lato font md:text-lg text-sm">
          La collecte de données s'effectue sur la base du consentement ou
          volontariat.
          <br />
          <br />
        </div>
        <h4 className="text-md font-semibold font-lato md:text-xl mt-2">
          Données enregistrées <br />
        </h4>
        <div className=" font-lato font-normal md:text-lg text-sm">
          Des données personnelles sont susceptibles d'être collectées par
          OFTY :
          <br />
          <ul>
            <li className=" font-normal">
              {" "}
              - Création de compte
              <br />
            </li>
            <div>
              Dans le cadre du formulaire de création requête, les données
              personnelles collectées sont : <br />• Nom (obligatoire) ;<br /> •
              Prénom(obligatoire) ; <br />• Adresse e-mail (obligatoire) ;{" "}
              <br />• Login/identifiant (obligatoire); <br /> • Mot de passe
              (obligatoire); <br /> • Numéro de téléphone;
              <br /> -informations de paiement Dans le cadre de l’achat d’un
              article, les données collectées sont :<br /> • Numéro de la carte
              bancaire ; <br /> • numéro de téléphone ;<br /> • CVC ; •
              Login/identifiant (obligatoire); <br /> • Mot de passe
              (obligatoire; <br /> - Informations de livraison <br /> • Adresse
              de livraison; <br /> • Quartier; <br /> • Ville; <br /> • Pays;
            </div>
          </ul>
        </div>
        <h4 className="text-md md:text-xl font-semibold font-lato mt-2">
          Consultations <br />
        </h4>
        <div className=" font-lato font-normal text-sm md:text-lg">
          {" "}
          <br />
          Dans le cadre de la consultation du site Des données de consultation
          sont enregistrées à des fins statistiques et d'audit de sécurité via
          le serveur web et conservées selon les obligations légales. Des
          statistiques de consultations sont également établies avec le logiciel
          (lequel ? préciser) en anonymisant les adresses IP. Nous conservons
          des adresses telles que 1.1.XXX.XXX Le site prend en charge la
          préférence "Ne Pas Suivre" Document mis à jour le 08 novembre 2022.{" "}
          <br />
        </div>
      </div>
      <div className="mt-10">
        <Footer />
      </div>
    </div>
  );
};

export default DonneesPersoAndaal;
