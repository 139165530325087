import React from "react";
import { useSelector } from "react-redux";
import translate from "../../internationalisation/translate";
import Button from "./ButtonServices";

const BodyServices = ({ services }) => {
  const locale = useSelector((state) => state.languageLocale);
  return (
    <div className="m-6 lg:m-10 xl:mx-20">
      <div className="py-5 mx-4 sm:py-7">
        <h3 className="text-xl font-bold font-lato sm:text-2xl lg:text-4xl">
          <span className="text-green-01">{translate("aService")}</span>
          <span className="text-blue-01"> {translate("adapterService")}</span>
        </h3>
      </div>

      <div className="grid mx-4">
        {/* Service consulting */}
        {services.map((service, index) => (
          <div
            key={index + "service"}
            className="grid items-center justify-center grid-cols-1 gap-2 py-4 my-4 indent-8 lg:pb-8 lg:grid-cols-2"
          >
            <div className={index % 2 === 0 ? "" : "lg:order-last"}>
              <div className="py-4 text-xl font-bold font-lato md:text-2xl lg:text-3xl">
                <h2 className="text-blue-01">
                  {locale === "en-us"
                    ? service.translations.en.title
                    : service.translations.fr.title}
                </h2>
              </div>
              <div className="py-4 text-lg font-semibold font-poppins md:text-xl lg:text-2xl">
                {/* <h4 className="">{ locale ==="en-us"? service.translations.en.sujet:service.translations.fr.sujet}</h4> */}
              </div>
              <div className="max-w-4xl font-light text-justify font-poppins md:text-lg 2xl:text-xl">
                <div className="py-3">
                  {locale === "en-us"
                    ? service.translations.en.description
                    : service.translations.fr.description}
                </div>
              </div>
              <div className="flex justify-start">
                <Button
                  adress={service.link}
                  name={"LEARN MORE"}
                  color={"green"}
                />
              </div>
            </div>
            <div className="flex justify-center mx-8">
              <img src={service.image} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BodyServices;
