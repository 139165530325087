import { useEffect, useState } from "react";
import { HiChevronDoubleUp } from "react-icons/hi";
import { Link } from "react-scroll";
import BodyContact from "../components/contact/BodyContact";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

import bgHeader from "../img/contact/contact-us.jpg";

import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../api";
import translate from "../internationalisation/translate";

const Contact = () => {
  const [contact, setContact] = useState({
    tel_cmr: "",
    tel_fr: ""
  });
  const describe = translate('headerDescribeExpertise')
  const header = {
    title: translate('contact'),
    describe: describe,
    image: bgHeader,
  };

  const text = (
    <div>
      Give us a call or drop by anytime, we endeavour to answer all enquiries
      within 24 hours on business days. We will be happy to answer your
      questions.
    </div>
  );



  // const contact = {
  //   text: text,
  //   tel: phone,
  //   email: "xxx@ofty.fr",
  //   location: position,
  //   maps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3980.900512241595!2d11.475708815231684!3d3.8315196496460335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x108bcfad9507347b%3A0xffed9540279ecc8e!2sOFTY%20Cameroun!5e0!3m2!1sfr!2scm!4v1661789666867!5m2!1sfr!2scm",
  //   mymaps:
  //     "https://www.google.com/maps/d/embed?mid=1uDrzh9jL4ZAuLzUGWkJVPADqO8cnWxo&ehbc=2E312F",
  // };
  useEffect(() => {
    axios.get(apiUrlVitrine + "/contact/").then((res) => {
      setContact(res.data[0]);
    })
  }, []);
  return (
    <div className="relative bg-white cursor-default">
      <div className="absolute z-50">
        <Navbar />
      </div>



      <div className="flex justify-end">
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="fixed p-2 text-2xl bg-white border border-gray-200 rounded-full shadow-xl cursor-pointer text-green-01 shadow-gray-300 right-5 bottom-14"
        >
          <HiChevronDoubleUp />
        </Link>
      </div>

      {/* <div>
        <Header header={header} />
      </div> */}

      <div>
        <BodyContact contact={contact} />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
