import React from "react";
import translate from "../../internationalisation/translate";

import Product from "./Product";

const BodyProduct = ({ products }) => {
  return (
    <div className="m-4 lg:m-10 break-02:m-20">
      <div className="py-5 mx-4 text-center sm:py-7">
        <h3 className="text-xl font-bold font-lato sm:text-2xl lg:text-4xl">
          <span className="text-green-01"> {translate('workCarred')} </span>
          <span className="text-blue-01">{ translate('accordingOfty')}</span>
        </h3>
      </div>

      <div className="grid mx-4">
        {products.map((product, index) => (
          <div
            key={product.id}
            className={
              index % 2 === 0 ? "lg:translate-x-6" : "-lg:translate-x-6"
            }
          >
            <Product product={product} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BodyProduct;
