import React from "react";
import { HiOutlineArrowRight } from "react-icons/hi";

import image from "../../img/career_1.jpg";
import translate from "../../internationalisation/translate";

const Career = () => {
  const career = {
    title: "Welcome to the Team",
    text: "Solving the world's biggest problems requires instinct and imagination.",
    image: image,
  };

  const linkApply = { url: "/career", text: "Apply Now" };

  const linkExplore = { url: "/about", text: "Explore our culture" };

  return (
    <div className="mx-6 lg:mx-10 xl:mx-16 grid grid-cols-1 xl:grid-cols-2 gap-4 items-center">
      <div className="m-4 lg:m-12 text-left grid">
        <h3 className="font-bold text-2xl lg:text-3xl 2xl:text-4xl py-2 font-lato">
          <span className="text-green-01">OFTY </span>
          <span className="text-blue-01">{translate("carrière")}</span>
        </h3>

        <h1 className="font-bold font-lato text-black text-2xl md:text-3xl lg:text-5xl break-02:text-7xl mb-5 lg:mb-7">
          {translate("careerTitle")}
        </h1>

        <p className="text-gray-700 font-poppins font-light text-xl lg:text-2xl break-02:text-3xl py-4 lg:py-8">
          {translate("careerText")}
        </p>

        <div className="font-medium font-poppins text-gray-800 text-xl lg:text-2xl break-02:text-3xl">
          <a href={linkApply.url} className="flex gap-2 p-3">
            <span className="text-green-01">
              <HiOutlineArrowRight />
            </span>
            <span className="cursor-pointer hover:text-green-01 transition-colors duration-400 origin-left">
              {translate("careerApply")}
            </span>
          </a>

          <a href={linkExplore.url} className="flex gap-2 p-3">
            <span className="text-green-01">
              <HiOutlineArrowRight />
            </span>
            <span className=" cursor-pointer hover:text-green-01 transition-colors duration-400 origin-left">
              {translate("careerExplore")}
            </span>
          </a>
        </div>
      </div>
      <div className="m-4 flex justify-center">
        <img
          src={career.image}
          alt="..."
          className="break-02:w-[48rem] lg:ml-16 border-t-4 border-t-blue-01"
        />
      </div>
    </div>
  );
};

export default Career;
