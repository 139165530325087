import React from "react";

const Section2 = ({ section }) => {
  return (
    <div>
      <div className="py-2 2xl:py-4 font-poppins text-justify text-sm md:text-base lg:text-lg break-02:text-xl">
        {section.intro}
      </div>

      <div className="py-2 lg:py-4 font-lato font-semibold md:text-lg lg:text-xl 2xl:text-2xl break-02:text-3xl">
        {section.title}
      </div>

      <div className="py-2 2xl:py-4 font-poppins font-light text-justify text-sm md:text-base lg:text-lg break-02:text-xl">
        {section.describe}
      </div>

      <div className="flex justify-center my-4 2xl:my-8">
        <img src={section.image} alt="" />
      </div>
    </div>
  );
};

export default Section2;
