import React from "react";
import { FaConnectdevelop } from "react-icons/fa";
import { TbCircleSquare } from "react-icons/tb";
import Button from "./Button";
import translate from "../../internationalisation/translate.js"

import bg_1 from "../../img/services_1.png";
import bg_2 from "../../img/services_2.png";
import { useSelector } from "react-redux";

const Services = ({ services }) => {
  const locale =useSelector((state)=>state.languageLocale);
  const bgService1 = {
    backgroundImage: `url(${services[0].imageHome})`,
  };

  const bgService2 = {
    backgroundImage: `url(${services[1].imageHome})`,
  };

  return (
    <div className="pt-20 bgDegradeInv">
      <div className="pt-10 pb-16 text-2xl font-bold text-center uppercase sm:text-3xl lg:text-4xl">
        <span className="p-2 text-white bg-green-01">{ translate('our')}</span>
        <span className="text-blue-01"> {translate('services')} </span>
      </div>

      <div className="flex flex-col justify-around p-4 sm:flex-row lg:py-8">
        <div className="flex justify-center bg-center sm:ml-10">
          <div
            className="w-72 lg:w-96 xl:w-[32rem] lg:h-[37rem] xl:h-[40rem] bg-local bg-cover bg-center m-4"
            style={bgService1}
          >
            <div className="w-full h-full bg-white shadow-2xl bg-opacity-80 bg-origin-border shadow-blue-200">
              <div className="p-2 sm:p-5">
                <TbCircleSquare size={40} />
              </div>
              <div className="flex items-center h-48 lg:h-52 lg:items-end">
                <h1 className="text-3xl font-bold px-9 xl:text-5xl text-blue-01 font-lato">
                { locale ==="en-us"? services[0].translations.en.title:services[0].translations.fr.title}
                </h1>
              </div>
              <div className="flex items-end h-36 lg:h-40 xl:h-56">
                <p className="text-lg m-9 sm:text-xl xl:text-2xl font-poppins">
                { locale ==="en-us"? services[0].translations.en.abstract:services[0].translations.fr.abstract}
                </p>
              </div>
              <div className="flex items-end justify-center h-20 lg:h-28">
                <Button
                  adress={services[0].link}
                  name={translate('learnMore')}
                  color={"green"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center bg-center sm:mr-10">
          <div
            className="w-72 lg:w-96 xl:w-[32rem] lg:h-[38rem] xl:h-[40rem] bg-local bg-cover bg-center m-4"
            style={bgService2}
          >
            <div className="w-full h-full bg-white shadow-2xl bg-opacity-80 bg-origin-border shadow-blue-200">
              <div className="p-2 sm:p-5">
                <FaConnectdevelop size={40} />
              </div>
              <div className="flex items-center h-48 lg:h-52 lg:items-end">
                <h1 className="text-3xl font-bold px-9 xl:text-5xl text-blue-01 font-lato">
               
                  { locale ==="en-us"? services[1].translations.en.title:services[1].translations.fr.title}
                </h1>
              </div>
              <div className="flex items-end h-36 lg:h-40 xl:h-56">
                <p className="text-lg m-9 sm:text-xl 2xl:text-2xl font-poppins">
                   { locale ==="en-us"? services[1].translations.en.abstract:services[1].translations.fr.abstract}
                </p>
              </div>
              <div className="flex items-end justify-center h-20 lg:h-28">
                <Button
                  adress={services[1].link}
                  name={translate('learnMore')}
                  color={"green"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
