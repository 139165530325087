import React from "react";
import { FaPhoneAlt, FaWhatsapp, FaEnvelope } from "react-icons/fa";
import { TbMapSearch } from "react-icons/tb";
import translate from "../../internationalisation/translate";
import Button from "../services/ButtonServices";
import Form from "./Form";
import Maps from "./Maps";

const BodyContact = ({ contact }) => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  const sizeWidth = (value) => {
    let newWidth = window.innerWidth;
    return value >= 1280 ? (value / 3) * 2 - 175 : newWidth;
  };

  const sizeHeight = (value) => {
    return value / 2;
  };

  return (
    <div className="pt-32">
      <div className="flex flex-col items-center gap-3 xl:flex-row">
        <div className="mx-8 xl:-translate-y-32 xl:w-1/2 lg:mx-10 2xl:ml-20">
          <div className="">
            <h3 className="py-5 mb-4 text-xl font-bold font-lato sm:text-2xl lg:text-4xl sm:py-7">
              <span className="text-green-01">Contact </span>
              <span className="text-blue-01">OFTY</span>
            </h3>

            <div className="my-5 font-poppins sm:text-lg 2xl:text-xl">
              {translate("contactMotive")}
            </div>
          </div>

          <div className="flex flex-col justify-between mt-10 sm:flex-row break-02:max-w-4xl font-poppins xl:text-sm break-05:text-base break-02:text-lg">
            <div className="grid items-center justify-center p-2 mb-4 sm:mb-0">
              <div className="flex justify-center">
                <FaPhoneAlt className="mb-4 text-green-01" size={35} />
              </div>
              <div>
                <div className="grid gap-2">
                  <a href={`tel:${contact.tel_fr}`}></a>
                  <div className="flex">
                    <a href={`tel:${contact.tel_fr}`} className="flex ">
                      France:{" "}
                      <span className="text-blue-500 mx-2">
                        {contact.tel_fr}{" "}
                      </span>
                      <FaPhoneAlt
                        className="mx-4 text-green-01  rotate-[240deg] "
                        size={15}
                      />
                    </a>
                  </div>
                  <div className="flex my-3">
                    <a href={`tel:${contact.tel_cmr}`} className="flex ">
                      Cameroun:{" "}
                      <span className="text-blue-500 mx-2">
                        {contact.tel_cmr}{" "}
                      </span>
                      <FaPhoneAlt
                        className="mx-4 text-green-01 rotate-[240deg] "
                        size={15}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid items-center justify-center p-2 mb-4 sm:mb-0">
              <div className="flex justify-center">
                <FaWhatsapp className="mb-4 text-green-01" size={40} />
              </div>
              <div>
                <div className="grid gap-2">
                  <div className="flex">
                    <a
                      href={` https://wa.me/${
                        contact != {}
                          ? contact.tel_fr.split(" ").join("").replace("+", "")
                          : ""
                      }`}
                      className="flex "
                    >
                      France:{" "}
                      <span className="text-blue-500 mx-2">
                        {contact.tel_fr}{" "}
                      </span>
                      <FaPhoneAlt
                        className="mx-4 text-green-01  rotate-[240deg] "
                        size={15}
                      />
                    </a>
                  </div>
                  <div className="flex my-3">
                    <a
                      href={` https://wa.me/${
                        contact != {}
                          ? contact.tel_cmr.split(" ").join("").replace("+", "")
                          : ""
                      }`}
                      className="flex "
                    >
                      Cameroun:{" "}
                      <span className="text-blue-500 mx-2">
                        {contact.tel_cmr}{" "}
                      </span>
                      <FaPhoneAlt
                        className="mx-4 text-green-01 rotate-[240deg] "
                        size={15}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid items-center justify-center p-2 mb-4 sm:mb-0">
              <div className="flex justify-center">
                <FaEnvelope className="mb-4 text-green-01" size={35} />
              </div>
              <div>
                <a className="text-blue-400" href={`mailto:${contact.email}`}>
                {contact.email}
                </a>
                </div>
            </div>
          </div>
        </div>

        <div className="xl:w-1/2">
          <Form />
        </div>
      </div>

      <div className="flex flex-col xl:flex-row xl:items-center">
        <div className="w-full mx-8 xl:w-2/5 xl:order-last xl:mx-16">
          <div className="flex flex-col">
            <div className="grid xl:order-last xl:mt-8">
              <div>
                <h3 className="py-5 text-xl font-bold font-lato sm:text-2xl lg:text-4xl sm:py-7">
                  <span className="text-green-01">
                    {translate("collaborate")}{" "}
                  </span>
                  <span className="text-blue-01">{translate("withUs")} </span>
                </h3>

                {/* <div className="flex">
                  <div className="mr-4">
                    <Button
                      adress={"/career"}
                      name={translate('careerApply')}
                      color={"green"}
                    />
                  </div>

                  <div className="ml-4">
                    <Button
                      adress={"/career"}
                      name={translate('careerExplore')}
                      color={"green"}
                    />
                  </div>
                </div> */}
              </div>
            </div>

            <div className="grid items-center py-2 mt-10 mb-4 xl:mt-0 xm:mb-10 ">
              <div className="flex">
                <TbMapSearch className="mb-4 text-green-01" size={45} />
              </div>
              <div>
                France: {contact.localisation_fr} <br />
                Cameroun: {contact.localisation_cmr}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center w-full xl:w-3/5">
          <Maps
            maps={contact.maps}
            width={sizeWidth(width)}
            height={sizeHeight(height)}
          />
        </div>
      </div>
    </div>
  );
};

export default BodyContact;
