import React, { useState } from "react";
import translate from "../../internationalisation/translate.js"

import { HashLink as Link } from "react-router-hash-link";
import { useSelector } from "react-redux";

const Expertise = ({ expertises }) => {
  const locale =useSelector((state)=>state.languageLocale);
  return (
    <div className="py-4 xl:py-8 bgDegrade">
      <div className="py-4 my-8 text-2xl font-bold text-center uppercase font-lato sm:text-3xl lg:text-4xl xl:py-10">
        <span className="p-2 text-white bg-green-01">{ translate('our')}</span>
        <span className="text-blue-01">{  translate('expertises')}</span>
      </div>

      <div className="grid grid-cols-1 gap-1 pl-5 md:grid-cols-2 sm:px-12 break-04:mx-32">
        {expertises.map((expertise, index) => {
          return (
            <div
              className={
                index % 2 === 0
                  ? "flex justify-end m-4 2xl:m-8 p-4 2xl:p-8"
                  : "flex justify-start m-4 2xl:m-8 p-4 2xl:p-8"
              }
              key={index + "expertise"}
            >
              <div className="shadow-xl bg-white lg:max-w-[30rem] overflow-hidden">
                <Link to={"expertise/#id" + (index + 1)}>
                  <img
                    className="w-full h-auto transition duration-300 ease-in-out delay-75 hover:opacity-70 hover:scale-110"
                    src={expertise.imageHome}
                    alt="..."
                  />
                </Link>
                <div className="p-4 bg-blue-01 bg-opacity-5">
                  <h5 className="p-2 text-base font-semibold text-gray-900 font-lato sm:text-2xl 2xl:text-3xl">
                    { locale ==="en-us"? expertise.translations.en.title:expertise.translations.fr.title}
                  </h5>
                  <p className="px-2 font-normal text-justify text-gray-700 lg:py-2 sm:text-lg 2xl:text-xl font-poppins">
                    {locale ==="en-us"? expertise.translations.en.abstract:expertise.translations.fr.abstract}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Expertise;
