import { LOCALES } from "../locales";
export default {
    [LOCALES.FRENCH]: {
        'hello': 'Bonjour',
        'Expertise': 'Expertise',
        'Services': 'Services',
        'Produits': 'Produits',
        'Blog': 'Blog',
        'Career': 'Carrière',
        'About': 'À propos',
        'Consulting': 'CONSULTANTE',
        'Research & Development': 'RECHERCHE & DÉVELOPPEMENT',
        'AboutHeader': 'Qui sommes-nous ?',
        'slogan': 'Nous nous occupons de votre croissance',
        'contact': 'Contactez-Nous',
        'ourServices': 'Nos Services',
        'ourExpertises': 'Nos Expertises',
        'ourProducts': 'Nos Produits',
        'insideTitle': 'Nous sommes une équipe d’experts dynamiques et proactifs.',
        'insideDescribe': '',
        'insideTexte': 'Chez OFTY, nous pensons que le temps est une valeur cruciale pour toute entreprise ("le temps c’est de l’argent !"), et une marque d’efficacité dans la réalisation des projets. C\'est pourquoi, nous accordons une importance capitale au respect des délais dans la création de nos produits et services.',
        'inside': 'À l\'intérieur de',
        'aboutTitle': 'SOCIÉTÉ DE DÉVELOPPEMENT DE LOGICIELS DE CONFIANCE DEPUIS',
        'aboutDescribe': 'Avec plus de 02 ans d\'expérience et plus de 850 employés à son bord, Iflexion sert les moyennes et grandes entreprises dans le monde entier. Nos connaissances techniques approfondies, associées à notre compréhension du monde des affaires, nous permettent de créer des solutions uniques qui alimentent chaque jour les entreprises, leurs employés et leurs clients. Nous nous conformons aux dernières tendances du secteur et aux exigences de qualité pour fournir des logiciels puissants et sécurisés qui s\'adaptent parfaitement aux environnements des entreprises et apportent une expérience positive aux utilisateurs.',
        'careerTitle': 'Bienvenue dans l\'équipe',
        'careerText': 'La résolution des plus grands problèmes du monde requiert de l\'instinct et de l\'imagination.',
        'careerApply': 'Postulez maintenant',
        'careerExplore': 'Découvrez notre culture',
        'learnMore': 'Savoir Plus',
        'submit': 'Soumettre',
        'submitMessage': 'votre message a été envoyé avec success',
        'contactTitle': ' TRAVAILLONS ENSEMBLE',
        'our': 'Nos',
        'services': 'Services',
        'expertises': 'Expertises',
        'lastAchievement': 'Dernières réalisations',
        'lastForm': 'Dernières nouvelles de',
        'ourBlog': 'notre Blog',
        'aExpertise': "Des solutions innovantes",
        'aService': "Un Service",
        'adapterService': 'adapté au besoin du client',
        'forNeed': "pour un besoin précis ",
        'contactFomHeader': 'Pour plus d’informations, remplissez le formulaire ci-dessous.',
        'fullName': 'Nom Prenom *',
        'companyName': 'Nom de la companie',
        'phone': 'Téléphone',
        'headerDescribeExpertise': 'Ofty est une entreprise citoyenne, de Recherche et Développement (R&D)des solutions innovantes au service de l’humain.',
        'headerDescribeBlog': 'Les dernières informations sur les activités d’Ofty et ses partenaires, avec des articles depresse, des éditoriaux, des comptes rendus, des photos, des vidéos, des interviews d’acteurs reconnus de la R&D dans le monde',
        'workCarred': 'Travaux réalisés',
        'accordingOfty': 'selon les valeurs d\'OFTY',
        'contactLink': 'Vous cherchez une agence digitale pour numériser votre entreprise ?',
        'contactLinkText': 'OFTY met à votre disposition son expertise dans le domaine de la numérisation des entreprises et un groupe d\'experts pour vous aider à développer votre activité.',
        'lastArticle': 'Derniers articles',
        'aboutTitle': 'OFTY en Bref ',
        'aboutContent': 'Ofty est une entreprise internationale de Recherche et Développement (R&D). L’entreprise opère principalement dans les domaines de l’informatique (développement web, cybersécurité, administration de réseau, développement logiciel, consultation en système d’information, administration de bases de données, architecture réseau, développement informatique, etc.), du marketing (marketing digital) de la culture (vente de produits culturels) et de la communication (community management). Sa mission: développer des solutions innovantes, respectueuses de l’environnement et accessibles au plus grand nombre. Implanté en France et au Cameroun, Ofty emploie des centaines de collaborateurs. La startup travaille en partenariat avec de multiples laboratoires de recherche et d’innovation technologique à travers le monde.',
        'aboutMission': 'Développer des solutions innovantes au service de l’humain. Grâce à l’engagement de ses collaborateurs, Ofty crée des solutions de haute technologie accessibles à tous. Entreprise citoyenne, Ofty s’engage également à participer à la construction d’un monde plus sûr, à partir d’une activité de recherche scientifique particulièrement attachée au respect des lois et normes environnementales.',
        'aboutVision': 'Devenir un acteur majeur du projet global d’amélioration des conditions de vie de l’homme. Cette ambition se nourrit de l’excellence opérationnelle et de la motivation des équipes de Ofty. Démocratisation des outils technologiques, promotion des produits culturels, développement des solutions informatiques pour les pays défavorisés, production des outils écologiques… Tel est le type de projets en développement chez Ofty et qui permettent à l\'entreprise de suivre sa démarche de création des produits innovants au service de l’humain.',
        'aboutValeurTitle1': 'Éthique',
        'aboutValeurContent1': 'Respect des normes de la propriété intellectuelle. Comme toute entreprise d’innovation, Ofty mène ses activités en conformité avec les lois et règlements en matière de production intellectuelle. Les valeurs de transparence et d\'intégrité sont au cœur de cet engagementéthique. Elle est le symbole de la volonté d’Ofty d’incarner l’image d’une entreprise responsable.',
        'aboutValeurTitle2': 'Satisfaction du client',
        'aboutValeurContent2': 'Priorisation de l’intérêt du client et optimisation de la qualité des produits. Conscient que le client est sa plus précieuse richesse, Ofty veille à la satisfaction de ses clients. L’entreprise travaille dans le strict respect des hauts standards de la qualité en matière d’innovation technologique et de production industrielle.',
        'aboutValeurTitle3': 'Innovation',
        'aboutValeurContent3': 'Création de produits innovants et, surtout éco-responsables. L’enjeu écologique est au cœur des activités de recherche chez Ofty. L’entreprise s’investit uniquement dans des initiatives et actions qui prennent en compte l’impératif environnemental et garantissent une qualité de vie meilleure aux individus. Plusieurs projets de ce type sont menés chez Ofty, ce qui reflète son engagement pour le développement durable et sa démarche RSE.',
        'aboutValeurTitle4': 'Travail d’équipe',
        'aboutValeurContent4': 'Les meilleures solutions naissent de bonnes collaborations. C’est pourquoi Ofty travaille en permanence à la garantie d’un cadre de travail favorable à l’échange et au partage des idées. L’un des objectifs majeurs de l\'entreprise est d’être un employeur exemplaire pour tous ses collaborateurs. Ainsi, Ofty mène-t-il une politique volontariste en faveur de l’égalité de traitement, de l’inclusion et de la sécurité au travail.',
        'values': 'Valeurs',
        'team': 'Equipes',
        'likeThem': 'Comme eux',
        'trustUs': ' faites-nous confiance ',
        'contactMotive': 'Nos experts sont à votre écoute pour échanger autour de votre projet et répondre à toutes vos questions.',
        'collaborate': 'Collaborez',
        'withUs': ' avec nous',
        'subscribe': 'S\'abonner ',
        'subscribeTitle': 'Restez à jour avec nos dernières informations.',
        'accesRapide': 'Accès Rapide ',
        'notre': 'Notre',
        'ResponseNewletter': 'Nous avons pris en compte votre demande.',
        'of': 'de',
        'valeurs': 'valeurs',
        'vision': 'Vision',
        'cible': 'Cible',
        'project': 'projet',
        'about': 'A propos',
        "whatis": 'C\'est quoi ',
        'consulting': ' le Consulting',
        'bodyConsulting': 'Notre rôle est d’accompagner les entreprises dans leur croissance en mettant à leur disposition l’expertise de nos consultants dans les métiers du numérique et de la stratégie d\'entreprise. Depuis plusieurs années, OFTY recrute des consultants en IT expérimentés (développeurs full stack, java, Big Data, React, Python; experts cyber-sécurité, DevOps, Node JS, React JS, Angular, Data analyst, business analyst, NEt core, C Sharp, etc.) pour ensuite les proposer à des structures partenaires, européennes pour la plupart, en fonction de leurs besoins. Une fois embauchées, ces personnes ont l’opportunité de voyager pour rejoindre le siège de l\'entreprise cliente; tout comme elles peuvent faire le choix de travailler à partir des bureaux de OFTY au Cameroun. ',
        'bodyConsulting2': 'OFTY dispose d’une équipe de recruteurs IT dont le rôle principal est celui de l’intermédiation. Ces dénicheurs de talents ont pour mission de rapprocher l’offre de la demande en matière de services informatiques, de mettre en relation les sociétés avec les candidats ayant des profils adaptés à leurs besoins.',
        'bodyRd': 'C’est le deuxième département opérationnel de OFTY. C’est ici que sont élaborés et développés tous ses projets de l’entreprise. Qu’il s’agisse de la création d’applications de promotion culturelle (Cheel, Andaal), de la production d’électricité à partir des énergies vertes (GROX, SNETBEE, ETUL) ou encore de la migration des Machines Virtuelles (VM) telle que expérimentée dans le cadre du projet TGVM.',
        'bodyRd2': ' Nos équipes de recherche sont constituées de d’ingénieurs de haute qualité, (informaticiens, mécaniciens, électriciens, etc.) chargés de produire des connaissances théoriques (articles scientifiques), mais aussi d’innover en créant des produits et services à forte valeur ajoutée et, surtout, éco-responsables. Pour mener à bien ses projets, OFTY bénéficie de l’assistance technique d’acteurs connus dans le domaine de la science et l’innovation technologique, au rang desquels Pr Alain Tchana (enseignant à l’université de Grenoble INP) ou encore le Dr Djob Mvondo (Maître de conférences à l’Université de Rennes). Grâce à la qualité de son activité de R&D, OFTY était le seul représentant africain au forum international Unikraft Hackathon 2022, organisé à Lyon, en France.',
        'research': ' la Recherche & Développement ',
        'respSuccess': 'Succès',
        'researchOfty': 'L\'autre priorité d\'OFTY',
        'respError': 'Erreur',
        'respErrorLong': 'Une Erreur survenu lors de cet opération ',
        'consutlting': "Consulting",
        'mentionL': 'Mentions Légales',
        'dataPerso': 'Politique de confidentialité ',



    }
};