import React from "react";

import image from "../../img/about/about.png";

import image2 from "../../img/about/vision22.png";
import image3 from "../../img/about/mission.png";
import Button from "../services/ButtonServices";
import translate from "../../internationalisation/translate";

const Content = () => {
  const content = {
    title: translate("aboutTitle"),
    describe: translate("aboutContent"),
    image: image,
  };

  const vision = {
    text: translate("aboutVision"),
    image: image2,
  };
  const mission = {
    text: translate("aboutMission"),
    image: image3,
  };

  return (
    <div className="m-6 lg:m-10 xl:mx-20">
      <div>
        <div className="grid grid-cols-1 gap-2 py-4 my-4 lg:pb-8 lg:grid-cols-2 justify-center items-center">
          <div className="">
            <div className="font-lato py-4 font-bold text-xl lg:text-2xl 2xl:text-3xl">
              <h2 className="">{content.title}</h2>
            </div>
            <div className="max-w-4xl break-02:max-w-none font-poppins font-light text-justify lg:text-lg break-02:text-xl">
              <p className="py-3 indent-8">{content.describe}</p>
            </div>
          </div>

          <div className="mx-8 flex justify-center">
            <img
              src={content.image}
              alt=""
              className="break-02:min-w-[38rem]"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-1 gap-3 md:gap-10 md:pb-8 md:grid-cols-2 justify-center items-center">
          <div className="md:order-last">
            <div className="py-5 sm:py-7">
              <h3 className="font-bold font-lato text-xl sm:text-2xl lg:text-4xl">
                <span className="text-green-01">{translate("notre")} </span>
                <span className="text-blue-01">mission</span>
              </h3>
            </div>
            <div className="max-w-4xl break-02:max-w-none font-poppins font-light lg:text-lg break-02:text-xl">
              <p className="py-3 indent-8">{mission.text}</p>
            </div>
            <div className="flex justify-start items-center">
              <Button adress={"/contact"} name={"CONTACT US"} color={"green"} />
            </div>
          </div>

          <div className="mx-8 flex justify-center">
            <img
              src={mission.image}
              alt=""
              className="break-02:min-w-[38rem]"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="grid grid-cols-1 gap-3 md:gap-10 md:pb-8 md:grid-cols-2 justify-center items-center">
          <div className="">
            <div className="py-5 sm:py-7">
              <h3 className="font-bold font-lato text-xl sm:text-2xl lg:text-4xl">
                <span className="text-green-01">{translate("notre")} </span>
                <span className="text-blue-01">Vision</span>
              </h3>
            </div>
            <div className="max-w-4xl break-02:max-w-none font-poppins font-light lg:text-lg break-02:text-xl">
              <p className="py-3 indent-8">{vision.text}</p>
            </div>
            <div className="flex justify-start items-center">
              <Button adress={"/contact"} name={"CONTACT US"} color={"green"} />
            </div>
          </div>

          <div className="mx-8 flex justify-center">
            <img src={vision.image} alt="" className="break-02:min-w-[38rem]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
