import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import header from "../img/heade404r.svg";

const Page404 = () => {
  return (
    <div className="relative bg-white cursor-default">
      <div className="absolute z-50">
        <Navbar />
      </div>
      <div className="w-full h-screen items-center flex place-content-center ">
        <img src={header} className="object-contain" alt="" />
      </div>
      <Footer />
    </div>
  );
};

export default Page404;
