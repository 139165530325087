import React from "react";

const Partners = ({ logos }) => {
  return (
    <div className="m-4 lg:m-10">
      <div className="uppercase font-lato font-bold text-2xl sm:text-3xl lg:text-4xl text-center py-16">
        <span className="p-2 text-white bg-green-01">Our</span>
        <span className="text-blue-01"> Partners</span>
      </div>

      <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-center justify-center">
        {logos.map((logo) => {
          return (
            <div className="p-3 m-2 flex justify-center" key={logo.id}>
              <img
                src={logo.logo}
                alt={logo.name}
                className="hover:scale-110 transition ease-in-out duration-200 delay-200"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Partners;
