import React from "react";

import bg from "../../img/about/bg-feedback.png";
import person from "../../img/about/person.png";
import translate from "../../internationalisation/translate";
import Element from "./Element";

const Feedback = ({ feedback }) => {
  const bgFeed = {
    backgroundImage: `url(${bg})`,
  };

  return (
    <div className="py-4 lg:py-10 select-none">
      <h3 className="font-bold font-lato text-2xl xl:text-3xl break-02:text-4xl p-4 m-4">
        <span className="text-green-01">{translate('likeThem')}, </span>
        <span className="text-blue-01">{translate('trustUs')}</span>
      </h3>

      <div
        className="bg-local bg-center bg-cover grid items-center py-6 sm:h-[32rem] 2xl:h-[36rem] break-02:h-[38rem] min-h-max w-full "
        style={bgFeed}
      >
        <div
          id="carouselExampleControls"
          className="carousel slide relative"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner relative w-full overflow-hidden">
            {feedback.map((feed, index) => (
              <div
                key={feed.id}
                className={` ${
                  index === 0 ? "active" : ""
                } carousel-item relative float-left w-full`}
              >
                <div className="flex justify-center">
                  <Element feedback={feed} />
                </div>
              </div>
            ))}
          </div>

          <button
            className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="cursor-pointer carousel-control-next absolute  top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Feedback;

/* <div className="h-full">
  <div className="flex items-center justify-center h-full w-full">
    <div className="w-11/12 md:w-2/3 2xl:w-3/4 bg-white bg-opacity-60 rounded p-4 2xl:p-8 text-center">
      <div className="flex justify-center py-2">
        <div className="w-[11rem] h-[11rem] rounded-full overflow-hidden">
          <img src={feedback.image} alt="..." className="w-full h-full " />
        </div>
      </div>

      <div className="font-lato font-semibold text-xl 2xl:text-2xl break-02:text-3xl p-2 md:p-3">
        {feedback.name}
      </div>

      <div className="font-poppins font-medium text-lg 2xl:text-xl break-02:text-2xl p-3">
        {feedback.company}
      </div>

      <div className="font-poppins font-light 2xl:text-lg break-02:text-xl p-3 flex">
        <span className="text-gray-700">
          <ImQuotesLeft />
        </span>
        <span className="mx-2">{feedback.message}</span>
        <span className="flex items-end -translate-y-3 text-gray-700">
          <ImQuotesRight />
        </span>
      </div>
    </div>
  </div>
</div>; */
