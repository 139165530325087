import axios from "axios";
import { useState } from "react";
// import { FaCircle } from "react-icons/fa";
import Button from "./Button";
import { apiUrlVitrine, defaultHeaders } from "../../api";
import { NotificationManager } from "react-notifications";
import translate from "../../internationalisation/translate";

const FormContact = () => {
  const [message, setMessage] = useState({});
  const [stateMessage, setStateMessage] = useState(false);
  const [testEmail, setTestEmail] = useState(false);

  let handleChange = (event) => {
    const regex =
      /^[^\W][a-zA-Z0-9\-\._]+[^\W]@[^\W][a-zA-Z0-9\-\._]+[^\W]\.[a-zA-Z]{2,6}$/;
    if (event.target.name === "email") {
      if (regex.test(event.target.value)) {
        setTestEmail(true);
      } else {
        setTestEmail(false);
      }
    }
    setMessage({
      ...message,
      [event.target.name]: event.target.value,
    });
  };
  let sendMessage = (event) => {
    event.preventDefault();
    axios
      .post(apiUrlVitrine + "/message/", message)
      .then((res) => {
        setStateMessage(true);
        NotificationManager.success(
          translate("ResponseNewletter"),
          translate("respSuccess"),
          5000
        );
      })
      .catch((e) => {
        NotificationManager.error(
          translate("respErrorLong"),
          translate("respError"),
          5000
        );
      });
  };
  const link = { url: "#!", text: "Submit", color: "green" };

  const style =
    "form-control block w-full px-3 py-1.5 font-poppins text-lg 2xl:text-xl break-02:text-2xl text-gray-700 bg-[#FFF] bg-clip-padding rounded border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-[#FFF] focus:border-blue-01 focus:outline-none";

  return (
    <div className="block w-full max-w-4xl p-3 bg-white border border-gray-200 rounded shadow-2xl">
      <div className="p-6 text-center text-sm 2xl:text-base text-[#444] font-poppins">
        {!stateMessage ? " Fill in the form!" : ""}
      </div>
      {!stateMessage ? (
        <form onSubmit={sendMessage} className="max-w-5xl px-10">
          <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:mb-0">
            <div className="mb-6 form-group">
              <input
                type="text"
                required
                name="name"
                onChange={handleChange}
                className={style}
                placeholder="Full Name *"
              />
            </div>

            <div className="mb-6 form-group">
              <input
                type="text"
                required
                name="company"
                onChange={handleChange}
                className={style}
                placeholder="Company Name"
              />
            </div>
          </div>

          <div className="grid gap-4 sm:grid-cols-2">
            <div className="mb-6 form-group">
              <input
                type="email"
                required
                name="email"
                onChange={handleChange}
                className={`form-control px-3 py-1.5  block w-full text-lg font-normal text-[#444] bg-white-01 bg-clip-paddingrounded transition ease-in-out m-0 focus:text-[#444] focus:bg-white-01 ${
                  !testEmail && message.email
                    ? "focus:border-red-700  border border-solid border-red-300  focus:outline-none  "
                    : " focus:border-blue-01  focus:outline-none  border border-solid border-gray-300  "
                }`}
                placeholder="Email *"
              />
            </div>

            <div className="mb-6 form-group">
              <input
                type="text"
                required
                name="tel"
                onChange={handleChange}
                className={style}
                placeholder="Phone *"
              />
            </div>
            <div className="mb-6 form-group">
              <input
                type="text"
                required
                name="object"
                onChange={handleChange}
                className={style}
                placeholder="Object *"
              />
            </div>
          </div>
          <div className="mb-6 form-group">
            <textarea
              name="body"
              onChange={handleChange}
              required
              className="form-control block w-full px-3 py-1.5 text-lg 2xl:text-xl break-02:text-2xl text-gray-700 bg-[#FFF] bg-clip-padding rounded
                    border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-[#FFF] focus:border-blue-01 focus:outline-none"
              rows="3"
              placeholder="Message *"
            ></textarea>
          </div>

          <div className="flex items-start justify-center mb-6 text-center form-group form-check">
            <input
              type="checkbox"
              className="form-check-input appearance-none h-3 w-3 xl:h-5 xl:w-5 border border-gray-300 rounded-sm bg-[#FFF] checked:bg-blue-01 checked:border-blue-01 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
            />
            <label
              className="form-check-label xl:text-xl inline-block text-[#444]"
              htmlFor="exampleCheck25"
            >
              Subscribe to our newsletter
            </label>
          </div>
          <div className="flex justify-end">
            <button disabled={!testEmail}>
              <Button
                adress={link.url}
                name={translate("submit")}
                color={link.color}
              />
            </button>
          </div>
        </form>
      ) : (
        <div className="py-10 text-xl text-center font-lato">
          Mr/Mme
          <span className="text-2xl font-bold text-green-600 ">
            {"   " + message.name + "    "}
          </span>
          {translate("submitMessage")}
        </div>
      )}
    </div>
  );
};

export default FormContact;
