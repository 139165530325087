import React from "react";
import translate from "../../internationalisation/translate";

const Describe = ({ description }) => {
  return (
    <div className="m-6 lg:m-8 xl:mx-16">
      <div className="py-5 mx-4">
        <h3 className="font-bold font-lato text-xl sm:text-2xl lg:text-3xl">
          {/* <span className="text-green-01">{translate("whatis")} </span> */}
          <span className="text-blue-01">{description.title}</span>
        </h3>
      </div>

      <div className="mx-4 flex flex-col md:flex-row items-center">
        <div className="md:w-4/6 grid justify-start">
          <p className="font-poppins indent-8 font-light text-justify sm:text-lg 2xl:text-xl">
            {description.text}
          </p>
        </div>

        <div className="md:w-2/6  px-10 flex items-center justify-center">
          <img
            src={description.image}
            alt=""
            className="min-w-[16rem] hover:animate-pulse"
          />
        </div>
      </div>
    </div>
  );
};

export default Describe;
