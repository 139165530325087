import React from "react";

const Section1 = ({ section }) => {
  return (
    <div className="mx-2 lg:p-5 grid justify-center">
      <div className="m-1">
        <div className="py-2 lg:py-4 font-lato font-semibold md:text-lg lg:text-xl 2xl:text-2xl break-02:text-3xl">
          {section.title}
        </div>

        <div className="py-2 2xl:py-4 font-poppins text-justify text-sm md:text-base lg:text-lg break-02:text-xl">
          {section.text}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-4 xl:m-5 2xl:my-8 items-center justify-center">
        {section.subSection.map((element, index) => {
          return (
            <div key={index + element} className="grid justify-center">
              <div className="bg-[#FFF] shadow-lg hover:shadow-xl hover:scale-105 hover:border border-gray-200 m-3 p-5 2xl:max-w-2xl transition ease-in-out duration-300">
                <div className="py-2 font-poppins font-semibold md:text-lg xl:text-xl break-02:text-2xl">
                  {element.name}
                </div>

                <div className="py-2 font-poppins text-justify font-light text-sm md:text-base lg:text-lg break-02:text-xl">
                  {element.describe}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex justify-center my-4 2xl:my-8">
        <img src={section.image} alt="" />
      </div>
    </div>
  );
};

export default Section1;
