import React, { useState, useEffect } from "react";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import BodyViewProduct from "../components/products/BodyViewProduct";

import bgHeader from "../img/products/header-products.png";
import product1 from "../img/products/products-1.png";
import cycle from "../img/products/cycle-production.png";
import { HiChevronDoubleUp } from "react-icons/hi";
import { Link } from "react-scroll";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../api";
import { NotificationManager } from 'react-notifications';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";


const ViewProduct = () => {
  const locale = useSelector((state) => state.languageLocale);
  const [prod, setProd] = useState(null);
  const { id } = useParams();
  useEffect(() => {

    axios.get(apiUrlVitrine + "/produit/?id=" + id).then((res) => { setProd(res.data[0]) }).catch((er) => { NotificationManager.error('Une Erreur est survenu lors de la mise en page !!!', 'Erreur !!!', 3000); })
  }, []);


  const describe = prod === null ? "" : locale === "en-us" ? prod.translations.en.description : prod.translations.fr.description;
  const header = {
    title: prod === null ? "" : locale === "en-us" ? prod.translations.en.name : prod.translations.fr.name,
    describe: describe,
    image: bgHeader,
  };

  const text =
    "2022, a French leader in the recruitment industry is looking for a new trusted partner. Priority #1: to be supported in the design and development of a mobile application dedicated to the medical sector. A first work that will open on the creation and marketing of the application dedicated to its users: its future multi-sector mobile platform. The latter aims to address all its targets, candidates and temporary professionals.";

  const product = {
    name: prod === null ? "" : locale === "en-us" ? prod.translations.en.name : prod.translations.fr.name,
    subjet: prod === null ? "" : locale === "en-us" ? prod.translations.en.sujet : prod.translations.fr.sujet,
    aboutProduct: prod === null ? "" : locale === "en-us" ? prod.translations.en.description : prod.translations.fr.description,
    imageAbout: prod === null ? "" : prod.imageAbout,
    imageVision: prod === null ? "" : prod.imageVision,
    imageValeur: prod === null ? "" : prod.imageValeur,
    imageCible: prod === null ? "" : prod.imageCible,
    valeurProduct: prod === null ? "" : locale === "en-us" ? prod.translations.en.valeurProduct : prod.translations.fr.valeurProduct,
    visionProduct: prod === null ? "" : locale === "en-us" ? prod.translations.en.visionProduct : prod.translations.fr.visionProduct,
    cibleProduct: prod === null ? "" : locale === "en-us" ? prod.translations.en.cibleProduct : prod.translations.fr.cibleProduct,

  };

  return (
    <div className="bg-white cursor-default relative">

      <div className="z-50 absolute">
        <Navbar />
      </div>

      <div id="home" className="h-14 "></div>

      <div className="flex justify-end">
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="fixed p-2 text-2xl bg-white border border-gray-200 rounded-full shadow-xl cursor-pointer text-green-01 shadow-gray-300 right-5 bottom-14"
        >
          <HiChevronDoubleUp />
        </Link>
      </div>

      <div>
        <Header header={header} />
      </div>

      <div>
        <BodyViewProduct product={product} />
      </div>

      <div className="mb-8 lg:mb-12">
        <ContactForm />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewProduct;
