import { useState, useContext } from 'react';
import "../../../styles/carreer.css";
import { FaFolderOpen } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
import { changeCar } from '../../../store';
import SendContext from '../../../context/SendContext';

const ResumeCv = () => {
    const dispatch=useDispatch();
    const [nombreDocument, setNombreDocument] = useState(1);
    const {handelChangCand}=useContext(SendContext)

    return (
        <div className='flex-col break-05:w-1/2 mx-auto mb-10 transition-all ease-in-out duration-500'>
            <h1 className='text-xl font-lato font-semibold'>Resume  / CV</h1>
            <p className=' font-poppins font-light w-3/4 my-5'>Please upload your resume/CV (Max size: 5MB)*
                Only .pdf, .doc, .docx, .odt, .txt formats are supported  </p>
            <div className=' flex-row flex'>
                <div className='  basis-full  lg:basis-1/2'>
                    <div class="overflow-hidden relative w-full  border border-solid border-gray-300  bg-white-01 mt-4 mb-4 rounded-md hover:border hover:border-blue-01 ">
                        <button className=" text-[#444] bg-  py-2 px-4 w-full inline-flex items-center">
                            <FaFolderOpen className='text-[33px] ml-3 text-blue-01' />
                            <span className="ml-10 text-lg ">My Computer </span>
                            <input className="cursor-pointer absolute block opacity-0 w-full -translate-x-3 " type="file" name="cv" id="cv_cand" />
                        </button>

                    </div>
                </div>


            </div>
        </div>
    )
}

export default ResumeCv; 