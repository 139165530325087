import React from "react";

const Maps = ({ maps, width, height }) => {
  return (
    <div className="">
      <iframe
        title="Maps OFTY"
        src={maps}
        width={width}
        height={height}
        style={{ border: "0" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      {/* 
      <iframe src={maps} width={width} height={height}></iframe> */}
    </div>
  );
};

export default Maps;
