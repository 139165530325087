import React from "react";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Describe from "../components/services/Describe";
import Domaines from "../components/services/Domaines";
import Process from "../components/services/Process";

import imageDescription from "../img/services/rd.png";
import bgHeader from "../img/services/rdBig.jpeg";
import bgDomaines from "../img/services/bg-domaines-02.png";

import process_1 from "../img/expertises/process-1.jpg";
import process_2 from "../img/expertises/process-2.jpg";
import Header from "../components/Header";
import { Link } from "react-scroll";
import { HiChevronDoubleUp } from "react-icons/hi";
import translate from "../internationalisation/translate";

const ResearchServices = () => {
  const text = (
    <div>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maxime, sint,
      repellat vel quo quisquam accusamus in qui at ipsa enim quibusdam illo
      laboriosam omnis. Labore itaque illum distinctio eum neque!
    </div>
  );
  const titleDescribe = translate("researchOfty");

  const describe = "";

  const header = {
    title: translate("research"),
    describe: describe,
    image: bgHeader,
  };

  const textDescribe = (
    <div>
      <p className="my-3 indent-8 fle
       justify-start">
        {translate("bodyRd")}
      </p>
      <p className="my-3 indent-8 fle
       justify-start">
        {translate("bodyRd2")}
      </p>

    </div>
  );

  const description = {
    title: titleDescribe,
    text: textDescribe,
    image: imageDescription,
  };

  const describe1 =
    "";

  const listDomaines = [
    { title: "Development Web", describe: describe1 },
    { title: "Development Mobile", describe: describe1 },
    { title: "Responsive Design", describe: describe1 },
    { title: "Development web", describe: describe1 },
  ];

  const domaines = {
    image: bgDomaines,
    listDomaines: listDomaines,
  };

  const processData = [
    { title: "Analyse De besoins", describe: text, image: process_1 },
    { title: "Choix de la solution", describe: text, image: process_2 },
    { title: "Prototypage de solution", describe: text, image: process_1 },
    { title: "Test", describe: text, image: process_1 },
    { title: "Validation & Activation", describe: text, image: process_2 },
    { title: "Livraison", describe: text, image: process_2 },
  ];

  return (
    <div className="bg-white cursor-default relative">
      <div className="z-50 absolute">
        <Navbar />
      </div>

      <div id="home" className="h-14 "></div>

      <div className="flex justify-end">
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="fixed p-2 text-2xl bg-white border border-gray-200 rounded-full shadow-xl cursor-pointer text-green-01 shadow-gray-300 right-5 bottom-14"
        >
          <HiChevronDoubleUp />
        </Link>
      </div>

      <div>
        <Header header={header} white={true} />
      </div>

      <div>
        <Describe description={description} />
      </div>

      {/* <div>
        <Domaines domaines={domaines} />
      </div> */}

      {/* <Process processData={processData} /> */}
      <div>
        <ContactForm />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ResearchServices;
