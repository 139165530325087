import { useState, useEffect } from "react";
import ContactLink from "../components/ContactLink";
import Footer from "../components/Footer";
import Header from "../components/Header2";
import Navbar from "../components/Navbar";
import BodyViewPublication from "../components/publication/BodyViewPublication";
import { useParams } from "react-router-dom";

import bgHeader from "../img/publications/header-publication.png";
import image from "../img/publications/article-image.png";
import { HiChevronDoubleUp } from "react-icons/hi";
import { Link } from "react-scroll";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import translate from "../internationalisation/translate";
import { apiUrlVitrine } from "../api";
import { Helmet } from "react-helmet";

const ViewPublication = () => {
  const describe =
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima possimus cupiditate dicta quia assumenda hic aperiam officiis iste explicabo voluptate, iusto nihil, omnis veritatis nostrum, alias mollitia fugiat deleniti quis.";
  const header = {
    title: "Blogs",
    describe: describe,
    image: bgHeader,
  };

  const article = {
    id: 5,
    title: "Challenges and Importance of Digitalization for Companies!",
    abstract:
      "We proactively consult, design, develop & scale robust web, mobile & custom software solutions, that fuel innovation & deliver digital success ...",
    image: image,
    author: "Autor of article",
    date: "Août 16, 2022",
    domaines: ["Digitalisation", "Agile development", "Web application"],
  };
  let navigate = useNavigate();
  const [data, setData] = useState(null)
  let { id } = useParams();
  useEffect(() => {

    axios.get(apiUrlVitrine + `/publication/?id=${id}`).then((res) => {
      if (res.data.translations) {
        navigate("/publication");
      }
      setData(res.data);
    }).catch((e) => {
      navigate("/publication");
      NotificationManager.error(
        translate("respErrorLong"),
        translate("respError"),

        5000
      );

    });

  }, []);

  return (
    <div className="bg-white cursor-default relative">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={data != null ? data[0].translations.en.abstract : ""} />
        <title>{data != null ? data[0].translations.en.title : ""} </title>
        <meta property="og:image" content={data != null ? data[0].image : ""} />
      </Helmet>
      <div className="z-50 absolute">
        <Navbar />
      </div>

      <div id="home" className="h-14 "></div>

      <div className="flex justify-end">
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="fixed p-2 text-2xl bg-white border border-gray-200 rounded-full shadow-xl cursor-pointer text-green-01 shadow-gray-300 right-5 bottom-14"
        >
          <HiChevronDoubleUp />
        </Link>
      </div>

      <div>

        {
          data != null ? <Header header={data} /> :
            <div className="text-xl font-bold text-center text-red-600"> IL YA AUCUN CONTENU DANS CETTE ATICLE </div>
        }

      </div>

      <div>{
        data != null ? <BodyViewPublication article={data} /> :
          <div className="text-xl font-bold text-center text-red-600"> IL YA AUCUN CONTENU DANS CETTE ATICLE </div>
      }

      </div>

      <div className="mb-8 lg:mb-12">
        <ContactLink />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewPublication;
