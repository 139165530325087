import React, { useEffect } from "react";
import { useState } from "react";
import ContactLink from "../components/ContactLink";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import BodyProduct from "../components/products/BodyProduct";

import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../api";

import bgHeader from "../img/products/header-products.png";
import { HiChevronDoubleUp } from "react-icons/hi";
import { Link } from "react-scroll";
import translate from "../internationalisation/translate";

const Products = () => {
  const describe = translate('headerDescribeExpertise')
  const header = {
    title: translate("ourProducts"),
    describe: describe,
    image: bgHeader,
  };

  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios.get(apiUrlVitrine + "/produit/").then((res) => {
      setProducts(res.data);
    });
  }, []);

  return (
    <div className="bg-white cursor-default relative">
      <div className="z-50 absolute">
        <Navbar />
      </div>

      <div id="home" className="h-14 "></div>

      <div className="flex justify-end">
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="fixed p-2 text-2xl bg-white border border-gray-200 rounded-full shadow-xl cursor-pointer text-green-01 shadow-gray-300 right-5 bottom-14"
        >
          <HiChevronDoubleUp />
        </Link>
      </div>

      <div>
        <Header header={header} white={true} />
      </div>

      <div>
        {products.length < 1 ? "" : <BodyProduct products={products} />}
      </div>

      <div className="mb-8 lg:mb-12">
        <ContactLink />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Products;
