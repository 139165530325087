import React from "react";
import translate from "../../internationalisation/translate";
// import { FaNewspaper } from "react-icons/fa";
// import Button from "./Button";
import NewAticle from "./NewAticle";

// import image from "../../img/publications/article1.png";
// import { useState } from "react";

const Publication = ({ articles }) => {
  // const publications = [
  //   {
  //     title: "New Web Design",
  //     date: "04 / 02 / 2022",
  //     abstract:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque diam non nisi semper, et elementum lorem ornare. Maecenas placerat facilisis mollis. Duis sagittis ligula in sodales vehicula.",
  //     link: "#!",
  //   },
  // ];

  // const articles = [
  //   {
  //     id: 1,
  //     title: "Challenges and Importance of Digitalization for Companies!",
  //     abstract:
  //       "We proactively consult, design, develop & scale robust web, mobile & custom software solutions, that fuel innovation & deliver digital success ...",
  //     image: image,
  //     author: "Autor of article",
  //     date: "Août 16, 2022",
  //     domaines: ["Digitalisation", "Agile development", "Web application"],
  //   },
  //   {
  //     id: 2,
  //     title: "Challenges and Importance of Digitalization for Companies!",
  //     abstract:
  //       "We proactively consult, design, develop & scale robust web, mobile & custom software solutions, that fuel innovation & deliver digital success ...",
  //     image: image,
  //     author: "Autor of article",
  //     date: "Août 16, 2022",
  //     domaines: ["Digitalisation", "Agile development", "Web application"],
  //   },
  //   {
  //     id: 3,
  //     title: "Challenges and Importance of Digitalization for Companies!",
  //     abstract:
  //       "We proactively consult, design, develop & scale robust web, mobile & custom software solutions, that fuel innovation & deliver digital success ...",
  //     image: image,
  //     author: "Autor of article",
  //     date: "Août 16, 2022",
  //     domaines: ["Digitalisation", "Agile development", "Web application"],
  //   },
  //   {
  //     id: 4,
  //     title: "Challenges and Importance of Digitalization for Companies!",
  //     abstract:
  //       "We proactively consult, design, develop & scale robust web, mobile & custom software solutions, that fuel innovation & deliver digital success ...",
  //     image: image,
  //     author: "Autor of article",
  //     date: "Août 16, 2022",
  //     domaines: ["Digitalisation", "Agile development", "Web application"],
  //   },
  //   {
  //     id: 5,
  //     title: "Challenges and Importance of Digitalization for Companies!",
  //     abstract:
  //       "We proactively consult, design, develop & scale robust web, mobile & custom software solutions, that fuel innovation & deliver digital success ...",
  //     image: image,
  //     author: "Autor of article",
  //     date: "Août 16, 2022",
  //     domaines: ["Digitalisation", "Agile development", "Web application"],
  //   },
  // ];

  return (
    <div>
      <div className="mx-6 lg:mx-10 xl:mx-16 mb-16">
        <h3 className="font-bold font-lato text-2xl lg:text-3xl 2xl:text-4xl my-4 ml-8">
          <span className="text-green-01">{translate('lastForm')} </span>
          <span className="text-blue-01">{translate('ourBlog')} </span>
        </h3>
      </div>

      <div className="grid md:grid-cols-2 xl:grid-cols-3 2xl:flex gap-5 md:gap-10 items-center justify-center m-4">
        {articles.slice(0, 3).map((article) => {
          return (
            <div key={article.id} className="mt-10 flex justify-center">
              <NewAticle article={article} />
            </div>
          );
        })}
      </div>

      {/* <div className="border-t-2 border-blue-01 text-sm sm:text-base lg:text-lg 2xl:text-2xl translate-y-2">
        <ol className="flex overflow-hidden scroll-smooth hover:overflow-x-scroll bg-white ml-4">
          {publications.map((publication, index) => (
            <li
              className="max-w-max lg:max-w-none lg:min-w-[450px] 2xl:min-w-[25%] mx-4 sm:mx-6"
              key={index + "publication"}
            >
              <div className="md:flex flex-col">
                <div className="text-white flex items-center justify-start w-full mb-3">
                  <div className="bg-blue-01 bg-opacity-80 p-2 flex items-center justify-center">
                    <FaNewspaper size={25} />
                  </div>
                </div>
                <div className="block p-6 bg-white shadow-lg shadow-gray-400 rounded max-w-md mb-10 scale-105">
                  <div className="flex flex-col sm:flex-row justify-between font-poppins text-base sm:text-lg font-semibold mb-4">
                    <a
                      href="#!"
                      className="text-blue-01 hover:text-green-01 focus:text-blue-01 duration-300 transition ease-in-out"
                    >
                      {publication.title}
                    </a>
                    <a href="#!" className="text-blue-01">
                      {publication.date}
                    </a>
                  </div>
                  <p className="text-gray-700 my-6 text-xs sm:text-sm xl:text-lg font-body">
                    {publication.abstract}
                  </p>
                  <div className="grid justify-start">
                    <Button
                      adress={publication.link}
                      name={"Learn More"}
                      color={"green"}
                    />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div> */}
    </div>
  );
};

export default Publication;
