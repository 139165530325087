import React, { useState } from "react";
import { HiChevronDoubleUp } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link } from "react-scroll";
import BodyCareer from "../components/career/BodyCareer";
// import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

import bgHeader from "../img/career/header-career.png";

const Career = () => {
  const [candidature, setCandidature]=useState(useSelector((state)=>state.candidature));
  const describe =
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima possimus cupiditate dicta quia assumenda hic aperiam officiis iste explicabo voluptate, iusto nihil, omnis veritatis nostrum, alias mollitia fugiat deleniti quis.";
  const header = {
    title: "Career",
    describe: describe,
    image: bgHeader,
  };

console.log(candidature); 
  return (
    <div className="bg-white cursor-default relative">
      <div className="z-50 absolute">
        <Navbar />
      </div>

      <div id="home" className="h-14 "></div>

      <div className="flex justify-end">
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="fixed p-2 text-2xl bg-white border border-gray-200 rounded-full shadow-xl cursor-pointer text-green-01 shadow-gray-300 right-5 bottom-14"
        >
          <HiChevronDoubleUp />
        </Link>
      </div>

      <div>
        <Header header={header} />
      </div>

      <div className="mt-8">
        <BodyCareer />
      </div>

      {/* <div>
        <ContactForm />
      </div> */}

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Career;
