import React from "react";
import { FaCircle } from "react-icons/fa";

const Button = ({ adress, name, color }) => {
  return (
    <div className="select-none">
      <div className="flex justify-around my-2 text-white font-medium text-sm lg:text-lg break-02:text-xl">
        <a
          className={`${
            color === "blue"
              ? "bg-blue-01 hover:bg-green-01"
              : "bg-green-01 hover:bg-blue-01"
          } relative w-max flex items-center px-3 py-2 leading-tight rounded transition duration-500 ease-in-out origin-right hover:bg-opacity-90 focus:outline-none focus:ring-0`}
          href={adress}
          role="button"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
        >
          <span className="">{name}</span>

          <span className="">
            <FaCircle className="ml-2 lg:ml-4" size={14} />
          </span>
        </a>
      </div>
    </div>
  );
};

export default Button;
