import { useState, useContext, useMemo } from "react";
import SendContext from "../../../context/SendContext";

import Select from "react-select";
import countryList from "react-select-country-list";

const CandidateInformation = () => {
  const options = useMemo(() => countryList().getData(), []);

  const { handelChangCand } = useContext(SendContext);
  return (
    <div className="flex-col px-3 mx-auto transition-all duration-500 ease-in-out break-05:w-1/2">
      <h1 className="py-10 text-xl font-semibold font-lato">
        Candidate information
      </h1>
      <h1 className="py-3 text-lg font-semibold font-lato">
        Personal informations
      </h1>
      <div className="flex flex-col md:flex-row">
        <div className="my-2 form-floating basis-full lg:basis-1/3 md:mr-2 lg:mr-3">
          <input
            type="text"
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white-01 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none"
            id="floatingFirstName"
            placeholder="name@example.com"
            name="first_name"
            onChange={handelChangCand}
          />
          <label for="floatingFirstName" className="text-gray-700 font-poppins">
            First Name <span className="text-red-500">*</span>
          </label>
        </div>
        <div className="my-2 form-floating basis-full lg:basis-1/3 md:mr-2 lg:mr-3">
          <input
            type="text"
            className="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none"
            id="floatingFirstName"
            placeholder="name@example.com"
            name="second_name"
            onChange={handelChangCand}
          />
          <label for="floatingFirstName" className="text-gray-700 font-poppins">
            Second Name{" "}
          </label>
        </div>
        <div className="my-2 form-floating basis-full lg:basis-1/3 ">
          <input
            type="text"
            className="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none"
            id="floatingFirstName"
            placeholder="name@example.com"
            name="last_name"
            onChange={handelChangCand}
          />
          <label for="floatingFirstName" className="text-gray-700 font-poppins">
            Last Name <span className="text-red-500">*</span>
          </label>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="my-2 form-floating basis-full lg:basis-2/3 md:mr-3 lg:mr-8">
          <input
            type="date"
            className="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none"
            id="floatingFirstName"
            placeholder="name@example.com"
            name="birthday"
            onChange={handelChangCand}
          />
          <label for="floatingFirstName" className="text-gray-700 font-poppins">
            Date of birth <span className="text-red-500">*</span>
          </label>
        </div>
        <div className="my-2 form-floating basis-full lg:basis-1/3">
          <input
            type="text"
            className="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none"
            id="floatingFirstName"
            placeholder="name@example.com"
            name="address"
            onChange={handelChangCand}
          />
          <label for="floatingFirstName" className="text-gray-700 font-poppins">
            Place of birth <span className="text-red-500">*</span>{" "}
          </label>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="my-2 form-floating basis-full lg:basis-2/6 md:mr-3 lg:mr-8">
          <input
            type="text"
            className="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none"
            id="floatingFirstName"
            placeholder="name@example.com"
            name="tel"
            onChange={handelChangCand}
          />
          <label for="floatingFirstName" className="text-gray-700 font-poppins">
            Phone <span className="text-red-500">*</span>{" "}
          </label>
        </div>
        <div className="my-2 form-floating basis-full lg:basis-3/6">
          <input
            type="email"
            className="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none"
            id="floatingFirstName"
            placeholder="name@example.com"
            name="email"
            onChange={handelChangCand}
          />
          <label for="floatingFirstName" className="text-gray-700 font-poppins">
            Email <span className="text-red-500">*</span>{" "}
          </label>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="my-2 form-floating basis-full lg:basis-1/3 md:mr-2 lg:mr-3">
          <input
            type="text"
            className="form-control
block
w-full
px-3
py-1.5
text-base
font-normal
text-gray-700
bg-white-01 bg-clip-padding
border border-solid border-gray-300
rounded
transition
ease-in-out
m-0
focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none"
            id="floatingFirstName"
            placeholder="name@example.com"
            name="address"
            onChange={handelChangCand}
          />
          <label for="floatingFirstName" className="text-gray-700 font-poppins">
            Address<span className="text-red-500">*</span>
          </label>
        </div>
        <div className="my-2 form-floating basis-full lg:basis-1/3 md:mr-2 lg:mr-3">
          <Select
            className="form-select form-select-lg mb-3
      appearance-none
      h-[51px]
      block
      w-full
      px-4
      py-2
      text-xl
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-01 focus:outline-none"
            aria-label=".form-select-lg example"
            name="country"
            onChange={handelChangCand}
            options={options}
          />
        </div>
        <div className="my-2 form-floating basis-full lg:basis-1/3 ">
          <select
            class="form-select form-select-lg mb-3
      appearance-none
      h-[51px]
      block
      w-full
      px-4
      py-2
      text-xl
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-01 focus:outline-none"
            aria-label=".form-select-lg example"
            name="province"
            onChange={handelChangCand}
          >
            <option selected>
              {" "}
              Province / State <span className="text-red-500">*</span>
            </option>
            <option value="1">+33</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
      </div>
      <div className="flex flex-col md:flex-row"></div>
    </div>
  );
};

export default CandidateInformation;
