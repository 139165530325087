import { useEffect, useState } from "react";
import ContactForm from "../components/ContactForm";
import BodyExpertises from "../components/expertises/BodyExpertises";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import image1 from "../img/expertises/data-security.png";
import image2 from "../img/expertises/cloud.png";
import image3 from "../img/expertises/data-science.png";
import image4 from "../img/expertises/information-system.png";

import bgHeader from "../img/expertises/header-expertises.jpg";
import Header from "../components/Header";

import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../api";

import { Link } from "react-scroll";
import { HiChevronDoubleUp } from "react-icons/hi";
import translate from "../internationalisation/translate";

const Expertise = () => {
  const [expertises, setExpertises] = useState([]);
  const describe = translate('headerDescribeExpertise')

  const header = {
    title: translate('ourExpertises'),
    describe: describe,
    image: bgHeader,
  };


  useEffect(() => {
    axios.get(apiUrlVitrine + "/expertise/").then((res) => {
      setExpertises(res.data);
    });
  }, []);
  return (
    <div className="relative bg-white cursor-default">
      <div className="absolute z-50">
        <Navbar />
      </div>

      <div id="home" className="h-14 "></div>

      <div className="flex justify-end">
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="fixed p-2 text-2xl bg-white border border-gray-200 rounded-full shadow-xl cursor-pointer text-green-01 shadow-gray-300 right-5 bottom-14"
        >
          <HiChevronDoubleUp />
        </Link>
      </div>

      <div>
        <Header header={header} white={true} />
      </div>
      <div>
        <BodyExpertises expertises={expertises} />
      </div>
      <div>
        <ContactForm />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Expertise;
