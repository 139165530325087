import React from "react";

const MentionLegaleAndaal = () => {
  return (
    <div className="w-full bg-[#ffffff]">
      <div className="pb-20 pt-40 mx-10 flex flex-col justify-start md:px-20 lg:px-60  ">
        <h1 className="text-xl md:text-3xl font-lato font-semibold text-blueT tracking-normal ">
          Mentions légales{" "}
        </h1>
        <div className="font-lato font md:text-lg  my-5 text-sm">
          <span className="font-bold"> ANDAAL</span> est une application de la
          société OFTY SAS.
        </div>
        <h4 className="text-md md:text-xl font-semibold font-lato mt-2">
          Éditeur
        </h4>
        <div className="my-2 text-sm md:text-lg font-lato">
          OFTY SAS <br />
          6 Rue des Tilleuls <br />
          95870 Bezons France <br />
          Mail : contact@ofty.fr
        </div>
        <h4 className="text-md font-semibold font-lato mt-2">Hébergeur</h4>

        <h4 className="text-md  md:text-2xl font-semibold font-lato  my-5">
          Localisation Hébergement des serveurs
        </h4>
        <div className="font-lato font md:text-lg  text-sm">
          Les serveurs sont hébergés dans l’Union Européenne Pour consulter la
          politique d’OFTY sur la protection des données, vous pouvez vous
          rendre sur la page Données personnelles{" "}
          <a
            className="font-semibold text-md text-blue-500"
            href="/donneespersonnellesandaal"
          >
            ici
          </a>
        </div>
        {/* <h4 className="text-md font-semibold font-lato md:text-xl mt-2">
          Archivage à long terme
        </h4>
        <div className=" font-lato font-normal md:text-lg text-sm">
          Pour consulter la politique de la Plateforme The Pépin sur la
          protection des données, vous pouvez vous rendre sur la page Données
          personnelles{" "}
        </div> */}
        <h4 className="text-md md:text-2xl font-semibold font-lato  my-5">
          Liens vers d’autres sites
        </h4>
        <div className=" font-lato font-normal text-sm md:text-lg">
          Le site peut contenir des liens hypertextes vers des sites existants
          ou gérés par des tiers. The Pépin ou OFTY ne peuvent exercer aucun
          contrôle permanent sur ces sites, ni assumer aucune responsabilité
          quant à leurs conditions d'accès, leurs contenus, les publicités et
          services proposés ou l’usage qui peut en être fait.
        </div>
        <h4 className="text-md md:text-2xl font-semibold font-lato  my-5">
          Clause de non-responsabilité
        </h4>
        <div className=" font-lato font-normal text-sm md:text-lg">
          La responsabilité de l’éditeur ne peut être engagée à raison
          d’éventuelles erreurs ou omissions dans le contenu du site.
        </div>
        <h4 className="text-md md:text-2xl font-semibold font-lato my-5">
          Signalement de contenus illicites
        </h4>
        <div className=" font-lato font-normal text-sm md:text-lg">
          Si vous estimez qu’un contenu est illicite au sens de{" "}
          <span className="text-blue-500 font-semibold ">
            l’art. 6-I de la loi n° 2004-575 du 21 juin 2004 pour la confiance
            dans l'économie numérique
          </span>
          , vous pouvez le porter à la connaissance de l’hébergeur via l'adresse
          électronique du support{" "}
          <span className="text-blueT font-semibold">contact@ofty.fr</span>
          <br />
          ou
          <div className="my-2 text-sm md:text-lg font-lato">
            OFTY SAS
            <br />
            6 Rue des Tilleuls
            <br />
            95870 Bezons <br />
            France
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentionLegaleAndaal;
