import React from "react";
import { useState, useContext } from "react";
import { FaFolderOpen, FaPlus } from "react-icons/fa";
import SendContext from "../../../context/SendContext";
import Button from "../../services/ButtonServices";

const AcademicInformation = () => {
const {handelChangCand}=useContext(SendContext);
  return (
    <div className='flex-col px-3 mx-auto transition-all duration-500 ease-in-out break-05:w-1/2 '>
      <h1 className='py-10 text-xl font-semibold font-lato'>Professional  information</h1>

      <div className='flex flex-col md:flex-row'>

        <div className="my-2 form-floating basis-full lg:basis-1/2 xl:basis-1/3 md:mr-2 lg:mr-3">
          <h1 className='p-1 text-xs'>
            Are you currently employed?<span className='mx-1 text-red-500'>*</span>
          </h1>
          <select class="form-select form-select-lg mb-3
      appearance-none
      h-[51px]
      block
      w-full
      px-4
      py-2
      text-xl
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-01 focus:outline-none"
            aria-label=".form-select-lg example"
            name="current_employed"
            onChange={handelChangCand}

          >
            <option selected>
              {" "}
              Make selection <span className="text-red-500">*</span>
            </option>
            <option value="True">Yes</option>
            <option value="False">No</option>
          </select>
        </div>


        <div className="my-2  basis-full lg:basis-1/2 xl:basis-2/3 md:mr-2 lg:mr-3">
          <h1 className="p-1 text-xs">
            In which company are you employed?
            <span className="mx-1 text-red-500">*</span>
          </h1>
          <div className="form-floating">
            <input
              type="text"
              className="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none"
              id="floatingFirstName"
              placeholder="name@example.com"
              name="current_employer"
            onChange={handelChangCand}
            />
            <label
              for="floatingFirstName"
              className="text-gray-700 font-poppins"
            >
              Ex: Informatics and Telecomminications{" "}
            </label>
          </div>
        </div>
      </div>
      <div className="flex flex-col  md:flex-row">
        <div className="my-2 form-floating basis-full lg:basis-1/2 md:mr-2 lg:mr-3">
          <h1 className="p-1 text-xs">
            What working arrangements are you currently talking about? *
            <span className="mx-1 text-red-500">*</span>
          </h1>
          <select
            class="form-select form-select-lg mb-3
appearance-none
h-[51px]
block
w-full
px-4
py-2
text-xl
font-normal
text-gray-700
bg-white-01 bg-clip-padding bg-no-repeat
border border-solid border-gray-300
rounded
transition
ease-in-out
m-0
focus:text-gray-700 focus:bg-white focus:border-blue-01 focus:outline-none"
            aria-label=".form-select-lg example"
            name="how_work"
            onChange={handelChangCand}
            
          >
            <option selected>
              {" "}
              Make selection <span className="text-red-500">*</span>
            </option>
            <option value="Remote">Remote</option>
            <option value="Expatriation">Expatriation</option>
          </select>
        </div>
      </div>
      <div className="flex flex-col  md:flex-row">
        <div className="my-2 form-floating basis-full lg:basis-1/2 md:mr-2 lg:mr-3">
          <input
            type="text"
            className="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none"
            id="floatingFirstName"
            placeholder="name@example.com"
            name="qualities"
            onChange={handelChangCand}
            
          />
          <label for="floatingFirstName" className="text-gray-700 font-poppins">
            Give two of your qualities <span className="text-red-500">*</span>
          </label>
        </div>

        <div className="my-2 form-floating basis-full lg:basis-1/2 md:mr-2 lg:mr-3">
          <input
            type="text"
            className="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white-01 bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none"
            id="floatingFirstName"
            placeholder="name@example.com"
            name="faults"
            onChange={handelChangCand}
          />
          <label for="floatingFirstName" className="text-gray-700 font-poppins">
            List two of your faults <span className="text-red-500">*</span>
          </label>
        </div>
      </div>

      <div className="flex flex-col  md:flex-row">

        <div className="my-2 form-floating basis-full md:mr-2 lg:mr-3">
          <h1 className="p-1 text-sm">
            Years of experience <span className="mx-1 text-red-500">*</span>
          </h1>
          <select
            class="form-select form-select-lg mb-3
appearance-none
h-[51px]
block
w-full
px-4
py-2
text-xl
font-normal
text-gray-700
bg-white-01 bg-clip-padding bg-no-repeat
border border-solid border-gray-300
rounded
transition
ease-in-out
m-0
focus:text-gray-700 focus:bg-white focus:border-blue-01 focus:outline-none"
            aria-label=".form-select-lg example"
            name="years_experience"
            onChange={handelChangCand}
          >
            <option selected>
              {" "}
              Make selection <span className="text-red-500">*</span>
            </option>
            <option value="1">0 - 3 Years</option>
            <option value="2">3 - 5 Years</option>
            <option value="3">5 - 10 Years</option>
            <option value="4">More 10 Years</option>
          </select>
        </div>
      </div>
      <h1 className="my-2">Tell us more about you</h1>
      <div className="flex flex-col  md:flex-row">
        <textarea
          placeholder="write ...."
          name="tell_us"
          onChange={handelChangCand}
          id="tell_us"
          className="w-full h-32 px-3 py-4 focus:text-gray-700 focus:bg-white-01 focus:border-blue-01 focus:outline-none "
        ></textarea>
      </div>
    </div>
  );
};

export default AcademicInformation;
