import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeCarreer } from "../../store";
import ContactLink from "../ContactLink";
import PageCareer from "./PageCareer";
import PageRequest from "./PageRequest";
import PageSearch from "./PageSearch";

const BodyCareer = () => {
  const button =
    "cursor-pointer z-10 px-3 py-2 m-2 flex justify-center hover:text-green-01";
  const active =
    "text-green-01 bg-white-01 rounded-md shadow-md  transition-all ease-in-out duration-500";

  const etatMenu = useSelector((state) => state.carreerNavigate);
  const dispach = useDispatch();
  const [poste, setPoste] = useState(-1);

  // let pass = (index) => {
  //   let tampon = [...etatMenu];
  //   let i = tampon.findIndex((a) => a.etat === true);

  //   tampon[i].etat = false;
  //   tampon[index].etat = true;
  //   setEtatatMenu(tampon);
  // };
  let apply = (id) => {
    setPoste(id);
  }

  return (
    <div className="lg:py-8">
      <div className="justify-center hidden text-sm md:grid font-lato lg:text-base 2xl:text-lg">
        <div className="relative flex items-center h-12 transition duration-500 ease-in-out rounded-md navigate_carreer place-items-center">
          <div
            className={`w-48 z-0 h-9 absolute transition-all ease-out duration-500 shadow-md ${etatMenu[1].etat
              ? ""
              : etatMenu[2].etat
                ? "translate-x-[14rem] "
               : ""
              } rounded-md mx-4 bg-[#ffffff]`}
          ></div>
          {/* <h1
            onClick={() => dispach(changeCarreer(0))}
            className={`z-10 w-48 mx-4 cursor-pointer flex justify-center ${etatMenu[0].etat ? "text-green-500" : "text-black"
              } `}
          >
            CAREER WITH US
          </h1> */}
          <h1
            onClick={() => dispach(changeCarreer(1))}
            className={`z-10 w-48 mx-4 cursor-pointer flex justify-center ${etatMenu[1].etat ? "text-green-500" : "text-black"
              }`}
          >
            JOB SEARCH
          </h1>
          <h1
            onClick={() => dispach(changeCarreer(2))}
            className={`z-10 w-48 mx-4 cursor-pointer flex justify-center ${etatMenu[2].etat ? "text-green-500" : "text-black"
              }`}
          >
            REQUEST A JOB
          </h1>
        </div>
      </div>

      <div className="grid justify-center text-base transition-all duration-500 ease-in-out font-lato md:hidden">
        <div className="grid px-2 rounded-md gap-x-5 navigate_carreer">
          {/* <h1
            className={`${etatMenu[0].etat === true ? `${active}` : ""
              } ${button}`}
            onClick={() => dispach(changeCarreer(0))}
          >
            Career with us
          </h1> */}
          <h1
            className={`${etatMenu[1].etat === true ? `${active}` : ""
              } ${button}`}
            onClick={() => dispach(changeCarreer(1))}
          >
            Job Search
          </h1>
          <div className="flex justify-center col-span-2 -mt-3">
            <h1
              className={`${etatMenu[2].etat === true ? `${active}` : ""
                } ${button}`}
              onClick={() => dispach(changeCarreer(2))}
            >
              Request a job
            </h1>
          </div>
        </div>
      </div>

      <br />

      <div className="flex flex-col justify-center transition-all duration-500 ease-in-out">
        {/* <div
          id="Career"
          className={`${etatMenu[0].etat === true ? "block" : "hidden"}`}
        >
          <PageCareer />
        </div>

        <div
          id="Search"
          className={`${etatMenu[1].etat === true ? "block" : "hidden"}`}
        >
          <PageSearch />
        </div>

        <div
          id="Request"
          className={`${etatMenu[2].etat === true ? "block" : "hidden"}`}
        >
          <PageRequest />
        </div> */}

        {etatMenu[2].etat === true ? (
          <div className="px-8">
            <PageRequest post={poste} />
          </div>
        ) : etatMenu[1].etat === true ? (
          <div>
            <div className="px-2">
              <PageSearch apply={apply} />
            </div>
            <div className="mt-20">
              <ContactLink />
            </div>
          </div>
        ) : etatMenu[0].etat === true ? (
          <PageCareer />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default BodyCareer;
