import React from "react";

const Header = ({ header, white, visible }) => {
  const bgHeader = {
    backgroundImage: `url(${header.image})`,
  };
  console.log(white);
  return (
    <div className="my-3">
      {/* Texte de desciption */}
      <div
        className="h-[28rem] md:h-[35rem] break-04:h-[45rem] break-02:h-[50rem] w-full grid grid-cols-1 bg-local bg-cover bg-no-repeat bg-center"
        style={bgHeader}
      >
        {visible === false ? (
          ""
        ) : (
          <div className="flex flex-col items-center h-[85%] justify-end">
            <div
              className={`font-lato text-5xl md:text-7xl 2xl:text-8xl text-center font-bold uppercase text-shadow ${
                white ? "text-white" : "text-black"
              }  shadow-sm`}
            >
              {header.title}
            </div>
            {/* <div className="max-w-2xl mx-4 pt-8 text-center font-poppins text-sm md:text-base 2xl:text-lg text-gray-01">
            {header.describe}
          </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
