import React from "react";
import Button from "./Button";
import image from "../../img/inside_1.png";
import { useSelector } from "react-redux";

const CardAchiev = ({ achievement }) => {
  const locale = useSelector((state) => state.languageLocale);

  const linkView = {
    url: achievement.link,
    text: "View Achievements",
    color: "green",
  };

  const linkContact = { url: "/contact", text: "Contact us", color: "blue" };

  return (
    <div>
      <div className="flex justify-center items-center h-42">
        <div className="w-full flex flex-col lg:flex-row justify-center items-center">
          <div className="w-11/12 mt-4 mb-5 lg:w-5/12 break-04:w-4/12 2xl:min-w-[40rem] flex items-center justify-end lg:translate-x-16">
            <div className="flex justify-center items-center w-full">
              <img
                src={achievement.image}
                className="rounded shadow-2xl"
                alt=""
              />
            </div>
          </div>

          <div className="lg:pl-20 lg:py-10 lg:w-6/12 break-02:w-5/12 2xl:min-w-[50rem] break-04:min-w-[55rem] mx-4 bg-white bg-opacity-50 rounded shadow-2xl">
            <div className="lg:h-[22rem] xl:h-[25rem] 2xl:h-[30rem] break-02:h-[35rem]">
              <div className="h-1/5 flex flex-col justify-end">
                <div className="p-4 text-lg sm:text-xl break-04:text-2xl font-semibold font-lato grid justify-center lg:justify-start items-center">
                  <h1>
                    {" "}
                    {locale === "en-us"
                      ? achievement.translations.en.name
                      : achievement.translations.fr.name}
                  </h1>
                  <span className="border-2 rounded-full w-14 bg-green-01 border-green-01"></span>
                </div>
              </div>

              <div className="h-1/5 flex flex-col justify-end">
                <div className="p-2 max-w-md sm:text-xl lg:text-2xl break-04:text-3xl font-poppins font-medium text-center lg:text-left lg:flex lg:justify-start items-center">
                  <h2>
                    {locale === "en-us"
                      ? achievement.translations.en.sujet
                      : achievement.translations.fr.sujet}
                  </h2>
                </div>
              </div>

              <div className="h-2/5 min-w-xl mr-4 hidden text-gray-700 lg:flex items-center font-poppins text-justify 2xl:text-lg break-02:text-xl p-2">
                <div>
                  {locale === "en-us"
                    ? achievement.translations.en.abstract
                    : achievement.translations.fr.abstract}
                </div>
              </div>

              <div className="flex justify-star items-end h-1/5 ">
                <div className="mx-8 hidden lg:block">
                  <Button
                    adress={linkContact.url}
                    name={linkContact.text}
                    color={linkContact.color}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAchiev;

/* <div className="max-w-7xl">
        <div className="flex flex-wrap">
          <div className=" w-5/12 mb-12 lg:mb-0">
            <div className="flex justify-end w-full h-full lg:py-12 sm:ml-14">
              <img
                src={image}
                className="w-full max-w-md rounded-lg shadow-lg z-20 "
                alt=""
              />
            </div>
          </div>

          <div className="w-full z-auto lg:w-7/12 break-04:w-1/2">
            <div className="bg-white bg-opacity-50 h-full rounded-lg p-6 mr-14 lg:pl-9 text-black flex flex-col justify-center text-center lg:text-left">
              <div className="flex my-10">
                <div className="lg:pl-12">
                  <h2 className="text-3xl font-bold font-poppins mb-6">
                    Let it surprise you
                  </h2>
                  <p className="my-6 py-3 break-04:py-9 font-body hidden lg:flex items-end">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Maxime, sint, repellat vel quo quisquam accusamus in qui at
                    ipsa enim quibusdam illo laboriosam omnis. Labore itaque
                    illum distinctio eum neque!
                  </p>
                </div>
              </div>
              <div className="flex justify-around items-end">
                <Button adress={"#!"} name={"Learn More"} color={"blue"} />
                <Button adress={"#!"} name={"Learn More"} color={"blue"} />
              </div>
            </div>
          </div>
        </div>
      </div> */
