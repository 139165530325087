import React from "react";
import Button from "../services/ButtonServices";
import { useEffect } from "react";
import { useState } from "react";

import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../../api";
import { FaBookmark } from "react-icons/fa";
import { useSelector } from "react-redux";

const Product = ({ product, index }) => {
  const [domaines, setDomaines] = useState([]);
  const locale =useSelector((state)=>state.languageLocale);


  useEffect(() => {
    let tab = [];
    let d = [];
    for (let ind of product.domaine) {
      tab.push(
        axios.get(apiUrlVitrine + "/domaine/?id=" + ind).then((res) => {
          d.push(res.data[0]);
        })
      );
    }
    Promise.all(tab).then(() => setDomaines(d));
  }, [product.domaine]);

  return (
    <div>
      <div className="grid items-center justify-center grid-cols-1 gap-2 p-4 my-8 transition border rounded shadow-xl lg:my-14 lg:p-8 lg:grid-cols-2 hover:bg-slate-100 hover:shadow-2xl border-slate-200">
        <div className={index % 2 === 0 ? "" : "lg:order-last"}>
          <div className="py-4 text-3xl font-medium font-poppins md:text-4xl lg:text-5xl">
            <h2 className="text-blue-01">{ locale ==="en-us"? product.translations.en.name:product.translations.fr.name}</h2>
          </div>

          <div className="flex">
            {domaines.map((mark) => {
              return (
                <div
                  key={mark.id}
                  className="flex items-center p-1 mr-2 text-blue-01"
                >
                  <FaBookmark />
                  <div className="ml-1 text-xs font-light text-black xl:text-sm font-poppins">
                    { locale ==="en-us"? mark.translations.en.name:mark.translations.fr.name}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="py-4 text-lg font-medium font-poppins md:text-xl lg:text-2xl">
            <h4 className="">{ locale ==="en-us"? product.translations.en.sujet:product.translations.fr.sujet}</h4>
          </div>

          <div className="max-w-4xl font-light text-justify font-poppins md:text-lg break-02:text-xl">
            <div className="py-3">{ locale ==="en-us"? product.translations.en.description:product.translations.fr.description}</div>
          </div>

          <div className="flex justify-start">
            <Button
              adress={"/products/"+product.id}
              name={"See case report "}
              color={"green"}
            />
          </div>
        </div>
        <div
          className={
            index % 2 === 0
              ? "sm:ml-10 flex justify-end"
              : "sm:mr-10 flex justify-start"
          }
        >
          <img
            src={product.image}
            alt=""
            className="w-full rounded 2xl:max-w-2xl break-02:max-w-3xl"
          />
        </div>
      </div>
    </div>
  );
};

export default Product;
