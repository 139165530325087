import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import { FaHourglassEnd } from "react-icons/fa";
import { FaAngleDoubleRight, FaCalendarAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { changeCarreer, changePoste } from "../../store";

const Job = ({ job, state, id, openClose, apply }) => {
  //   const openClose = () => {
  //     let tampon = state;

  //     return setState(!tampon);
  //   };
  const dispach = useDispatch();
  return (
    <div className="grid py-4 max-w-[100rem]  break-02:max-w-[120rem]">
      <div
        onClick={() => openClose(id)}
        className={`${state === true
          ? `shadow-2xl bg-[#FFF]`
          : "bg-white shadow-lg hover:bg-[#FAFAFA]"
          } cursor-pointer p-4 2xl:px-8 rounded w-full border outline-none transition ease-in-out duration-500`}
      >
        <div className="flex items-center justify-between w-full ">
          <div className="w-full">
            <div className="col-span-4 py-2 sm:py-4">
              <span className="text-xl font-semibold lg:text-2xl 2xl:text-3xl break-02:text-4xl font-lato">
                {job.title}
              </span>
            </div>

            <div className="grid grid-cols-1 my-2 sm:grid-cols-2" title="Postuler " onClick={() => {
              dispach(changeCarreer(2));
              apply(job.id);

            }} >
              <div className="my-3">
                <div className="flex items-center gap-2 py-2 mt-4 text-lg font-semibold uppercase transition-all duration-500 ease-in-out hover:scale-105 lg:text-xl 2xl:text-2xl break-02:text-3xl font-lato text-green-01">
                  <span className="text-green-01">
                    <FaAngleDoubleRight />
                  </span>
                  <span>Apply Now</span>
                </div>
              </div>

              <div className="grid gap-3 text-sm font-lato lg:gtext-base 2xl:text-lg break-02:text-xl">
                <div className="flex items-center gap-2">
                  <span className="text-green-01">
                    <MdLocationPin />
                  </span>
                  <span>Location: {job.localisation}</span>
                </div>

                <div className="flex items-center gap-2">
                  <span className="text-green-01">
                    <FaCalendarAlt />
                  </span>
                  <span>Date of publication: {job.date_publication}</span>
                </div>

                <div className="flex items-center gap-2">
                  <span className="text-green-01">
                    <FaHourglassEnd />
                  </span>
                  <span>Deadline: {job.deadline}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-around gap-2">
            <div className="hidden max-h-72 break-06:flex">
              <img
                src={job.image}
                alt=""
                className="max-w-xs h-auto xl:max-w-none xl:w-[25rem] 2xl:w-[30rem]"
              />
            </div>
            <div className="flex justify-center w-8">
              <div
                className={`${state === true ? `rotate-180` : ""
                  } transition ease-in-out duration-500 text-5xl 2xl:text-6xl text-green-01`}
              >
                <IoIosArrowDown />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${state === true ? "py-4 my-3 mx-1 h-max" : "max-h-0"
            } overflow-hidden xl:text-lg text-justify 2xl:text-xl font-poppins font-light transition-all duration-500 ease-out col-span-3 lg:col-span-2`}
        >
          {job.description}
        </div>
      </div>
    </div>
  );
};

export default Job;
