import { useState, useEffect } from "react";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import BodyServices from "../components/services/BodyServices";

import image1 from "../img/services/consulting.png";
import image2 from "../img/services/research.png";

import bgHeader from "../img/services/Services.jpg";
import Header from "../components/Header";
import { HiChevronDoubleUp } from "react-icons/hi";
import { Link } from "react-scroll";
import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../api";
import translate from "../internationalisation/translate";

const Services = () => {
  const [services, setServices] = useState([]);
  const text = (
    <div>
      {translate('headerDescribeExpertise')}
    </div>
  );

  // const services = [
  //   {
  //     title: "Consulting",
  //     subjet: "Keep your digital tools up to date",
  //     text: text,
  //     image: image1,
  //     link: {
  //       url: "/services/consulting",
  //       text: "Learn More",
  //     },
  //   },
  //   {
  //     title: "Research & Development",
  //     subjet: "Discover our latest technological advances",
  //     text: text,
  //     image: image2,
  //     link: {
  //       url: "/services/research-and-developpement",
  //       text: "Learn More",
  //     },
  //   },
  // ];

  const describe = translate('headerDescribeExpertise');
  const header = { title: translate('ourServices'), describe: describe, image: bgHeader };

  useEffect(() => {
    axios.get(apiUrlVitrine + "/service/").then((res) => {
      setServices(res.data);
    });
  }, []);

  return (
    <div className="relative bg-white cursor-default">
      <div className="absolute z-50">
        <Navbar />
      </div>

      <div id="home" className="h-14 "></div>

      <div className="flex justify-end">
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="fixed p-2 text-2xl bg-white border border-gray-200 rounded-full shadow-xl cursor-pointer text-green-01 shadow-gray-300 right-5 bottom-14"
        >
          <HiChevronDoubleUp />
        </Link>
      </div>

      <div>
        <Header header={header} white={true} />
      </div>
      <div>
        <BodyServices services={services} />
      </div>
      <div>
        <ContactForm />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Services;
