import axios from "axios";
import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { apiUrlVitrine } from "../../api";
import SendContext from "../../context/SendContext";
import Button from "../services/ButtonServices";
import AcademicInformation from "./pageRequest/AcademicInformation";
import AdditionalDocument from "./pageRequest/AdditionalDocument";
import CandidateInformation from "./pageRequest/CandidateInformation";
import ProfessionalInformation from "./pageRequest/ProfessionalInformation";
import ResumeCv from "./pageRequest/ResumeCv";


const PageRequest = ({ post }) => {
  const [poste, setPoste] = useState(post);
  const {send}=useContext(SendContext);
  useEffect(() => {
    if (post !== -1) {
      axios.get(apiUrlVitrine + "/offreemploie/?id=" + post).then((res) => {
        setPoste(res.data[0]);
      })
    }
  }, []);

  return (
    <div className="flex-col w-full px-1 pt-10 transition-all duration-500 ease-in-out lg:px-5">
      <div className="py-10 text-3xl font-bold text-center font-lato text-green-01">
        {
          post !== -1 ? poste.title : ""

        }

      </div>
      <ResumeCv />
      {/* <div className="w-full  bg-[#EBEBEB] py-5">
        <AdditionalDocument />
      </div> */}
      <CandidateInformation />
      {/* <div className="w-full  bg-[#EBEBEB] py-5">
        <ProfessionalInformation />
      </div> */}
      <div className="w-full py-5" >
        <AcademicInformation />
        <div onClick={send}>
           <Button  adress="#" name="SUBMIT PROFILE" color="green"   />
        </div>
       
      </div>

    </div>
  );
};

export default PageRequest;
