import React from "react";

import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

const Element = ({ feedback }) => {
  return (
    <div className="w-11/12 md:w-2/3 2xl:w-3/4 xl:h-[27rem] 2xl:h-[29rem] break-02:h-[30rem] bg-white bg-opacity-60 rounded p-4 2xl:p-8 text-center">
      <div className="flex justify-center py-2">
        <div className="w-[8rem] h-[8rem] lg:w-[11rem] lg:h-[11rem] rounded-full overflow-hidden">
          <img src={feedback.image} alt="..." className="w-full h-full " />
        </div>
      </div>

      <div className="font-lato font-semibold text-xl 2xl:text-2xl break-02:text-3xl p-2 md:p-3">
        {feedback.name}
      </div>

      <div className="font-poppins font-medium text-lg 2xl:text-xl break-02:text-2xl p-3">
        {feedback.entreprise}
      </div>

      <div className="font-poppins font-light 2xl:text-lg break-02:text-xl p-3 flex">
        <span className="text-gray-700">
          <ImQuotesLeft />
        </span>
        <span className="mx-2">{feedback.body}</span>
        <span className="flex items-end -translate-y-3 text-gray-700">
          <ImQuotesRight />
        </span>
      </div>
    </div>
  );
};

export default Element;
