import React from "react";
import { HiChevronDoubleUp } from "react-icons/hi";
import { Link } from "react-scroll";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import BodyPublication from "../components/publication/BodyPublication";

import bgHeader from "../img/publications/header-publication.jpg";
import translate from "../internationalisation/translate";

const Publication = () => {
  const describe = translate('headerDescribeBlog');
  const header = {
    title: translate('Blog'),
    describe: describe,
    image: bgHeader,
  };

  return (
    <div className="relative bg-white cursor-default">
      <div className="absolute z-50">
        <Navbar />
      </div>

      <div id="home" className="h-14 "></div>

      <div className="flex justify-end">
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="fixed p-2 text-2xl bg-white border border-gray-200 rounded-full shadow-xl cursor-pointer text-green-01 shadow-gray-300 right-5 bottom-14"
        >
          <HiChevronDoubleUp />
        </Link>
      </div>

      <div>
        <Header header={header} white={false} visible={false} />
      </div>
      <div>
        <BodyPublication />
      </div>
      <div className="">
        <ContactForm />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Publication;
