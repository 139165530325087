import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const DonneesPersoOfty = () => {
  return (
    <div className="w-full bg-[#ffffff]">
      <Navbar />

      <div className="pt-40 mx-10 flex flex-col justify-start md:px-20 lg:px-60  ">
        <h1 className="text-xl md:text-2xl font-lato font-semibold text-blueT tracking-normal ">
          Données personnelles d'OFTY (OFTY SAS France)
        </h1>
        <h4 className="text-md md:text-xl font-semibold font-lato mt-2">
          Introduction
        </h4>
        <div className="my-2 text-sm md:text-lg font-lato">
          Cette page vise à vous informer de l'utilisation de vos données
          personnelles sur ce site. <br />
          <br />
          Ofty.fr, Plateforme appartenant à l’entreprise OFTY France, s'engage à
          ce que les traitements de données personnelles effectués sur ce site
          soient conformes au règlement général sur la protection des données
          (RGPD) et à la loi Informatique et Libertés. <br />
          <br />
          OFTY.fr est un site web numérique appartenant à l’entreprise OFTY SAS
          France <a className="text-blueT font-semibold">https://ofty.fr</a>. Il
          est question du site vitrine de l'entreprise OFTY . <br />
          <br />
          La consultation du site est libre et ne nécessite pas de compte
          utilisateur.
          <br />
          Pour vous fournir des services personnalisés, nous pouvons être
          obligés de vous demander de vous identifier ou de recueillir certaines
          données personnelles.
          <br />
          Dans ce cadre, OFTY est amené à traiter des informations personnelles
          vous concernant. Il s'agit par exemple d'informations identifiant vos
          consultations, de l'inscription à un service d'alertes, ou encore
          d'informations collectées lors de la création d'un compte vous
          permettant de déposer des documents ou de créer un CV.
          <br />
          Le responsable de traitement est OFTY SAS 6 Rue des Tilleuls, 95870
          Bezons, France Mail :{" "}
          <span className="text-blueT font-semibold">contact@ofty.fr</span>
        </div>
        <h4 className="text-md  md:text-xl font-semibold font-lato mt-2">
          Finalité du traitement <br />
        </h4>
        <div className="my-2 text-sm md:text-lg font-lato">
          Les données renseignées dans les formulaires servent à vous
          identifier, à savoir quel contenu vous appartient, à vous contacter si
          vous le souhaitez, par exemple à vous envoyer des alertes par courriel
          si vous le demandez. <br />
        </div>
        <h4 className="text-md  md:text-xl font-semibold font-lato mt-2">
          Base légale du traitement <br />
        </h4>
        <div className="font-lato font md:text-lg text-sm">
          La collecte de données s'effectue sur la base du consentement ou
          volontariat. <br />
        </div>
        <h4 className="text-md font-semibold font-lato md:text-xl mt-2">
          Données enregistrées <br />
        </h4>
        <div className=" font-lato font-normal md:text-lg text-sm">
          Des données personnelles sont susceptibles d'être collectées par
          OFTY :
          <br />
          <ul>
            <li className=" font-normal">
              {" "}
              - Création d'une requête
              <br />
            </li>
            <div>
              Dans le cadre du formulaire de création requête, les données
              personnelles collectées sont : <br />• Nom (obligatoire) ;<br /> •
              Prénom(obligatoire) ; <br />• Adresse e-mail (obligatoire) ;{" "}
              <br />• Le corps de votre requête (obligatoire) <br />
            </div>
          </ul>
        </div>
        <h4 className="text-md md:text-xl font-semibold font-lato mt-2">
          Consultations <br />
        </h4>
        <div className=" font-lato font-normal text-sm md:text-lg">
          {" "}
          <br />
          Dans le cadre de la consultation du site Des données de consultation
          sont enregistrées à des fins statistiques et d'audit de sécurité via
          le serveur web et conservées selon les obligations légales. Document
          mis à jour le 08 novembre 2022. <br />
        </div>
      </div>
      <div className="mt-10">
        <Footer />
      </div>
    </div>
  );
};

export default DonneesPersoOfty;
