import React from "react";

import image from "../../img/inside_1.png";
import translate from "../../internationalisation/translate";
import Button from "./Button";

const About = () => {
  const about = {
    title: "TRUSTED SOFTWARE DEVELOPMENT COMPANY SINCE",
    year: "2020",
    describe:
      "With over 02 years of experience and 850+ employees on board, Iflexion serves medium-sized and large companies globally. Profound tech knowledge coupled with business understanding is what allows us to create unique solutions that power enterprises, their employees, and customers every day. We stick to the latest industry trends and quality requirements to deliver powerful and secure software that fits corporate environments perfectly and brings positive user experience.",
    image: image,
  };

  const linkAbout = { url: "/about", text: "Learn More", color: "green" };

  return (
    <div className="mx-6 lg:mx-10 xl:mx-16 flex justify-between flex-col xl:flex-row gap-4 items-center">
      <div className="m-4 lg:m-12 text-left xl:max-w-[50%] xl:order-last">
        <h3 className="font-bold font-lato text-2xl lg:text-3xl 2xl:text-4xl mb-4">
          <span className="text-green-01">{translate("About")} </span>
          <span className="text-blue-01">OFTY</span>
        </h3>

        <h1 className="text-black font-lato font-medium text-xl md:text-2xl lg:text-4xl break-02:text-5xl mb-5 lg:mb-7">
        {translate("aboutTitle")}
          <span className="text-green-01"> {about.year}</span>
        </h1>
        <div className="text-gray-700 font-poppins text-justify text-base md:text-xl break-02:text-2xl px-4">
          <div className="m-2 py-2">{translate("aboutDescribe")}</div>
        </div>
        <div className="flex justify-start">
          <Button
            adress={linkAbout.url}
            name={translate('learnMore')}
            color={linkAbout.color}
          />
        </div>
      </div>
      <div className="m-4 flex justify-center w-full">
        <img
          src={about.image}
          alt="..."
          className="break-02:w-[48rem] lg:ml-16"
        />
      </div>
    </div>
  );
};

export default About;
