import React, { useState } from "react";
import { FaCircle } from "react-icons/fa";
import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../api";
import { NotificationManager } from "react-notifications";
import translate from "../internationalisation/translate";

const ContactForm = () => {
  const [message, setMessage] = useState({});
  const [testEmail, setTestEmail] = useState(false);
  const [stateMessage, setStateMessage] = useState(false);

  let handleChange = (event) => {
    const regex =
      /^[^\W][a-zA-Z0-9\-\._]+[^\W]@[^\W][a-zA-Z0-9\-\._]+[^\W]\.[a-zA-Z]{2,6}$/;
    if (event.target.name === "email") {
      if (regex.test(event.target.value)) {
        setTestEmail(true);
      } else {
        setTestEmail(false);
      }
    }
    setMessage({
      ...message,
      [event.target.name]: event.target.value,
    });
  };
  let sendMessage = (event) => {
    event.preventDefault();
    axios
      .post(apiUrlVitrine + "/message/", message)
      .then((res) => {
        setStateMessage(true);
        NotificationManager.success(
          translate("ResponseNewletter"),
          translate("respSuccess"),
          5000
        );
      })
      .catch((e) => {
        NotificationManager.error(
          translate("respErrorLong"),
          translate("respError"),
          5000
        );
      });
  };
  return (
    <div className="mt-6 mb-16">
      <div className="grid justify-center py-3 text-center sm:py-6">
        <h3 className="mb-4 text-xl font-bold font-lato sm:text-2xl lg:text-4xl">
          <span className="text-green-01">Contact </span>
          <span className="text-blue-01">OFTY</span>
        </h3>

        <div className="grid items-center justify-center px-2 pb-4 text-center font-poppins lg:text-lg 2xl:text-xl">
          {translate("contactFomHeader")}
        </div>
      </div>

      {/* Formulaire de contact */}
      {!stateMessage ? (
        <form onSubmit={sendMessage} className="mx-5">
          <div className="justify-center md:flex">
            <div className="grid max-w-xl gap-1 sm:mx-4 md:w-1/2 2xl:max-w-2xl">
              <div className="relative w-full mb-5 form-floating">
                <input
                  type="text"
                  className="form-control block w-full text-lg font-normal text-[#444] bg-white-01 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-[#444] focus:bg-white-01 focus:border-blue-01 focus:outline-none"
                  onChange={handleChange}
                  name="name"
                  placeholder={translate("fullName")}
                  required
                />
                <label
                  htmlFor="floatingInput"
                  className="text-lg text-gray-400"
                >
                  {translate("fullName")}
                </label>
              </div>

              <div className="relative w-full my-5 form-floating">
                <input
                  type="email"
                  className={`form-control block w-full text-lg font-normal text-[#444] bg-white-01 bg-clip-paddingrounded transition ease-in-out m-0 focus:text-[#444] focus:bg-white-01 ${
                    !testEmail && message.email
                      ? "focus:border-red-700  border border-solid border-red-300  focus:outline-none  "
                      : " focus:border-blue-01  focus:outline-none  border border-solid border-gray-300  "
                  }`}
                  onChange={handleChange}
                  name="email"
                  placeholder="Email"
                  required
                />
                <label
                  htmlFor="floatingInput"
                  className="text-lg text-gray-400"
                >
                  Email*
                </label>
              </div>

              <div className="relative w-full my-5 form-floating">
                <input
                  type="text"
                  className="form-control block w-full text-lg font-normal text-[#444] bg-white-01 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-[#444] focus:bg-white-01 focus:border-blue-01 focus:outline-none"
                  onChange={handleChange}
                  name="object"
                  placeholder={translate("object")}
                />
                <label
                  htmlFor="floatingInput"
                  className="text-lg text-gray-400"
                >
                  {translate("object")}
                </label>
              </div>
              <div className="relative w-full my-5 form-floating">
                <input
                  type="text"
                  className="form-control block w-full text-lg font-normal text-[#444] bg-white-01 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-[#444] focus:bg-white-01 focus:border-blue-01 focus:outline-none"
                  onChange={handleChange}
                  name="company"
                  placeholder={translate("companyName")}
                />
                <label
                  htmlFor="floatingInput"
                  className="text-lg text-gray-400"
                >
                  {translate("companyName")}
                </label>
              </div>

              <div className="relative w-full my-5 form-floating md:mb-0">
                <input
                  type="text"
                  required
                  className="form-control block w-full text-lg font-normal text-[#444] bg-white-01 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-[#444] focus:bg-white-01 focus:border-blue-01 focus:outline-none"
                  onChange={handleChange}
                  name="tel"
                  placeholder={translate("phone")}
                />
                <label
                  htmlFor="floatingInput"
                  className="text-lg text-gray-400"
                >
                  {translate("phone")}
                </label>
              </div>
            </div>

            <div className="max-w-xl my-6 sm:mx-4 md:my-0 md:w-1/2 2xl:max-w-2xl">
              <textarea
                className="form-control block w-full h-60 md:h-full px-3 py-2 text-lg font-normal text-[#444] bg-white-01 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-[#444] focus:bg-white-01 focus:border-blue-01 focus:outline-none"
                onChange={handleChange}
                required
                name="body"
                placeholder="Message ..."
              ></textarea>
            </div>
          </div>

          <div className="flex justify-around mt-8 text-sm font-medium text-white select-none lg:text-xl">
            <button
              type="submit"
              disabled={!testEmail}
              className="flex items-center px-3 py-2 leading-tight transition duration-150 ease-in-out rounded bg-green-01 hover:bg-blue-01 hover:bg-opacity-90 focus:outline-none focus:ring-0"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
            >
              Submit
              <FaCircle className="ml-2 lg:ml-4" size={14} />
            </button>
          </div>
        </form>
      ) : (
        <div className="py-10 text-xl text-center font-lato">
          Mr/Mme
          <span className="text-2xl font-bold text-green-600 ">
            {"   " + message.name + "    "}
          </span>
          votre message a été envoyé avec success
        </div>
      )}
    </div>
  );
};

export default ContactForm;
