import React from "react";

import image from "../../img/inside_2.png";
import translate from "../../internationalisation/translate";
import Button from "./Button";
import Aos from "aos";
import { useEffect } from "react";

const Inside = () => {
  const linkInside = { url: "/about", text: "Learn More", color: "blue" };
  const inside = {
    title: "How we stand out - We are Proactive Experts",
    describe:
      "We proactively consult, design, develop & scale robust web, mobile & custom software solutions, that fuel innovation & deliver digitalsuccess!",
    text: "At Unified we believe that every project is an important milestone in our journey. So we position ourselves as a boutique digital agency, ustom tailoring impactful digital solutions with industry best practices across the board, for Fortune 500's, SMEs, and Start-up’s around the globe.",
    image: image,
  };
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="mx-2 md:mx-6 lg:mx-10 xl:mx-16 grid grid-cols-1 xl:grid-cols-2 gap-4 items-center">
      <div className="m-4 text-left">
        <h3
          data-aos="fade-down"
          className="font-bold font-lato text-2xl lg:text-3xl 2xl:text-4xl"
        >
          <span className="text-green-01">{translate("inside")} </span>
          <span className="text-blue-01">OFTY</span>
        </h3>

        <h1
          data-aos="fade-down"
          className="font-semibold font-lato text-black text-3xl md:text-4xl lg:text-5xl my-5 lg:my-7"
        >
          {translate("insideTitle")}
        </h1>

        {/* <p className="sm:font-medium font-poppins text-black text-lg sm:text-xl lg:text-2xl break-02:text-3xl sm:mb-10 lg:mb-14">
        {translate('insideDescribe')}
        </p> */}
        <p className="hidden sm:flex m-2 font-poppins font-light text-justify text-lg lg:text-xl break-02:text-2xl">
          {translate("insideTexte")}
        </p>
        <div className="flex justify-start">
          <Button
            adress={linkInside.url}
            name={translate("learnMore")}
            color={linkInside.color}
          />
        </div>
      </div>
      <div className="flex justify-center items-center mx-4">
        <img src={inside.image} alt="..." className="rounded" />
      </div>
    </div>
  );
};

export default Inside;
