import React from "react";
import { useSelector } from "react-redux";
import translate from "../../internationalisation/translate";
import CardAchiev from "./CardAchiev";

const Achievement = ({ achievements }) => {
  return (
    <div>
      <div className="mx-6 lg:mx-10 xl:mx-16 h-42">
        <div className="my-4 ml-8 text-right">
          <h3 className="font-bold font-lato text-2xl lg:text-3xl 2xl:text-4xl">
            <span className="text-green-01">{translate("inside")} </span>
            <span className="text-blue-01">OFTY</span>
          </h3>
          <div className="text-2xl 2xl:text-3xl font-medium font-poppins">
            {translate("lastAchievement")}
          </div>
        </div>
      </div>

      <div className="w-full  bg-achiev bg-cover min-h-max py-8 2xl:py-14">
        <div
          id="carouselExampleControls"
          className="carousel slide relative"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner relative w-full overflow-hidden">
            {achievements.map((achiev, index) => (
              <div
                key={achiev.id}
                className={`${
                  index === 0 ? "active" : ""
                } carousel-item relative float-left w-full`}
              >
                <div className="flex justify-center">
                  <CardAchiev achievement={achiev} />
                </div>
              </div>
            ))}
          </div>

          <button
            className="cursor-pointer carousel-control-next absolute -left-0 bottom-60 flex items-center justify-center p-0 h-10 w-10 "
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon inline-blockbg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>

          <button
            className="cursor-pointer carousel-control-next absolute -right-0 bottom-60   flex items-center justify-center p-0 h-10 w-10 "
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon inline-block bg-no-repeat "
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Achievement;
