import { BrowserRouter as Router, Route, Routes, Switch } from "react-router-dom";
import { useEffect, useState } from "react";
import About from "./pages/About";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import Expertise from "./pages/Expertise";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Products from "./pages/Products";
import Publication from "./pages/Publication";
import ConsultingServices from "./pages/ConsultingServices";
import ResearchServices from "./pages/ResearchServices";
import ViewPublication from "./pages/ViewPublication";
import ViewProduct from "./pages/ViewProduct";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Provider, useSelector } from "react-redux";
import { store } from "./store"
import LanguageProvider from "./internationalisation/LanguageProvider";
import { LOCALES } from "./internationalisation/locales";
import ModalTerms from "./components/ModalTerms";
import { SendProvider } from "./context/SendContext"
import Page404 from "./pages/Page404";
import Privacy from "./pages/Privacy";
import DonneesPersoOfty from "./pages/DonneesPersoOfty";
import DonneesPersoAndaal from "./pages/DonneesPersoAndaal";
import MentionLegalOfty from "./pages/MentionLegalOfty";
import MentionLegaleAndaal from "./pages/MentionLegaleAndaal";


// import Test from "./AppTest";

// import Achievement from "./pages/Achievement";
// import Process from "./pages/Process";

function App() {
  const locale = useSelector((state) => state.languageLocale);
  const [spiner, setSpiner] = useState(true);


  // useEffect(()=>{
  //  if(spiner===true){


  //  }
  // },[])

  console.log = console.warn = console.error = () => {};
  
  return (

    <LanguageProvider locale={locale}>

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/services" element={<Services />} />
          <Route path="/Career" element={<Career />} />

          <Route path="/services/consulting" element={<ConsultingServices />} />
          <Route
            path="/services/research-and-developpement"
            element={<ResearchServices />}
          />

          {/* <Route path="/achievement" element={<Achievement />} />
        <Route path="/process" element={<Process />} /> */}
          {/* <Route path="/career" element={
          <SendProvider><Career /></SendProvider>} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/products" element={<Products />} />

          <Route path="/products/:id" element={<ViewProduct />} />

          <Route path="/publication" element={<Publication />} />
          <Route path="/donneespersonnellesofty" element={<DonneesPersoOfty />} />
          <Route path="/donneespersonnellesandaal" element={<DonneesPersoAndaal />} />
          <Route path="/mentionlegaleofty" element={<MentionLegalOfty />} />
          <Route path="/mentionlegaleandaal" element={<MentionLegaleAndaal />} />

          <Route path="/publication/:id" element={<ViewPublication />} />
          <Route path="/privacy" element={<Privacy />} />

          {/* <Route path="/test" element={<Test />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Page404 />} />
        </Routes>

        <NotificationContainer />
      </Router>
    </LanguageProvider>
  );
}

export default App;
