import React from "react";
import { useSelector } from "react-redux";

const Header = ({ header }) => {
  console.log(header);
  const bgHeader = {
    backgroundImage: `url(${header[0].image})`,
  };
  const locale = useSelector((state) => state.languageLocale);
  return (
    <div className="text-white my-3">
      {/* Texte de desciption */}
      <div
        className="h-[28rem] md:h-[35rem] break-04:h-[45rem] break-02:h-[50rem] w-full grid grid-cols-1 bg-local bg-cover bg-no-repeat bg-center"
        style={bgHeader}
      >
        {/* <div className="flex flex-col items-center h-[85%] justify-end">
          <div className="font-lato text-5xl md:text-7xl 2xl:text-8xl text-center font-bold uppercase text-shadow shadow-sm">
            {locale === "en-us"
              ? header[0].translations.en.title
              : header[0].translations.fr.title}
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Header;
