import React, { Fragment } from "react";
import { IntlProvider } from "react-intl";
import { LOCALES } from "./locales";
import messages from "./messages";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";


const LanguageProvider = ({ children, locale }) => {
    return (
        <IntlProvider
            locale={locale}
            textComponet={Fragment}
            messages={messages[locale]}
        >
            {children}
        </IntlProvider>
    )
}

export default LanguageProvider;