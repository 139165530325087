import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaCheckSquare } from "react-icons/fa";
import translate from "../../internationalisation/translate";
import { apiUrlVitrine, defaultHeaders } from "../../api";

const BodyViewProduct = ({ product }) => {
  return (
    <div className="m-6 lg:m-10 break-02:m-20 ">
      <div className="flex flex-col md:flex-row justify-between lg:items-center gap-2 my-4 2xl:mx-4">
        <div className="">
          <div className="py-3 sm:py-6 grid md:grid-cols-2 w justify-start my-5">
            <div className="mx-4 mb-2">
              <h3 className="font-bold font-lato text-xl sm:text-2xl lg:text-4xl mb-4">
                <span className="text-green-01">{translate("about")} </span>
                <span className="text-blue-01"> {translate("project")} </span>
              </h3>
              <div className=" font-light text-justify font-poppins md:text-lg 2xl:text-xl">
                {product.aboutProduct}
              </div>
            </div>
            <div className="grid justify-center items-center mx-4 ">
              <img src={product.imageAbout} alt="" />
            </div>
          </div>
          <div className="py-3 sm:py-6 grid md:grid-cols-2 w justify-start my-5">
            <div className=" md:order-last mx-4  mb-2 ">
              <h3 className="font-bold font-lato text-xl sm:text-2xl lg:text-4xl mb-4">
                <span className="text-green-01">{translate("vision")}</span>
                <span className="text-blue-01">
                  {" "}
                  {translate("of")} {product.name}
                </span>
              </h3>
              <div className=" font-light text-justify font-poppins md:text-lg 2xl:text-xl">
                {product.visionProduct}
              </div>
            </div>
            <div className="grid justify-center items-center mx-4">
              <img src={product.imageVision} alt="" />
            </div>
          </div>
          <div className="py-3 sm:py-6 grid md:grid-cols-2 w justify-start my-5">
            <div className="mx-4  mb-2">
              <h3 className="font-bold font-lato text-xl sm:text-2xl lg:text-4xl mb-4">
                <span className="text-green-01">{translate("valeurs")} </span>
                <span className="text-blue-01">
                  {translate("of")} {product.name}
                </span>
              </h3>
              <div className=" font-light text-justify font-poppins md:text-lg 2xl:text-xl">
                {product.valeurProduct}
              </div>
            </div>
            <div className="grid justify-center items-center mx-4">
              <img src={product.imageValeur} alt="" />
            </div>
          </div>
          <div className="py-3 sm:py-6 grid md:grid-cols-2 w justify-start my-5">
            <div className=" md:order-last mx-4  mb-2">
              <h3 className="font-bold font-lato text-xl sm:text-2xl lg:text-4xl mb-4">
                <span className="text-green-01">{translate("cible")} </span>
                <span className="text-blue-01">{product.name}</span>
              </h3>
              <div className=" font-light text-justify font-poppins md:text-lg 2xl:text-xl">
                {product.cibleProduct}
              </div>
            </div>
            <div className="grid justify-center items-center mx-4">
              <img src={product.imageAbout} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyViewProduct;
