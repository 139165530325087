import React from "react";
import { FaCircle } from "react-icons/fa";

import bgImage from "../img/bg-contact.png";
import translate from "../internationalisation/translate";

const ContactLink = () => {
  const bgContact = {
    backgroundImage: `url(${bgImage})`,
  };

  const contact = {
    title: translate('contactLink'),
    text: translate('contactLinkText'),
  };

  return (
    <div
      className="bg-local bg-center bg-cover w-full grid justify-center items-center my-5"
      style={bgContact}
    >
      <div className="flex flex-col md:flex-row items-center m-5 lg:m-10 bg-gray-01 bg-opacity-30 shadow-md shadow-gray-600 rounded-lg p-4">
        <div className="p-4 text-white font-poppins">
          <div className="pb-4 font-medium text-lg md:text-xl">
            {contact.title}
          </div>
          <div className="font-light max-w-3xl 2xl:max-w-5xl text-sm md:text-base">
            {contact.text}
          </div>
        </div>
        <div className="min-w-[16rem] flex justify-center md:justify-end mr-6">
          <a href="/contact">
            <div className="uppercase min-w-max flex justify-around items-center transition ease-in-out delay-250 duration-300 px-5 py-1 text-blue-01 bg-white hover:translate-y-1 hover:scale-105 rounded-xl hover:text-green-01">
              <span className="font-lato sm:text-base lg:text-lg">
               { translate('contact')}
              </span>
              <span className="ml-4">
                <FaCircle size={10} />
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactLink;
