import React from "react";

const Privacy = () => {
  return (
    <div className="w-full bg-[#ffffff]">
      <div className="mt-40 mx-10 flex flex-col justify-start md:px-20 lg:px-60  ">
        <h1 className="text-xl md:text-2xl font-lato text-blue-700 text-center font-semibold text-blueT tracking-normal ">
          POLITIQUE DE CONFIDENTIALITÉ DE Andaal
        </h1>
        <p className="my-2 text-sm md:text-lg font-lato ">
          ANDAAL est une application développée et gérée par l’entreprise
          OFTY.SAS, dont le siège est situé 6 Rue des Tilleuls, 95870 Bezons,
          France ; Mail : contact@ofty.fr
        </p>
        <p className="my-2 text-sm md:text-lg font-lato">
          À ce titre, les informations et données personnelles sont collectées
          et traitées par OFTY SAS selon la présente politique de
          confidentialité qui est celle de OFTY SAS (ci-dessous « OFTY »).
        </p>
        <p className="my-2 text-sm md:text-lg font-lato">
          OFTY accorde la plus grande importance au respect de la vie privée et
          la protection des données à caractère personnel. À ce titre, OFTY
          s'engage à ce que les traitements de données personnelles effectués
          sur ce site soient conformes au Règlement Européen 2016/679 du 27
          avril 2016 (ou « Règlement Général sur la Protection des Données ») et
          la loi n°78-17 du 6 janvier 1978 dite loi informatique et libertés
        </p>
        <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
          1. Responsable du traitement des données
        </h4>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Vos Données sont collectées et traitées par :
          <br />
        </p>
        <div className="my-2 text-sm md:text-lg font-lato font-bold">
          OFTY SAS
          <br />
          SIREN : 853986487
          <br />
          RCS : Pontoise B 853 986 487 <br />
          Adresse : 6 rue des Tilleuls 95870 Bezons, France
        </div>
        <p className="my-2 text-sm md:text-lg font-lato">
          Lorsque OFTY agit en tant que sous-traitant, vous pouvez vous référer
          aux conditions particulières, conditions générales de vente ou
          conditions générales d’utilisation de votre solution.
          <br />
        </p>
        <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
          2. Identification des données collectées, finalités et exploitation
          des données traitées
        </h4>
        <h4 className="my-3 text-lg font-lato font-semibold">
          a.Identification des données collectées
        </h4>
        <p className="my-2 text-sm md:text-lg font-lato ">
          La société OFTY, en qualité de Responsable de Traitement, est amené à
          collecter, consulter, utiliser, modifier, stocker, transmettre et
          effacer des Données à Caractère Personnel (ci-après les « Données »)
          dans le cadre de ses activités.
          <br />
        </p>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Conformément à l’article 4 du Règlement Général de Protection des
          Données 2016/679 du 27 avril 2016, les termes « Données » ou « Données
          à Caractère Personnel » désignent toute information se rapportant à
          une personne physique identifiée ou identifiable (ci-après dénommée «
          Personne Concernée ») ; est réputée être une « personne physique
          identifiable » une personne physique qui peut être identifiée,
          directement ou indirectement, notamment par référence à un
          identifiant, tel qu'un nom, un numéro d'identification, des données de
          localisation, un identifiant en ligne, ou à un ou plusieurs éléments
          spécifiques propres à son identité physique, physiologique, génétique,
          psychique, économique, culturelle ou sociale. Ces données sont pour la
          plupart collectées directement auprès des personnes concernées,
          lorsqu’elles, de manière non limitative ;
          <br />
        </p>
        <div className=" font-lato font-normal md:text-lg text-sm px-5">
          • Créent un espace personnel sur le site internet de OFTY ou sur l’une
          des applications ou plateformes appartenant ou gérées par OFTY ;
          <br /> • Effectuent une demande de renseignement sur le site internet
          de OFTY ou sur l’une des applications ou plateformes appartenant ou
          gérées par OFTY ;
          <br /> • Effectuent une commande sur le site internet de OFTY ou sur
          l’une des applications ou plateformes appartenant ou gérées par OFTY
          ou par tout autre moyen ou souscrivent à un essai gratuit ;
          <br /> • Naviguent sur le site internet de OFTY ou sur l’une des
          applications ou plateformes appartenant ou gérées par OFTY et/ou
          consultent les produits ;
          <br /> • Demandent à être recontactées par un commercial de OFTY ;
          <br /> • Téléchargent un document ou tout autre support sur le site
          internet de OFTY ou sur l’une des applications ou plateformes
          appartenant ou gérées par OFTY ;
          <br /> • Participent à un jeu ou un concours ;
          <br /> • Contactent le Service Client ;
          <br /> • Rédigent un avis/commentaire visible sur le site internet de
          OFTY ou sur l’une des applications ou plateformes appartenant ou
          gérées par OFTY ;
          <br /> • Postulent aux offres d’emploi sur le site internet de OFTY ou
          sur l’une des applications ou plateformes appartenant ou gérées par
          OFTY.
        </div>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Dans le cadre des activités de collecte mentionnées ci-avant, OFTY
          collecte les données suivantes :
          <br />
        </p>
        <div className=" font-lato font-normal md:text-lg text-sm px-5">
          • Les données identitaires ; <br /> • Les données de contact ; <br />{" "}
          • Les données de facturation et des modalités de paiement ; <br /> •
          Le détail des services et abonnements souscrits ; <br /> •
          L’historique de la relation contractuelle ; <br /> • Une copie de la
          correspondance des personnes avec la direction Relation client ;{" "}
          <br /> • Le cas échéant, les réponses aux sondages et questionnaires
          et les commentaires/avis ; <br /> • Les informations techniques liées
          à la navigation (ex : adresse IP) ; <br /> • Les prétentions
          salariales et toutes données transmises dans le Curriculum vitae dans
          le cadre d’une candidature spontanée ou en réponse à une offre
          d’emploi au sein de l’entreprise OFTY.
        </div>
        <h4 className="my-3 text-lg font-lato font-semibold">
          b. Finalités et exploitation des données traitées
        </h4>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Afin de mieux appréhender les traitements de données effectués par
          OFTY, nous informons les personnes concernées que OFTY peut traiter
          leurs données dans le cadre des finalités suivantes :
          <br />
        </p>
        <p className="my-2 text-sm md:text-lg font-lato font-semibold ">
          La réalisation de traitements relatifs à la gestion de l’exécution
          d’un accord avec un Client (« personne concernée par la collecte de
          données personnelles ») :
          <br />
        </p>
        <div className=" font-lato font-normal md:text-lg text-sm px-5">
          • Des contrats et des comptes clients ;
          <br /> • Des commandes et de leurs livraisons ;
          <br /> • De la facturation et de recouvrement (Numéro de téléphone,
          moyens ou instruments de paiement (orange money , mtn money), Numéro
          de carte bancaire ou données de compte bancaire, etc) ;
          <br /> • La réalisation de traitement relatifs à la gestion des
          candidatures et de l’embauche.
          <br />
        </div>
        <p className="my-2 text-sm md:text-lg font-lato font-semibold ">
          La réalisation de traitements relatifs à la gestion de la relation
          client :
          <br />
        </p>
        <div className=" font-lato font-normal md:text-lg text-sm px-5">
          • La gestion des avis des personnes sur des produits, services ou
          contenus ;
          <br /> • Le suivi de la relation client tel que la réalisation
          d'enquêtes de satisfaction ;
          <br /> • La gestion du programme de fidélité au sein d’une entité ou
          de plusieurs entités juridiques ;
          <br /> • La sélection de clients et utilisateurs pour réaliser des
          actions de fidélisation, de test produit.
          <br />
        </div>
        <p className="my-2 text-sm md:text-lg font-lato font-semibold ">
          La réalisation de traitements relatifs à la prospection commerciale et
          en particulier :
          <br />
        </p>
        <div className=" font-lato font-normal md:text-lg text-sm px-5">
          • La sélection de personnes pour réaliser des actions de prospection ;
          <br />
          • La réalisation d'opérations de sollicitation ;
          <br /> • L'actualisation de ses fichiers de prospection par
          l'organisme en charge de la gestion de la liste d'opposition au
          démarchage téléphonique ;
          <br /> • L’organisation de jeux-concours, de loteries ou de toute
          opération promotionnelle.
          <br />
        </div>
        <p className="my-2 text-sm md:text-lg font-lato font-semibold ">
          La réalisation de traitement relatif à la gestion des réclamations :
          <br />
        </p>
        <div className=" font-lato font-normal md:text-lg text-sm px-5">
          • La gestion des réclamations et du service après-vente ;<br /> • Le
          traitement des demandes de droit d'accès, de rectification,
          d'opposition, d’effacement ;
          <br /> • La gestion des contentieux.
          <br />
        </div>
        <p className="my-2 text-sm md:text-lg font-lato font-semibold ">
          La réalisation de traitements relatifs au pilotage de l’activité
          commerciale ;
          <br />
        </p>
        <div className=" font-lato font-normal md:text-lg text-sm px-5">
          • La sélection de personnes pour réaliser des études et/ou des
          sondages ; <br /> • L’élaboration de statistiques commerciales ;{" "}
          <br /> • La classification intelligente des données.
          <br />
        </div>
        <p className="my-2 text-sm md:text-lg font-lato font-semibold ">
          Les traitements mis en œuvre par OFTY sont fondés sur :
          <br />
        </p>
        <div className=" font-lato font-normal md:text-lg text-sm px-5">
          • Le consentement pour la prospection commerciale à destination des
          personnes physiques non-clientes ; <br /> • L’intérêt légitime pour
          les traitements relatifs au pilotage de l’activité commerciale, à la
          prospection commerciale pour les personnes morales, et au
          recrutement ; <br /> • Le respect d’une obligation légale pour les
          traitements liés à la fiscalité par exemple :
          <div className=" font-lato font-normal md:text-lg text-sm px-10">
            ◦ A l’ensemble des applications, supports et plateformes appartenant
            ou gérés par OFTY ou les sociétés dans lesquelles OFTY a une
            participation ;
            <br /> ◦ Prestataires de services et sous-traitants réalisant des
            prestations pour notre compte ;
            <br /> ◦ Partenaires commerciaux.
            <br />
          </div>
          <br />
        </div>
        <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
          3. Hébergement des Données
        </h4>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Les Données à Caractère Personnel collectées et traitées par OFTY sont
          hébergées au sein de l’Union Européenne.
          <br />
        </p>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Néanmoins, vos données peuvent être transférées à des sous-traitants
          situés en dehors de l’Union Européenne pour la durée du traitement.
          <br />
        </p>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Ces sous-traitants n’agissent que sur instructions documentées de
          OFTY. Ces transferts se font dans le cadre de l’exploitation des
          applications et infrastructures et pour certains traitements de télé
          vente et prise rendez-vous externe.
          <br />
        </p>
        <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
          4. Destination des Données collectées
        </h4>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Les données collectées peuvent être transmises : <br /> Les données
          collectées sont susceptibles d’être traitées hors de l’Union
          Européenne. Dans ce cas, OFTY s’engage à prendre les dispositions
          nécessaires avec ses sous-traitants et partenaires pour garantir un
          niveau de protection de vos données adéquat et ce en toute conformité
          avec la règlementation applicable.
          <br />
        </p>
        <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
          5. Durée de conservation des données
        </h4>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Les données sont conservées pour une durée adaptée aux finalités des
          traitements et conformément à la législation et réglementation en
          vigueur.
          <br /> Les données des clients sont conservées pendant toute la durée
          de la relation contractuelle, et peuvent être conservées jusqu’à dix
          (10) ans après sa rupture afin de respecter notamment les obligations
          comptables et fiscales qui incombent à OFTY.
          <br /> Les données des prospects personnes physiques peuvent être
          conservées jusqu’à trois (3) ans après le dernier contact émanant du
          prospect.
          <br /> Les Données à caractère personnel transmises dans le cadre
          d’une candidature seront conservées, sauf opposition de la part du
          candidat, pendant une période de deux (2) ans à compter de leur dépôt
          ou du dernier contact avec le candidat. Passé ce délai, les données
          seront supprimées.
          <br />
        </p>
        <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
          6. Exercice de droits
        </h4>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Conformément au Règlement Général sur la Protection des Données
          2016/679 du 27 avril 2016, chaque personne dispose d’un droit d’accès,
          de rectification, d’effacement et de portabilité de ses Données à
          Caractère Personnel, et d’un droit à la limitation et à l’opposition
          au traitement de ces données.
          <br /> Ces droits peuvent être exercés en nous contactant à l’adresse
          suivante :
          <br />
        </p>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Par Courrier : <br /> OFTY SAS <br /> 6 Rue des Tilleuls <br /> 95870
          Bezons <br /> France <br /> <br /> Ou par Mail : contact@ofty.fr
          <br />
        </p>
        <p className="my-2 text-sm md:text-lg font-lato ">
          En cas d’exercice de vos droits, vous devez justifier de votre
          identité par tous moyens. Toutefois, si des doutes raisonnables
          persistent quant à l’exactitude de votre identité, OFTY pourra
          demander des informations supplémentaires telles que la photocopie de
          votre CI. Les personnes disposent également du droit d’introduire une
          réclamation auprès de la CNIL. De plus, et depuis la loi n°2016-1321
          du 7 octobre 2016, les personnes qui le souhaitent, ont la possibilité
          d’organiser le sort de leurs données après leur décès. Pour plus
          d’information sur le sujet : https://www.cnil.fr/.
          <br />
        </p>
        <h1 className="text-xl md:text-2xl font-lato text-blue-700 text-center  mt-20 font-semibold text-blueT tracking-normal ">
          Politique de gestion des cookies de OFTY SAS
        </h1>
        <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
          1. Définitions
        </h4>
        <p className="my-2 text-sm md:text-lg font-lato ">
          Pour des raisons de simplification et de compréhension de la présente
          page d’information, les termes ci-après ont été définis de la façon
          suivante : « Cookie(s) » :<br /> est un fichier texte déposé sur votre
          disque dur par le serveur du site que vous visitez. Il contient
          généralement le nom du serveur qui l’a déposé, un identifiant sous
          forme de numéro unique et une date d’expiration. Il a pour but de
          collecter des informations relatives à votre navigation afin de vous
          proposer des services adaptés à votre terminal (ordinateur, mobile, ou
          tablette).
          <br /> « Responsable de traitement » : « est, sauf désignation
          expresse par les dispositions législatives ou réglementaires relatives
          à ce traitement, la personne, l’autorité publique, le service ou
          l’organisme qui détermine ses finalités et ses moyens. En pratique et
          en général, il s’agit de la personne morale incarnée par son
          représentant légal »1. Le responsable de traitement est :
          <br />
        </p>
        <div className="my-2 text-sm md:text-lg font-lato font-bold">
          OFTY SAS
          <br />
          SIREN : 853986487
          <br />
          RCS : Pontoise B 853 986 487 <br />
          Adresse : 6 rue des Tilleuls 95870 Bezons, France
        </div>
        <p className="my-2 text-sm md:text-lg font-lato ">
          La durée de vie maximale des cookies ou traceurs est de 13 mois. Les
          cookies fonctionnels et techniques ne nécessitent pas le recueil du
          consentement de l’utilisateur.
          <br />
        </p>
        <p className="my-2 text-sm md:text-lg font-lato ">
          <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
            2. Utilisation des cookies par OFTY SAS
            <br />
          </h4>
          <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
            a.Les cookies fonctionnels
            <br />
          </h4>
          Les cookies fonctionnels sont nécessaires au fonctionnement du site
          internet ou au respect des obligations contractuelles. Ils permettent
          de mémoriser les données d’authentification sur un site et d’améliorer
          l’expérience utilisateur. Ils sont nécessaires pour satisfaire la
          demande de l’utilisateur. Les cookies fonctionnels sont indispensables
          au fonctionnement des sites, applications et plateformes de OFTY et à
          la navigation des utilisateurs sur ces derniers. Ils permettent aux
          personnes d'utiliser les principales fonctionnalités des sites et de
          sécuriser leur connexion. Ils peuvent leur permettre également
          d'accéder directement à des espaces qui leur sont réservés, grâce à
          des identifiants ou des données.{" "}
          <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
            b. Les cookies techniques
            <br />
          </h4>
          Les cookies techniques permettent à OFTY SAS de suivre les
          performances techniques de ses sites, de détecter des problèmes afin
          de réduire autant que possible une gêne pour l’utilisateur, d’activer
          et de seconder les fonctionnalités de sécurité, mais aussi d'aider à
          détecter des activités malveillantes et des violations de ces
          conditions générales d’utilisation.
          <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
            c. Les cookies d’optimisation de l’expérience utilisateur
            <br />
          </h4>{" "}
          Ces cookies permettent d'optimiser le fonctionnement des sites,
          applications et plateformes appartenant ou gérés par OFTY SAS. Ils
          permettent également d'adapter la présentation de ces sites aux
          préférences d'affichage du terminal utilisé par les personnes lors de
          leurs visites. Ces cookies permettent ainsi aux utilisateurs d'avoir
          une navigation fluide et sur mesure. Dans le cadre de la navigation
          sur les sites et supports marchands de OFTY SAS, des cookies
          d’optimisation de l’expérience d’achat sont susceptibles d’être
          utilisés. Ces cookies permettent notamment à OFTY SAS de proposer un
          accompagnement et une assistance personnalisée aux utilisateurs de ses
          services, en leur proposant par exemple d’interagir avec eux. En cas
          de refus de la mise en oeuvre de ces cookies, les utilisateurs ne
          pourront pas bénéficier des fonctionnalités décrites ci-dessus.
          <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
            d. Les cookies de mesure d’audience
            <br />
          </h4>{" "}
          Les cookies de mesure d’audience et de statistique permettent à OFTY
          SAS de connaître l'utilisation et les performances des sites,
          d'établir des statistiques, des volumes de fréquentation et
          d'utilisation de divers éléments (contenus visités, parcours…) aux
          fins d'améliorer l'intérêt et l'ergonomie des services proposés (les
          pages ou les rubriques les plus souvent consultées, les articles les
          plus lus ...). Ces cookies servent également à mesurer le trafic de
          consultation du site internet. Quand les mesures d’usage font partie
          intégrante de l’offre vendue, la finalité des cookies est considérée
          comme légitime et les cookies sont gérés comme des cookies
          fonctionnels. Certains cookies sont anonymisés et pour ceux non
          anonymisés, l’utilisateur a la possibilité de consentir ou non à la
          sauvegarde de ces cookies. Le fait de refuser la mise en œuvre de tels
          cookies ou de les supprimer n'a pas d'incidence sur la navigation des
          utilisateurs sur les sites, mais est de nature à empêcher OFTY SAS de
          s’assurer de l’amélioration de la qualité de services proposés aux
          visiteurs et utilisateurs de ses sites.
          <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
            e. Les cookies de publicité
            <br />
          </h4>{" "}
          Dans le cadre de la navigation sur l’ensemble des pages de ses sites,
          applications et plateformes, OFTY SAS est susceptible d’effectuer un
          traitement des données de navigation et/ou d’utiliser des cookies
          publicitaires afin de proposer aux visiteurs ou utilisateurs des
          publicités en adéquation avec leurs centres d’intérêt. Ces publicités
          peuvent être affichées sur les différents sites, applications et
          plateformes de OFTY SAS, mais également sur d’autres sites sur
          lesquels l’utilisateur pourrait naviguer. Ces cookies permettent
          également de limiter le nombre de fois qu’un visiteur verra une
          publicité et servent également à OFTY SAS pour évaluer l’efficacité de
          ses campagnes publicitaires. L’utilisateur a la possibilité de
          consentir ou non à la sauvegarde de ces cookies. Le refus de ces
          cookies publicitaires n'a pas d'impact sur l'utilisation des sites,
          applications et plateformes de OFTY SAS.{" "}
          <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
            f. Les cookies transférés à des tiers
            <br />
          </h4>{" "}
          Les sites, applications et plateformes de OFTY SAS peuvent inclure des
          boutons ou modules de réseaux sociaux qui permettent aux visiteurs de
          partager des contenus de ces supports avec d’autres personnes ou de
          faire connaître à des tiers votre consultation ou votre opinion
          concernant un contenu des supports de OFTY SAS. Lorsque les visiteurs
          utilisent ces boutons de partage, un cookie tiers du réseau social est
          installé. Si les visiteurs sont connectés au réseau social lors de
          leur navigation sur les sites, les boutons de partage permettent de
          relier les contenus consultés à leur compte utilisateur. Vous pouvez
          choisir de ne pas donner votre accord pour la transmission de vos
          cookies à des tiers. Les visiteurs sont invités à consulter les
          politiques de protection de la vie privée de ces réseaux sociaux afin
          de prendre connaissance des finalités d'utilisation, notamment
          publicitaires, des informations de navigation qu'ils peuvent
          recueillir grâce à ces boutons applicatifs, et de la durée de vie des
          cookies associés.
          <br />
        </p>
        <h4 className="text-xl  font-lato mt-2 text-blue-500 font-semibold">
          Mis à jour le 08 mars 2023
          <br />
        </h4>{" "}
      </div>
      <div className="mt-10"></div>
    </div>
  );
};

export default Privacy;
