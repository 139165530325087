import React from "react";
import translate from "../../internationalisation/translate";

const Value = ({ title, text }) => {
  return (
    <div className="max-w-sm bg-white p-5 m-4 shadow-xl">
      <div className="font-lato py-3 font-semibold text-lg xl:text-xl">
        {title}
      </div>
      <div className="font-poppins py-3 mt-4 p text-gray-800 font-light">
        {text}
      </div>
    </div>
  );
};

const Values = () => {
  const values = [
    {
      title: translate('aboutValeurTitle1'),
      describe:translate('aboutValeurContent1'),
    },
    {
      title: translate('aboutValeurTitle2'),
      describe:translate('aboutValeurContent2'),
    },
    {
      title: translate('aboutValeurTitle3'),
      describe:translate('aboutValeurContent3'),
    },
    {
      title:translate('aboutValeurTitle4'),
      describe:translate('aboutValeurContent4'),
    },
  ];

  return (
    <div className="my-6 lg:my-8 xl:my-16">
      <div className="py-5 sm:py-7 mx-14 flex justify-start">
        <h3 className="font-bold font-lato text-xl sm:text-2xl lg:text-3xl">
          <span className="text-blue-01">{translate('our')} </span>
          <span className="text-green-01">{translate('values')}</span>
        </h3>
      </div>
      <div className="bg-local bg-cover bg-center">
        <div
          className="p-4 grid md:grid-cols-3 justify-center items-center"
          style={{
            background:
              "linear-gradient(278.86deg, rgba(45, 185, 241, 0.1) 25.19%, rgba(45, 185, 241, 0) 92.52%)",
          }}
        >
          <div className="flex items-center justify-end md:-translate-y-7">
            <Value title={values[0].title} text={values[0].describe} />
          </div>

          <div className="grid justify-center">
            <div className="md:translate-x-5">
              <Value title={values[1].title} text={values[1].describe} />
            </div>

            <div className="md:-translate-x-5">
              <Value title={values[2].title} text={values[2].describe} />
            </div>
          </div>

          <div className="flex justify-start items-center md:translate-y-7">
            <Value title={values[3].title} text={values[3].describe} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
