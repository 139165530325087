import { configureStore, createSlice } from "@reduxjs/toolkit";


const careerNavSlice = createSlice({
    name: "carreerNav",
    initialState: [
        { etat: false },
        { etat: true },
        { etat: false },
    ],
    reducers: {
        change: (state, action) => {
            const index = state.findIndex((e) => e.etat === true);
            state[index].etat = false;
            state[action.payload].etat = true;
        }
    }
});
const candidatureSlice = createSlice({
    name: "candidature",
    initialState:{vide:'d'},
    reducers: {
        changeCar: (state, action) => {
            state={
                ...state,
                [action.payload.name]: action.payload.value
            };
            return state;
        }
    }
});
const modalTermsSlice = createSlice({
    name: "modalTerms",
    initialState:localStorage.getItem('terms')?localStorage.getItem('terms'):true,
    reducers: {
        changeModal: (state, action) => {
            state=!state;
            localStorage.setItem('terms',state );
            return state;
        }
    }
});
const languageLocaleSlice=createSlice({
    name:"languageLocale",
    initialState:localStorage.getItem('LANG')?localStorage.getItem('LANG'):"en-us",
    reducers:{
        changeLanguage:(state, action)=>{
            state=action.payload;
            return state;
        }
    }
});
const posteSlice = createSlice(
    {
        name: "poste",
        initialState: { poste: -1 },
        reducers: {
            changeState: (state, action) => {
                state.poste = action.payload;
                return state;
            }
        }
    }
);




export const changeCarreer = (index) => {
    return ({
        type: "carreerNav/change",
        payload: index
    });
}
export const changePoste = (item) => {
    return ({
        type: "poste/changeState",
        payload: item
    });
}


export const store = configureStore({
    reducer: {
        carreerNavigate: careerNavSlice.reducer,
        poste: posteSlice.reducer,
        languageLocale:languageLocaleSlice.reducer,
        modalTerms: modalTermsSlice.reducer,
        candidature:candidatureSlice.reducer,   
     }
});

export const {changeLanguage}=languageLocaleSlice.actions;
export const {changeModal}=modalTermsSlice.actions;
export const {changeCar}=candidatureSlice.actions;
