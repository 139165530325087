

import { createContext, useState, useEffect, Children, } from "react";
//et pour concerver eet savoir le chemin react a 
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { apiUrlVitrine } from "../api";


const SendContext = createContext()

export default SendContext;





export const SendProvider = (props) => {
    const [contextData, setContextData] = useState({});

    const [candidature, setCandidature]= useState(() => localStorage.getItem('cand') ? JSON.parse(localStorage.getItem('cand')) : {});
    
    let [loading, setLoading] = useState(false);
    
    let handelChang=(event)=>{
            localStorage.setItem('cand',JSON.stringify({
                ...localStorage.getItem('cand') ? JSON.parse(localStorage.getItem('cand')) : {},
                [event.target.name]: event.target.value
            }));     
    }
      let handelChangfile=(event)=>{
              
    }
    let sbumitCandidature=()=>{
     
     let candSend=JSON.parse(localStorage.getItem('cand'));
     let file=document.getElementById("cv_cand").files[0];

    candSend['cv']= file;
console.log (candSend);
        axios.post(apiUrlVitrine+"/candidature/", candSend,    {
                    headers: {
                         'Content-Type': 'multipart/form-data'
                      },
                }).then((res) => {
     
                 NotificationManager.success('Envoie du message réussi !! ', 'Success', 5000);
               }).catch((e) => {
                NotificationManager.error('Une Erreur est survenu lors de l\'envoie !!!', 'Erreur !!!', 3000);
           })
    }
   


console.log(candidature);
    

    useEffect(() => {
        setContextData({
            handelChangCand:handelChang,
            send:sbumitCandidature,

        });

      
    }, [])
    return (
        <SendContext.Provider value={contextData}>
            {loading ? null : props.children}
        </SendContext.Provider>
    )
}