// const serverUrl = 'http://192.168.100.41:8000'

// const serverUrl = "http://www.ofty.fr";
const serverUrl = "https://www.ofty.fr";


//const serverUrl = "https://oftyapi.cibelleetfred.com"
//const serverUrl = "http://oftyapi.cibelleetfred.com";

//const serverUrl = "http://backend"
const apiUrlVitrine = serverUrl + "/vitrine";

const defaultHeaders = {
  Accept: "application/json",
};

const authHeaders = {
  ...defaultHeaders,
};

export { serverUrl, apiUrlVitrine, defaultHeaders, authHeaders };
