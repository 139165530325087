import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import Job from "./Job";

import image from "../../img/career/job.png";
import { apiUrlVitrine, defaultHeaders } from "../../api";
import axios from "axios";

const PageSearch = ({ apply }) => {
  const [search, setSearch] = useState("");
  const [jobs, setJobs] = useState([]);


  const [panelState, setPanelState] = useState([
    { state: false },
    { state: false },
    { state: false },
  ]);

  const openClose = (index) => {
    let tampon = [...panelState];

    for (let i = 0; i < tampon.length; i++) {
      if (i !== index) {
        tampon[i].state = false;
      }
    }
    tampon[index].state = !tampon[index].state;

    setPanelState(tampon);
  };




  useEffect(() => {
    axios.get(apiUrlVitrine + '/offreemploie/').then((res) => {
      setJobs(res.data);
    })

  }, []);

  return (
    <div className="grid justify-center mx-5 md:mx-8">
      <div className="flex items-center justify-center m-10">
        <input
          type="search"
          name="article"
          id="article"
          placeholder="Search job"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          className="block w-[17rem] lg:w-[24rem] p-1.5 text-lg font-normal text-gray-700 bg-[#FFF] bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-[#FFF] focus:border-green-01 focus:outline-none"
        />
        <div className="px-3 py-2 border border-solid border-gray-300 rounded -translate-x-2 bg-[#FFF] text-green-01">
          <FaSearch size={24} />
        </div>
      </div>

      <div className="w-full">
        {jobs.map((job, id) => {
          return (
            <div key={id + "job"}>
              <Job
                job={job}
                state={panelState[id].state}
                id={id}
                openClose={openClose}
                apply={apply}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PageSearch;
