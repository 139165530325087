import { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import FormContact from "./FormContact";
import { apiUrlVitrine, defaultHeaders } from "../../api";
import axios from "axios";
import translate from "../../internationalisation/translate";

const Contact = () => {
  const [contact, setContact] = useState({});

  useEffect(() => {
    axios.get(apiUrlVitrine + "/contact/").then((res) => {
      setContact(res.data[0]);
    });
  }, []);

  return (
    <div className="grid items-center grid-cols-1 gap-3 mx-6 xl:ml-8 xl:grid-cols-2">
      <div className="max-w-full m-4 text-left font-lato 2xl:my-8 2xl:mx-12">
        <h3 className="text-2xl font-bold lg:text-3xl 2xl:text-4xl">
          <span className="text-green-01">Contact </span>
          <span className="text-blue-01">OFTY</span>
        </h3>

        <div className="my-4 font-poppins">
          <div className="text-xl font-bold uppercase font-lato md:text-2xl lg:text-4xl break-02:text-6xl">
            {translate("contactTitle")}
          </div>
          <div className="py-1 text-xl font-lato lg:text-2xl break-02:text-3xl">
            {contact.title}
          </div>

          <div className="flex flex-col justify-around gap-2 py-6 text-sm sm:my-3 xl:max-w-4xl -translate-x-6 break-02:max-w-4xl break-04:text-base break-02:text-lg font-poppins sm:flex-row xl:justify-between">
            <div className="flex flex-col items-center p-2 mb-4 sm:mb-0">
              <MdLocationPin className="mb-4 text-green-01" size={35} />
              <div className="grid gap-2">
                <div className="grid break-04:flex">
                  <span>France: </span>
                  <span>{contact.localisation_fr}</span>
                </div>

                <div className="grid break-04:flex">
                  <span>Cameroun: </span>
                  <span>{contact.localisation_cmr}</span>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center p-2 mb-4 sm:mb-0">
              <FaPhoneAlt className="mb-4 text-green-01" size={35} />
              <div className="grid gap-2">
                <a href={`tel:${contact.tel_fr}`}></a>
                <div className="flex">
                  <a href={`tel:${contact.tel_fr}`} className="flex ">
                    France:{" "}
                    <span className="text-blue-500 mx-2">
                      {contact.tel_fr}{" "}
                    </span>
                    <FaPhoneAlt
                      className="mx-4 text-green-01  rotate-[240deg] "
                      size={15}
                    />
                  </a>
                </div>
                <div className="flex my-3">
                  <a href={`tel:${contact.tel_cmr}`} className="flex ">
                    Cameroun:{" "}
                    <span className="text-blue-500 mx-2">
                      {contact.tel_cmr}{" "}
                    </span>
                    <FaPhoneAlt
                      className="mx-4 text-green-01 rotate-[240deg] "
                      size={15}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center p-2 mb-4 sm:mb-0">
              <FaEnvelope className="mb-4 text-green-01" size={40} />
              <div>{contact.email}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center w-full">
        <FormContact />
      </div>
    </div>
  );
};

export default Contact;
