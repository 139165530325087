import React from "react";
import Article from "./Article";

import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../../api";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import translate from "../../internationalisation/translate";

const BodyPublication = () => {
  const locale = useSelector((state) => state.languageLocale);
  const [search, setSearch] = useState("");

  const [currentDomaine, setCurrentDomaine] = useState(-1);

  const [domaines, setDomaines] = useState([]);

  const [articles, setArticles] = useState([]);

  const [lastPost, setLastPost] = useState([]);

  useEffect(() => {
    if (search === "" && currentDomaine === -1) {
      axios.get(apiUrlVitrine + "/publication/").then((res) => {
        setArticles(res.data.reverse());
        setLastPost(res.data);
      });
    }

    axios.get(apiUrlVitrine + "/domaine/").then((res) => {
      setDomaines(res.data);
    });
  }, [search, currentDomaine]);

  let changeDomaine = (index) => {
    if (currentDomaine !== index) {
      setCurrentDomaine(index);

      axios
        .get(apiUrlVitrine + "/publication/?domaine=" + index)
        .then((res) => {
          setArticles(res.data.reverse());
        });
    } else {
      setCurrentDomaine(-1);
    }
  };

  const onChange = (e) => {
    setSearch(e.target.value);

    currentDomaine === -1
      ? axios
          .get(apiUrlVitrine + "/publication/?search=" + e.target.value)
          .then((res) => {
            setArticles(res.data.reverse());
          })
      : axios
          .get(
            apiUrlVitrine +
              "/publication/?search=" +
              e.target.value +
              "&domaine=" +
              currentDomaine
          )
          .then((res) => {
            setArticles(res.data.reverse());
          });
  };

  const year = (date) => {
    let yy = date.split("-");
    return yy;
  };

  return (
    <div>
      <div className="m-4 lg:m-10 break-02:m-20">
        <div className="py-5 sm:py-7 mx-4">
          <h3 className="font-bold font-lato text-xl sm:text-2xl lg:text-4xl">
            <span className="text-green-01">Blog </span>
            <span className="text-blue-01">Post</span>
          </h3>
        </div>

        <div className="grid lg:grid-cols-4 gap-2">
          <div className="hidden lg:grid">
            <div className="max-h-[50rem] border-r-2 border-gray-300 ">
              <div className="my-10">
                <input
                  type="search"
                  name="article"
                  id="article"
                  placeholder="Search Article"
                  value={search}
                  onChange={(event) => onChange(event)}
                  className="form-control block w-11/12 p-1.5 text-lg font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-01 focus:outline-none"
                />
              </div>

              <div className="py-5">
                <div className="text-xl 2xl:text-2xl break-02:text-3xl font-lato font-semibold py-3">
                  Categories
                </div>
                <div className="font-lato text-lg">
                  <ul>
                    {domaines.map((category) => {
                      return (
                        <div
                          key={category.id}
                          onClick={() => changeDomaine(category.id)}
                          className={`${
                            currentDomaine === category.id
                              ? "text-green-01 font-semibold"
                              : "text-gray-700"
                          }`}
                        >
                          <li className="p-2 cursor-pointer hover:text-green-01">
                            {locale === "en-us"
                              ? category.translations.en.name
                              : category.translations.fr.name}
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="py-5">
                <div className="text-xl 2xl:text-2xl break-02:text-3xl font-lato font-semibold py-3">
                  {translate("lastArticle")}
                </div>
                <div>
                  {lastPost.slice(0, 5).map((post) => {
                    return (
                      <div
                        key={post.id}
                        className="flex justify-between items-center p-2 my-4"
                      >
                        <div className="hidden xl:block max-w-[7rem] mr-3">
                          <img src={post.image} alt="..." />
                        </div>
                        <div className="text-xs xl:text-sm font-lato text-[#444] cursor-pointer hover:text-blue-01">
                          <a href={`/publication/${post.id}`}>
                            {locale === "en-us"
                              ? post.translations.en.title
                              : post.translations.fr.title}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:col-span-3">
            <div className="grid gap-3 grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 lg:ml-6">
              {articles.map((article, index) => {
                return (
                  <div key={article.id} className="mt-10 flex justify-center">
                    <Article article={article} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyPublication;
