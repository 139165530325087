import { useEffect, useState } from "react";
import Carousel from "../components/home/Carousel";
import Navbar from "../components/Navbar";
import CardBar from "../components/home/CardBar";
import Inside from "../components/home/Inside";
import Expertise from "../components/home/Expertise";
import Services from "../components/home/Services";
import Achievement from "../components/home/Achievement";
import Publication from "../components/home/Publication";
import Career from "../components/home/Career";
import About from "../components/home/About";
import Contact from "../components/home/Contact";
import Footer from "../components/Footer";
import Aos from "aos";
import { Link } from "react-scroll";

import { HiChevronDoubleUp } from "react-icons/hi";

// import slide_1 from "../img/slide_01.jpg";
import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../api";

const Home = () => {
  const [carousel, setCarousel] = useState([]);

  const [stats, setStats] = useState([]);
  const [expertises, setExpertises] = useState([]);
  const [services, setServices] = useState([]);

  const [achievements, setAchievements] = useState([]);
  const [articles, setArticles] = useState([]);

  useEffect(() => {

    Aos.init({ duration: 2000 });
    axios.get(apiUrlVitrine + "/carrousel/?name=home").then((res) => {
      setCarousel(res.data);
    });

    axios.get(apiUrlVitrine + "/statistique/").then((res) => {
      setStats(res.data);
    });
    axios.get(apiUrlVitrine + "/expertise/").then((res) => {
      setExpertises(res.data);
    });
    axios.get(apiUrlVitrine + "/service/").then((res) => {
      setServices(res.data);
    });

    axios.get(apiUrlVitrine + "/produit/").then((res) => {
      setAchievements(res.data);
    });

    axios.get(apiUrlVitrine + "/publication/").then((res) => {
      setArticles(res.data.reverse());
    });
  }, []);

  return (
    <div className="bg-white cursor-default relative">
      <div className="z-50 absolute">
        <Navbar />
      </div>

      <div id="home" className="h-14 "></div>

      <div className="flex justify-end">
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
          className="fixed p-2 text-2xl bg-white border border-gray-200 rounded-full shadow-xl cursor-pointer text-green-01 shadow-gray-300 right-5 bottom-14"
        >
          <HiChevronDoubleUp />
        </Link>
      </div>

      <div id="carousel" className=" ">
        {carousel.length < 1 ? "" : <Carousel carousel={carousel} />}
      </div>

      <div className="pb-2 md:pb-5">
        {stats.length < 1 ? "" : <CardBar stats={stats} />}
      </div>

      <div className="pt-3 md:pt-8">
        <Inside />
      </div>

      <div className="pt-3">
        {expertises.length < 1 ? "" : <Expertise expertises={expertises} />}
      </div>

      <div className="pb-8">
        {services.length < 1 ? "" : <Services services={services} />}
      </div>

      <div className="py-10">
        {achievements.length < 1 ? (
          ""
        ) : (
          <Achievement achievements={achievements} />
        )}
      </div>

      <div data-aos='fade-down' className="pt-10">
        {articles.length < 1 ? "" : <Publication articles={articles} />}
      </div>

      <div data-aos='fade-down' className="py-10">
        <Career />
      </div>

      <div data-aos='fade-down' className="py-10">
        <About />
      </div>

      <div data-aos='fade-down' className="py-10">
        <Contact />
      </div>

      <div data-aos='fade-down' className="pt-10">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
