import React, { useState } from "react";
import { FaCircle } from "react-icons/fa";

import axios from "axios";
import { apiUrlVitrine, defaultHeaders } from "../../api";
import { NotificationManager } from "react-notifications";
import translate from "../../internationalisation/translate";

const Form = () => {
  const [message, setMessage] = useState({});
  const [testEmail, setTestEmail] = useState(false);
  const [stateMessage, setStateMessage] = useState(false);

  let handleChange = (event) => {
    const regex =
      /^[^\W][a-zA-Z0-9\-\._]+[^\W]@[^\W][a-zA-Z0-9\-\._]+[^\W]\.[a-zA-Z]{2,6}$/;
    if (event.target.name === "email") {
      if (regex.test(event.target.value)) {
        setTestEmail(true);
      } else {
        setTestEmail(false);
      }
    }
    setMessage({
      ...message,
      [event.target.name]: event.target.value,
    });
  };
  let sendMessage = (event) => {
    event.preventDefault();
    axios
      .post(apiUrlVitrine + "/message/", message)
      .then((res) => {
        setStateMessage(true);
        NotificationManager.success(
          translate("ResponseNewletter"),
          translate("respSuccess"),
          5000
        );
      })
      .catch((e) => {
        NotificationManager.error(
          translate("respErrorLong"),
          translate("respError"),
          5000
        );
      });
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {/* Formulaire de contact */}
      {!stateMessage ? (
        <form
          onSubmit={sendMessage}
          className="p-4 px-8 m-6 border border-gray-200 rounded shadow-2xl 2xl:w-10/12 break-02:w-9/12 break-04:mx-16 md:px-12 break-04:px-14"
        >
          <div className="flex justify-center text-center font-poppins text-[#444] lg:text-lg break-02::text-xl m-4 py-2">
            <span className="max-w-lg ">{translate("contactFomHeader")}</span>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div className="relative w-full mb-3 form-floating">
              <input
                type="text"
                className="form-control block w-full text-lg font-normal text-gray-700 bg-[#FFF] bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-[#FFF] focus:border-blue-01 focus:outline-none"
                onChange={handleChange}
                name="name"
                placeholder={translate("fullName")}
                required
              />
              <label htmlFor="floatingInput" className="text-[#444] text-lg">
                {translate("fullName")}
              </label>
            </div>

            <div className="relative w-full my-3 form-floating">
              <input
                type="email"
                className={`form-control block w-full text-lg font-normal text-[#444] bg-white-01 bg-clip-paddingrounded transition ease-in-out m-0 focus:text-[#444] focus:bg-white-01 ${
                  !testEmail && message.email
                    ? "focus:border-red-700  border border-solid border-red-300  focus:outline-none  "
                    : " focus:border-blue-01  focus:outline-none  border border-solid border-gray-300  "
                }`}
                onChange={handleChange}
                name="email"
                placeholder="Email"
                required
              />
              <label htmlFor="floatingInput" className="text-[#444] text-lg">
                Email*
              </label>
            </div>

            <div className="relative w-full my-3 form-floating">
              <input
                type="text"
                className="form-control block w-full text-lg font-normal text-gray-700 bg-[#FFF] bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-[#FFF] focus:border-blue-01 focus:outline-none"
                onChange={handleChange}
                required
                name="company"
                placeholder={translate("companyName")}
              />
              <label htmlFor="floatingInput" className="text-[#444] text-lg">
                {translate("companyName")}
              </label>
            </div>
            <div className="relative w-full my-5 form-floating">
              <input
                type="text"
                className="form-control block w-full text-lg font-normal text-[#444] bg-white-01 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-[#444] focus:bg-white-01 focus:border-blue-01 focus:outline-none"
                onChange={handleChange}
                name="object"
                placeholder={translate("object")}
              />
              <label htmlFor="floatingInput" className="text-lg text-gray-400">
                {translate("object")}
              </label>
            </div>

            <div className="relative w-full my-3 form-floating">
              <input
                type="tel"
                className="form-control block w-full text-lg font-normal text-gray-700 bg-[#FFF] bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-[#FFF] focus:border-blue-01 focus:outline-none"
                onChange={handleChange}
                required
                name="tel"
                placeholder={translate("phone")}
              />
              <label htmlFor="floatingInput" className="text-[#444] text-lg">
                {translate("phone")}
              </label>
            </div>

            <div className="w-full h-40 my-3 md:mb-0">
              <textarea
                className="form-control block w-full h-full px-3 py-2 text-lg font-normal text-gray-700 bg-[#FFF] bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-[#FFF] focus:border-blue-01 focus:outline-none"
                onChange={handleChange}
                name="body"
                required
                placeholder="Message"
              ></textarea>
            </div>
          </div>

          <div className="flex justify-end my-4 text-sm font-medium text-white bg-white select-none md:my-8 lg:text-xl">
            <button
              type="submit"
              disabled={!testEmail}
              className="flex items-center px-3 py-2 leading-tight transition duration-150 ease-in-out rounded bg-green-01 hover:bg-blue-01 hover:bg-opacity-90 focus:outline-none focus:ring-0"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
            >
              {translate("submit")}
              <FaCircle className="ml-2 lg:ml-4" size={14} />
            </button>
          </div>
        </form>
      ) : (
        <div className="p-4 px-8 py-10 m-6 text-xl text-center border border-gray-200 rounded shadow-2xl font-lato 2xl:w-10/12 break-02:w-9/12 break-04:mx-16 md:px-12 break-04:px-14">
          Mr/Mme
          <span className="text-2xl font-bold text-green-600 ">
            {"   " + message.name + "    "}
          </span>
          votre message a été envoyé avec success
        </div>
      )}
    </div>
  );
};

export default Form;
