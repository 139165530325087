import React from "react";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

import image from "../../img/career/we_join.png";
import ContactForm from "../ContactForm";
import Section1 from "./Section1";
import Section2 from "./Section2";

const PageCareer = () => {
  const section1 = {
    title: "Being a consultant at OFTY",
    text: "At OFTY, you will have the means to continuously develop your skills and strengths. You'll contribute to critical projects for leading private and public organizations. You'll work with extraordinary people in teams where you can bring out the best in yourself.",
    subSection: [
      {
        name: "Why OFTY?",
        describe:
          "At McKinsey, you'll be involved in high-stakes, large-scale projects that help shape the world of tomorrow.",
      },
      {
        name: "Career progression",
        describe:
          "Attracting, developing and mobilizing exceptional talent to serve our clients is a fundamental mission...",
      },
      {
        name: "Which role to choose?",
        describe:
          "Consulting positions, internships, cross-functional roles... McKinsey recruits throughout the year and is constantly developing its...",
      },
      {
        name: "Valuation of internal projects",
        describe:
          "To apply for a job at McKinsey, visit our global careers website. To apply for a job at McKinsey, visit our global careers website.",
      },
    ],
    image: image,
  };

  const section2 = {
    intro:
      "The recruitment process for consultants consists of several interview sessions, usually two or three in a row. Each interview consists of three parts: a dialogue about your career path and motivations, followed by a case study, and finally, a time devoted to your questions. The interview lasts just under an hour, including the case study.",
    title: `The case stud`,
    describe:
      "The case study is a situational exercise and is intended to help us analyze your reasoning in the face of a given problem. The subject of the study is generally taken from an assignment carried out by the interviewer and can be related to any sector of activity. We do not expect a standard answer, but that you show us, through your reasoning and your interactions, what your logic is. For candidates invited to an interview session, we advise them to prepare in advance. You will find a lot of information on our global website. In particular, we recommend that you visit the pages: Problem Solving Training & Interview Preparation Tips Case Study Preparation You can also gather information directly from consultants or former consultants, for example through your school's alumni network, and from the recruitment department.",
    image: image,
  };

  const [panelState, setPanelState] = useState([
    { state: false },
    { state: false },
    { state: false },
  ]);

  const accordion =
    "cursor-pointer p-4 rounded w-full text-left border-none flex justify-between item-center outline-none hover:scale-[1.02] hover:bg-blue-01 hover:bg-opacity-70 transition ease-in-out duration-500";
  const arrow = "transition ease-in-out duration-500 text-2xl ";

  const active = "bg-blue-01 bg-opacity-70 scale-[1.02]";

  const panel =
    "px-4 bg-white overflow-hidden transition-all duration-500 ease-out";

  const openClose = (index) => {
    let tampon = [...panelState];

    for (let i = 0; i < tampon.length; i++) {
      if (i !== index) {
        tampon[i].state = false;
      }
    }
    tampon[index].state = !tampon[index].state;

    return setPanelState(tampon);
  };

  const question = [0, 1, 2];

  return (
    <div className="mx-5 md:mx-8 grid justify-center">
      <div className="py-6 lg:my-4 text-center font-lato font-semibold text-xl lg:text-2xl 2xl:text-3xl text-blue-01">
        We answer the questions you might have
      </div>

      <div className="grid py-4 2xl:max-w-[100rem] break-02:max-w-[120rem]">
        {question.map((id) => {
          return (
            <div className="my-4" key={id + "index"}>
              <div
                onClick={() => openClose(id)}
                className={`${
                  panelState[id].state === true
                    ? `${active} text-white`
                    : "bg-[#566272] text-white [#444] "
                } ${accordion}`}
              >
                <span className="text-lg break-02:text-xl">Section 1</span>
                <span
                  className={`${
                    panelState[id].state === true
                      ? "rotate-180"
                      : "text-white [#444]"
                  } ${arrow}`}
                >
                  <IoIosArrowDown />
                </span>
              </div>
              <div
                className={`${
                  panelState[id].state === true ? "py-4 h-max" : "max-h-0"
                } ${panel}`}
              >
                <div>
                  {id % 2 === 0 ? (
                    <Section1 section={section1} />
                  ) : (
                    <Section2 section={section2} />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div>
        <ContactForm />
      </div>
    </div>
  );
};

export default PageCareer;
