import React from "react";
import { useSelector } from "react-redux";
import translate from "../../internationalisation/translate";

const LinkButton = ({ adress, name, color }) => {
  return (
    <div className="select-none">
      <div className="flex justify-around my-4 text-xs font-medium text-white lg:text-lg 2xl:text-xl">
        <a
          type="button"
          className={`${
            color === "blue"
              ? "bg-blue-01 hover:bg-green-01 "
              : "hover:bg-blue-01 bg-green-01"
          } flex items-center px-3 py-2 leading-tight rounded hover:bg-opacity-90 focus:outline-none focus:ring-0 transition duration-300 ease-in-out`}
          href={adress}
          role="button"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
        >
          {name}
        </a>
      </div>
    </div>
  );
};

const Carousel = ({ carousel }) => {
  const locale = useSelector((state) => state.languageLocale);

  return (
    <div className="mt-3 w-wull">
      <div
        id="carouselExampleCaptions"
        className="relative carousel slide"
        data-bs-ride="carousel"
      >
        {/* Boutton d'indication du carousel */}
        <div className="absolute left-0 right-0 flex justify-center p-0 mb-4 carousel-indicators bottom-16 ">
          {carousel.map((carous, index) => {
            return index === 0 ? (
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
                key={carous.id}
              ></button>
            ) : (
              <button
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide-to={"" + index}
                aria-label={"Slide " + (index + 1)}
                key={carous.id}
              ></button>
            );
          })}
        </div>

        {/* Gestion des slide */}
        <div className="relative w-full overflow-hidden carousel-inner ">
          {carousel.map((carous, index) => {
            return index === 0 ? (
              <div
                key={carous.id}
                className="relative float-left w-full h-full carousel-item active"
              >
                <img
                  src={carous.image}
                  alt={carous.name}
                  className=" w-full h-[250px] md:h-full scale-200"
                />

                <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full overflow-hidden bg-fixed bg-gray-700 bg-opacity-20">
                  <div className="grid h-full grid-cols-3">
                    <div className="col-span-2 ">
                      <div className="flex items-center justify-start w-full h-full">
                        <div className="px-4 mx-6 text-white md:mx-10 xl:mx-20 lg:px-10">
                          <h2 className="flex flex-col text-xl font-extrabold font-lato md:text-3xl lg:text-4xl 2xl:text-6xl sm:my-4 sm:py-4">
                            <span>
                              {locale === "en-us"
                                ? carous.translations.en.title
                                : carous.translations.fr.title}
                            </span>
                            <span className="w-16 mt-1 translate-x-4 border-2 rounded-full lg:w-28 bg-green-01 border-green-01"></span>
                          </h2>
                          {/* <h5 className="py-6 text-sm font-medium md:text-lg 2xl:text-2xl text-blue-01 font-poppins sm:my-6">
                            {locale === "en-us"
                              ? carous.translations.en.describe
                              : carous.translations.fr.describe}
                          </h5>

                          <div className="flex justify-start">
                            <LinkButton
                              adress={carous.link}
                              name={translate("learnMore")}
                              color={"blue"}
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center w-full">
                      {/* <img src={carousel[0].image} alt="" className="w-full" /> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={carous.id}
                className="relative float-left w-full carousel-item"
              >
                <div className="block w-full h-[250px] md:h-full ">
                  <img
                    src={carous.image}
                    alt={carous.name}
                    className="w-full h-full"
                  />
                </div>

                <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full overflow-hidden bg-fixed bg-gray-700 bg-opacity-20">
                  <div className="grid items-center h-full grid-cols-3">
                    <div
                      className={`${
                        index % 2 === 0 ? "order-last" : ""
                      } flex items-center w-full`}
                    >
                      {/* <img src={carous.image} alt="" className="w-full" /> */}
                    </div>

                    <div className="col-span-2">
                      <div className="flex items-center justify-start w-full h-full">
                        <div className="px-4 mx-6 text-white md:mx-10 xl:mx-20 lg:px-10">
                          <h2 className="flex flex-col text-xl font-extrabold md:text-3xl lg:text-4xl 2xl:text-6xl font-lato sm:my-4 sm:py-4">
                            <span>
                              {locale === "en-us"
                                ? carous.translations.en.title
                                : carous.translations.fr.title}
                            </span>
                            <span className="w-16 mt-1 translate-x-4 border-2 rounded-full lg:w-28 bg-green-01 border-green-01"></span>
                          </h2>
                          {/* <h5 className="py-6 text-sm font-medium md:text-lg 2xl:text-2xl text-blue-01 font-poppins sm:my-6">
                            {locale === "en-us"
                              ? carous.translations.en.describe
                              : carous.translations.fr.describe}
                          </h5>

                          <div className="flex justify-start">
                            <LinkButton
                              adress={carous.link}
                              name={translate("learnMore")}
                              color={"blue"}
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
